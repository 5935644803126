interface Status {
	name: string;
}

export class Deletion implements Status {
	kind: UpdateCpMinorVersionStage.Deletion = UpdateCpMinorVersionStage.Deletion;
	name: string = 'Удаление старой версии КриптоПро';
}

export class Cleaning implements Status {
	kind: UpdateCpMinorVersionStage.Cleaning = UpdateCpMinorVersionStage.Cleaning;
	name: string = 'Проверка и очистка файлов';
}

export class Installation implements Status {
	kind: UpdateCpMinorVersionStage.Installation = UpdateCpMinorVersionStage.Installation;
	name: string = 'Установка новой версии КриптоПро';
}

export enum UpdateCpMinorVersionStage {
	Deletion,
	Cleaning,
	Installation,
}

export type StageType = Deletion | Cleaning | Installation;
