import { shortcutCreatorStore } from '@features/shortcuts-creator/stores/shortcut-creator-store';
import { observer } from 'mobx-react-lite';
import { Product } from '../product';
import styles from './products-grid.module.scss';

function ProductsGridElement() {
	return (
		<div id={styles.products}>
			<>
				{shortcutCreatorStore.products.map(info => {
					return (
						<Product
							key={info.targetUrl}
							info={info}
						/>
					);
				})}
			</>
		</div>
	);
}

export const ProductsGrid = observer(ProductsGridElement);
