import styles from './serial-info-plate.module.scss';
import { ReactComponent as Key } from '@assets/icons/key.svg';
import { SerialInfo } from '@features/cp-license/models/serial-info';

export interface Props {
	licenseSerial: SerialInfo;
	onChoose?: (licenseSerial: SerialInfo) => void;
}

function convertToString(date?: Date): string | undefined {
	return date?.toLocaleDateString('ru', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});
}

export function SerialInfoPlate(props: Props) {
	function renderExpiration(licenseSerial: SerialInfo) {
		if (licenseSerial.expirationDate === undefined) {
			return 'Бессрочная';
		}
		return `${convertToString(licenseSerial.activationDate)} — ${convertToString(licenseSerial.expirationDate)}`;
	}

	return (
		<div
			id={styles.block}
			className={props.onChoose === undefined ? undefined : styles.withHover}
			key={props.licenseSerial.serial}
			onClick={() => props.onChoose?.(props.licenseSerial)}
		>
			<Key id={styles.keyIcon} />
			<div id={styles.textInfo}>
				<div id={styles.serial}>{props.licenseSerial.serial}</div>
				<div id={styles.expiration}>
					{renderExpiration(props.licenseSerial)}
					{props.licenseSerial.isServer && ', для сервера'}
				</div>
			</div>
		</div>
	);
}
