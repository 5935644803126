import { Cert } from '@models/api/cert';

export class Loading {
	kind: 'Loading' = 'Loading';
}

export class NoCerts {
	kind: 'NoCerts' = 'NoCerts';
	showObsoleteCertsHint: boolean;

	constructor(showObsoleteCertsHint: boolean) {
		this.showObsoleteCertsHint = showObsoleteCertsHint;
	}
}

export class ShowCerts {
	kind: 'ShowCerts' = 'ShowCerts';
	certs: Cert[];
	searchResult: boolean;
	showObsoleteCertsHint: boolean;

	constructor(certs: Cert[], searchResult: boolean, showObsoleteCertsHint: boolean) {
		this.certs = certs;
		this.searchResult = searchResult;
		this.showObsoleteCertsHint = showObsoleteCertsHint;
	}
}

export type State = Loading | ShowCerts | NoCerts;
