import { MouseEventHandler } from 'react';
import styles from './dashed-link.module.scss';

export interface InstructionLinkProps {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	text: string;
}

export function DashedLink(props: InstructionLinkProps) {
	return (
		<button
			id={styles.dashedLink}
			onClick={props.onClick}
		>
			{props.text}
		</button>
	);
}
