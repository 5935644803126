import { postRequest } from '@helpers/fetch-helper';
import { checkConnectionWs } from '@helpers/ws-connection';
import { commonInfo } from '@businessLogic/common-info';
import { CertInstallationResult } from '@features/setup-wizard/enums/cert-installation-result';
import logger from '@logger';

export async function installCert(): Promise<CertInstallationResult> {
	try {
		const response = await postRequest(
			`${commonInfo.masterHttp}${process.env.REACT_APP_MASTER_EP_SYSTEM_INSTALL_ROOT_CERT!}`,
		);
		logger.info(
			`Код ответа на запрос по установке сертификата: [${response.status}]. Тело: [${await response.text()}]`,
		);

		if (!response.ok) {
			logger.warn('Не удалось установить сертификат. Код ответа отличен от успешного!');
			return CertInstallationResult.Failure;
		}

		if (!(await checkConnectionWs(commonInfo.masterWss)).isConnected) {
			logger.info(
				'Не получилось подключиться к терминальному ТА после установки сертификата. Вероятно, требуется перезагрузка браузера!',
			);
			return CertInstallationResult.NeedRestartBrowser;
		}

		return CertInstallationResult.Success;
	} catch (error) {
		logger.error(error);
		return CertInstallationResult.Failure;
	}
}
