import { ReactElement } from 'react';
import styles from './error-card.module.scss';

interface Props {
	title: string;
	content: ReactElement[] | ReactElement;
}

export function ErrorCard(props: Props) {
	return (
		<div className={styles.card}>
			<div className={styles.title}>{props.title}</div>
			<div className={styles.content}>{props.content}</div>
		</div>
	);
}
