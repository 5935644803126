import styles from './check-up.module.scss';
import { useEffect, useState } from 'react';
import { delay } from '@helpers/delay-helper';
import { Stage } from '@features/setup-wizard/models/stages';
import { Page, PageImage } from '@components/page-template';
import { store } from '@features/setup-wizard/stores/store';
import { Loader } from '@components/loader';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { ProgressList } from '@components/progress-list';
import { setupSucceeded, setupSettings } from '@features/setup-wizard/models/pages';
import { InnerServerErrorModalCard } from '@components/modal-cards/inner-server-error-modal-card';

function refreshPage() {
	window.location.reload();
}

export function CheckUp() {
	const [stages, setStages] = useState<Stage[]>([]);
	const [isError, setErrorStatus] = useState(false);
	const [selectedStage, selectStage] = useState(0);

	useEffect(() => {
		async function getStages() {
			try {
				await stagesWorker.updateStages();
				setStages(stagesWorker.getNeededToInstall());
			} catch (error) {
				setErrorStatus(true);
			}
		}

		getStages();
	}, []);

	useEffect(() => {
		async function checkUpStages() {
			try {
				if (stages.length === 0) return;

				for (let i = 0; i < stages.length; i++) {
					selectStage(i);
					await stages[i].checkUp();
					//Считаем что проверка раб места проходит слишком быстро поэтому делаем ее чуть по меделенее ибо так ее юзер просто не заметит.
					await delay(1000);
				}
				if (stagesWorker.getNeededToInstall().length === 0) {
					store.setPage(setupSucceeded());
					return;
				}
				store.setPage(setupSettings());
			} catch (error) {
				setErrorStatus(true);
			}
		}

		checkUpStages();
	}, [stages]);

	return (
		<Page
			image={PageImage.Magnifier}
			child={
				<>
					<div id={styles.title}>Проверяем компьютер</div>
					{stages.length === 0 ? (
						<Loader />
					) : (
						<div className={styles.content}>
							<ProgressList
								stages={stages.map(stage => stage.getCheckUpStageInfo().title)}
								selected={selectedStage}
							/>
						</div>
					)}
				</>
			}
			showMobileView={false}
			modalCard={<InnerServerErrorModalCard onClickCrossButton={() => refreshPage()} />}
			onClickOutOfModalCard={() => refreshPage()}
			modalActive={isError}
		/>
	);
}
