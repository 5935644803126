import { getModelFromBackend } from '@helpers/fetch-helper';
import { Stage, StagesRelevance } from '@features/setup-wizard/models/stages';
import { BrowserStage } from './stages/browser-stage';
import { CertsStage } from './stages/certs-stage';
import { CryptographyStage } from './stages/cryptography-stage';
import { PdfStage } from './stages/pdf-stage';
import { ProgramComponentsStage } from './stages/program-components-stage';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { commonInfo } from '@businessLogic/common-info';
import { SystemTypes } from '@enums/system-types';

export class StagesWorker {
	private wrappedStages: Stage[] = [];

	public get stages(): Stage[] {
		return this.wrappedStages;
	}

	public set stages(wrapped: Stage[]) {
		this.wrappedStages = wrapped;
	}

	public getNeededToInstall() {
		return this.stages.filter(stage => stage.needInstall);
	}

	public getIfType(type: StageTypes) {
		return this.stages.filter(stage => stage.type === type)[0];
	}

	public checkTypeOnActuality(type: StageTypes) {
		return this.stages.filter(stage => stage.type === type && stage.needInstall).length > 0;
	}

	public async updateStages() {
		this.stages = [];

		let stagesRelevance = await getModelFromBackend(
			StagesRelevance,
			`${process.env.REACT_APP_EP_SYSTEM_PARTS!}?systemType=${(await commonInfo.getSystemInfo()).type}`,
		);

		if (stagesRelevance.certs) {
			this.stages.push(new CertsStage());
		}

		if (stagesRelevance.browser) {
			this.stages.push(new BrowserStage());
		}

		if (stagesRelevance.pdf) {
			this.stages.push(new PdfStage());
		}

		if (stagesRelevance.programComponents) {
			this.stages.push(new ProgramComponentsStage());
		}

		if (stagesRelevance.cryptography) {
			const cryptoStage = new CryptographyStage();
			commonInfo.getSystemType() === SystemTypes.Linux
				? this.stages.unshift(cryptoStage)
				: this.stages.push(cryptoStage);
		}
	}
}

export const stagesWorker = new StagesWorker();
