import { ButtonWithSpinner } from '@components/button-with-spinner/button-with-spinner';
import { InnerServerErrorModalCard } from '@components/modal-cards/inner-server-error-modal-card';
import { Page, PageImage } from '@components/page-template';
import { ProgressList } from '@components/progress-list';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { updateCpMinorVersionRestartFailure } from '@features/setup-wizard/models/pages';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { rebootPc, updateCpMinorVersion } from '@features/update-cp-minor-version/business-logic/common';
import logger from '@logger';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import styles from './update-cp-minor-version-process-page.module.scss';

enum PageStatus {
	Updating = 'Updating',
	NeedRestart = 'NeedRestart',
	Restarting = 'Restarting',
	Error = 'Error',
}

function refreshPage() {
	window.location.reload();
}

export const UpdateCpMinorVersionProcessPage = observer(() => {
	const [status, setStatus] = useState(PageStatus.Updating);
	const stageNames = useMemo(() => cpStore.minorVersionStages.map(q => q.name), [cpStore.minorVersionStages]);

	function getSelectedNumber() {
		if (cpStore.currentUpdateCpMinorVersionStage)
			return cpStore.minorVersionStages.findIndex(q => q.kind === cpStore.currentUpdateCpMinorVersionStage?.kind);
		return -1;
	}

	useEffect(() => {
		async function startEffect() {
			try {
				await updateCpMinorVersion();
				setStatus(PageStatus.NeedRestart);
			} catch (error) {
				logger.error(
					`Возникла непредвиденная ошибка во время выполнения обновления минорной версии КриптоПро: ${error}!`,
				);
				setStatus(PageStatus.Error);
			}
		}

		startEffect();
	}, []);

	async function restart() {
		if (status !== PageStatus.NeedRestart) {
			return;
		}
		setStatus(PageStatus.Restarting);
		const rebootOk = await rebootPc();
		if (!rebootOk) store.setPage(updateCpMinorVersionRestartFailure());
	}

	return (
		<Page
			rightPaneContentClassName={styles.rightPaneContent}
			image={status === PageStatus.Updating ? PageImage.Load : PageImage.Cryptography}
			child={
				<div>
					<div id={styles.title}>Обновление КриптоПро</div>
					<ProgressList
						selected={getSelectedNumber()}
						stages={stageNames}
					/>
					<div className={styles.bottomArea}>
						<PromptBlock
							type={PromptBlockType.Warning}
							text={'Перезагрузка может затронуть работу других пользователей'}
						/>
						<ButtonWithSpinner
							onClick={async () => {
								await restart();
							}}
							loading={status === PageStatus.Restarting}
							disabled={status !== PageStatus.NeedRestart && status !== PageStatus.Restarting}
							defaultText={'Перезагрузить компьютер'}
						/>
					</div>
				</div>
			}
			modalCard={<InnerServerErrorModalCard onClickCrossButton={() => refreshPage()} />}
			onClickOutOfModalCard={() => refreshPage()}
			modalActive={status === PageStatus.Error}
		/>
	);
});
