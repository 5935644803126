import { OrgInfo } from './org-info';

export class ReAuthResult {
	public isNoSameOrg: boolean = false;

	public isOrgNotFound: boolean = false;

	public isServerError: boolean = false;

	public orgData?: OrgInfo;
}
