export enum CryptoInstallResult {
	Success,
	NotSuccess,
	NotNormalLicense,
	NotNormalLicenseInstallDemo,
	CryptoAlreadyInstall,
	ErrorWhenReceiveInstaller,
	AccessDenied,
	InstallerAlreadyRun,
}
