import styles from './authentication.module.scss';
import { PasswordInput } from '@components/password-input';
import { InputState } from '@features/settings/proxy/enums/input-state';
import { proxySettingsStore } from '../../stores/proxy-settings-store';
import { observer } from 'mobx-react-lite';
import { TextWithErrorIndication } from '@features/settings/proxy/components/text-with-error-indication';
import { Checkbox } from '@components/checkbox';
import { showError } from '../../business-logic/validation';

function AuthenticationElement() {
	function getHintText(state: InputState) {
		switch (state) {
			case InputState.Empty:
				return <span className={styles.wrongInputText}>Заполните поле</span>;
			case InputState.Wrong:
				return <span className={styles.wrongInputText}>Введён некорректный символ</span>;
			default:
				return <></>;
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<fieldset
				className={styles.dropdown}
				disabled={!proxySettingsStore.authenticationAllowed}
			>
				<button
					id={styles.header}
					onClick={() => proxySettingsStore.toggleAuthenticationState()}
				>
					<Checkbox
						checked={proxySettingsStore.authenticationEnabled}
						onChange={(checked, e) => {
							proxySettingsStore.setAuthenticationState(checked);
							e.stopPropagation();
						}}
					/>
					Логин и пароль
				</button>
				{proxySettingsStore.authenticationEnabled ? (
					<div id={styles.body}>
						<div className={styles.inputBlock}>
							<TextWithErrorIndication
								text="Логин *"
								error={showError(proxySettingsStore.loginInputState)}
							/>
							<input
								type="text"
								placeholder="User"
								onChange={e => proxySettingsStore.setLogin(e.target.value)}
								onBlur={() => proxySettingsStore.updateLoginInputState()}
								value={proxySettingsStore.login}
								className={
									showError(proxySettingsStore.loginInputState)
										? styles.wrongInputCustom1
										: styles.inputCustom1
								}
							/>
							{getHintText(proxySettingsStore.loginInputState)}
						</div>
						<div className={styles.inputBlock}>
							<TextWithErrorIndication
								text="Пароль *"
								error={showError(proxySettingsStore.passwordInputState)}
							/>
							<PasswordInput
								wrong={showError(proxySettingsStore.passwordInputState)}
								onChange={e => proxySettingsStore.setPassword(e.target.value)}
								onBlur={() => proxySettingsStore.updatePasswordInputState()}
								value={proxySettingsStore.password}
							/>
							{getHintText(proxySettingsStore.passwordInputState)}
						</div>
					</div>
				) : (
					<></>
				)}
			</fieldset>
		</form>
	);
}

export const Authentication = observer(AuthenticationElement);
