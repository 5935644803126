import { jsonMember, jsonObject } from 'typedjson';
import { Shortcut } from './shortcut';

@jsonObject
export class ShortcutExtended extends Shortcut {
	@jsonMember(() => String)
	iconPath!: string;

	@jsonMember(() => String)
	name!: string;

	constructor(init?: Partial<ShortcutExtended>) {
		super();
		Object.assign(this, init);
	}
}
