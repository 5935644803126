import styles from './choose-license-serial-modal.module.scss';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import { ModalStore } from '../../../../../stores/modal-store';
import { SerialInfo } from '@features/cp-license/models/serial-info';
import { SerialInfoPlate } from '../../../../cp-license/components/serial-info-plate';

export interface Props {
	modalStore: ModalStore;
	licenseSerials: SerialInfo[];
	onChoose: (licenseSerial: SerialInfo) => void;
}

// TODO SMA Здесь пока не получилось нормального сжатия на маленьком экране
function ChooseLicenseSerialModal(props: Props) {
	return (
		<ActionModalCardTemplate
			title={'Выберите серийный номер лицензии'}
			body={
				<div id={styles.body}>
					{props.licenseSerials.map(licenseSerial => (
						<SerialInfoPlate
							licenseSerial={licenseSerial}
							onChoose={serial => props.onChoose(serial)}
						/>
					))}
				</div>
			}
			footerButtons={[]}
			onClickCrossButton={props.modalStore.hide}
		/>
	);
}

export const showChooseLicenseSerialModal = (props: Props) => {
	props.modalStore.show({
		children: <ChooseLicenseSerialModal {...props} />,
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
