import { PageStatus } from '@features/settings/common/enums/page-body-status';
import { ReactElement } from 'react';
import { proxySettingsStore } from '../../stores/proxy-settings-store';
import styles from './buttons-block.module.scss';
import { observer } from 'mobx-react-lite';
import { ModalState } from '../../enums/modal-state';
import { ButtonSkeleton } from '@components/skeletons/button-skeleton';

function ButtonsBlockElement() {
	let buttons: ReactElement;
	switch (proxySettingsStore.pageStatus) {
		case PageStatus.Ok:
			buttons = (
				<>
					<button
						className={styles.buttonBlue}
						disabled={!proxySettingsStore.saveStore.saveAvailable}
						onClick={() => proxySettingsStore.saveStore.saveSettings()}
					>
						Сохранить
					</button>
					<button
						className={styles.buttonTransparentRed}
						disabled={!proxySettingsStore.saveStore.isDeleteAvailable}
						onClick={() => proxySettingsStore.setModalState(ModalState.ResetSettings)}
					>
						Сбросить
					</button>
				</>
			);
			break;
		case PageStatus.Skeleton:
			buttons = (
				<>
					<ButtonSkeleton width={143} />
					<ButtonSkeleton width={132} />
				</>
			);
			break;
		default:
			return <></>;
	}
	return <div id={styles.buttons}>{buttons}</div>;
}

export const ButtonsBlock = observer(ButtonsBlockElement);
