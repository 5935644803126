import styles from './activate-license-serial-modal.module.scss';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import { ModalStore } from '../../../../../stores/modal-store';
import { SerialInfo } from '@features/cp-license/models/serial-info';
import { SerialInfoPlate } from '../../serial-info-plate';

export interface Props {
	modalStore: ModalStore;
	licenseSerial: SerialInfo;
	onActivate: (licenseSerial: SerialInfo) => void;
}

// TODO SMA Здесь пока не получилось нормального сжатия на маленьком экране
function ActivateLicenseSerialModal(props: Props) {
	return (
		<ActionModalCardTemplate
			title="Cерийный номер лицензии"
			body={
				<div id={styles.body}>
					<SerialInfoPlate licenseSerial={props.licenseSerial} />
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonBlue}
					onClick={() => props.onActivate(props.licenseSerial)}
				>
					Активировать
				</button>,
			]}
			onClickCrossButton={props.modalStore.hide}
		/>
	);
}

export const showActivateLicenseSerialModal = (props: Props) => {
	props.modalStore.show({
		children: <ActivateLicenseSerialModal {...props} />,
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
