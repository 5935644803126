import logger from '@logger';
import { getUtmAppInfo } from './api/utm-app-operator';
import { commonInfo } from './common-info';

export async function prepareInstallApiLink(): Promise<string> {
	let baseUrl = `${commonInfo.backendUrl}${process.env.REACT_APP_EP_DOWNLOAD_AGENT!}`;
	try {
		if (!commonInfo.sourceInfo.utmAppId) {
			logger.info(`Пришли не из продукта инсталлятор будет по ссылке: ${baseUrl}`);
			return baseUrl;
		}

		let appInfo = await getUtmAppInfo(commonInfo.sourceInfo.utmAppId);
		if (appInfo == null) {
			logger.warn(
				`Не удалось получить данные о продукте[${commonInfo.sourceInfo.utmAppId}]. Отправим по дефолтной ссылке!`,
			);
			return baseUrl;
		}
		logger.info(`Получили данные по id ${appInfo.productId} отправил пользователя туда`);
		return `${baseUrl}?productId=${appInfo.productId}`;
	} catch (e) {
		logger.error('Ошибка при попытке запроса данных об инсталляторах отправим без продукта', e);
		return baseUrl;
	}
}
