import { Checkbox } from '@components/checkbox';
import { Page, PageImage } from '@components/page-template';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { updateCpMinorVersionProcess } from '@features/setup-wizard/models/pages';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { observer } from 'mobx-react-lite';
import styles from './update-cp-minor-version-start-page.module.scss';

export const UpdateCpMinorVersionStartPage = observer(() => (
	<Page
		rightPaneContentClassName={styles.rightPaneContent}
		image={PageImage.Attention}
		child={
			<div>
				<div id={styles.title}>Обновление КриптоПро</div>
				<div id={styles.subtitle}>
					Для обновления КриптоПро Мастеру Настройки требуется удалить старую версию, проверить файлы,
					установить новую версию КриптоПро с правами Администратора
				</div>
				<div
					className={[
						styles.cryptoArmCheckArea,
						cpStore.cryptoArmInstalled ? styles.checked : undefined,
					].join(' ')}
					onClick={() => cpStore.setCryptoArmInstalled(!cpStore.cryptoArmInstalled)}
				>
					<Checkbox checked={cpStore.cryptoArmInstalled} />
					На устройстве установлен КриптоАРМ
				</div>
				{cpStore.cryptoArmInstalled && (
					<div className={styles.cryptoArmPrompt}>
						<PromptBlock
							type={PromptBlockType.Warning}
							text={
								'Если на устройстве установлен КриптоАРМ, перед началом обновления рекомендуем удалить приложение и переустановить его после обновления КриптоПро'
							}
						/>
					</div>
				)}
				<button
					className={styles.buttonBlue}
					onClick={() => store.setPage(updateCpMinorVersionProcess())}
				>
					Подтвердить
				</button>
			</div>
		}
	/>
));
