import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class PdfSettings {
	@jsonMember(() => Boolean)
	public needOnlyPdfAssociation!: boolean;

	@jsonMember(() => Boolean)
	public associationAdobeWithPdf!: boolean;
}
