import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import styles from './directory-in-use-modal-card.module.scss';
import { MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

export interface DirectoryInUseModalCardProps {
	pathToDirectory: string;
	closeModalEvent: MouseEventHandler<HTMLButtonElement>;
	newSelectEvent: MouseEventHandler<HTMLButtonElement>;
}

function DirectoryInUseModalCardElement(props: DirectoryInUseModalCardProps) {
	return (
		<ActionModalCardTemplate
			title="Выбранная папка уже используется в другой организации"
			body={
				<div id={styles.mainText}>
					Смените папку <span id={styles.path}>«{props.pathToDirectory}»</span> на другую
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.newSelectEvent}
				>
					Выбрать другую папку
				</button>,
			]}
			onClickCrossButton={props.closeModalEvent}
		/>
	);
}

export const DirectoryInUseModalCard = observer(DirectoryInUseModalCardElement);
