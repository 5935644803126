import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler } from 'react';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import styles from './organization-already-set-up-modal-card.module.scss';

import { OrgCutInfo } from '@features/settings/auto-import/models/org-cut-info';
import { ModalState } from '@features/settings/auto-import/enums/modal-state';

export interface OrganizationAlreadySetUpModalCardProps {
	organizationName: OrgCutInfo;
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
	onClickOkButton?: MouseEventHandler<HTMLButtonElement>;
}

function OrganizationAlreadySetUpModalCardElement(props: OrganizationAlreadySetUpModalCardProps) {
	function onOrgSelectClick() {
		autoImportStore.setSelectedOrgHighlight(true);
		autoImportStore.setModal(ModalState.None);
	}

	return (
		<ActionModalCardTemplate
			title="Организация уже настроена"
			body={
				<div className={styles.mainText}>
					Для организации{' '}
					<span title={props.organizationName.tooltipOrgName}>{props.organizationName.presentOrgName}</span>{' '}
					уже имеются настройки
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonBlue}
					onClick={onOrgSelectClick}
				>
					Перейти к организации
				</button>,
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.onClickOkButton}
				>
					Ок
				</button>,
			]}
			onClickCrossButton={props.onClickCrossButton}
		/>
	);
}

export const OrganizationAlreadySetUpModalCard = observer(OrganizationAlreadySetUpModalCardElement);
