// TODO SMA подумать, нужны ли вообще эти методы и классы

class BaseInit implements RequestInit {
	method: string;
	headers?: HeadersInit;
	body?: BodyInit;
	signal?: AbortSignal;

	constructor(method: string, headers?: HeadersInit, body?: string, signal?: AbortSignal) {
		this.method = method;
		this.headers = headers;
		this.body = body;
		this.signal = signal;
	}
}

function createHeaders(body?: string): HeadersInit | undefined {
	return body ? { 'Content-Type': 'application/json' } : undefined;
}

export class PostInit extends BaseInit {
	constructor(body?: string, signal?: AbortSignal) {
		super('POST', createHeaders(body), body, signal);
	}
}

export class PutInit extends BaseInit {
	constructor(body?: string, signal?: AbortSignal) {
		super('PUT', createHeaders(body), body, signal);
	}
}

export class PatchInit extends BaseInit {
	constructor(body?: string, signal?: AbortSignal) {
		super('PATCH', createHeaders(body), body, signal);
	}
}

export class DeleteInit extends BaseInit {
	constructor(body?: string, signal?: AbortSignal) {
		super('DELETE', createHeaders(body), body, signal);
	}
}
