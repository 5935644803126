import { DashedLink } from '@components/dashed-link';
import styles from './org-card-error-text.module.scss';

interface Props {
	errorName: string;
	buttonText: string;
	onButtonClick?: () => void;
}

export function OrgCardErrorText(props: Props) {
	return (
		<div id={styles.orgErrorBlock}>
			<span id={styles.orgErrorText}>{props.errorName}</span>
			<span id={styles.orgErrorLinkBlock}>
				<DashedLink
					text={props.buttonText}
					onClick={props.onButtonClick}
				/>
			</span>
		</div>
	);
}
