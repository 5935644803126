import { Page, PageImage } from '@components/page-template';
import styles from './download-cp-error.module.scss';

export function DownloadCpError() {
	return (
		<Page
			image={PageImage.Error}
			child={
				<div>
					<div id={styles.title}>Не смогли скачать установщик КриптоПро CSP 5</div>
					<div id={styles.supportTextBlock}>
						<div id={styles.supportText}>Попробуйте обновить страницу или обратитесь в техподдержку</div>
					</div>
					<button
						className={styles.buttonBlue}
						onClick={() => {
							window.location.reload();
						}}
					>
						Обновить страницу
					</button>
				</div>
			}
			showMobileView={false}
		/>
	);
}
