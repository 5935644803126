import { Tooltip } from '@components/tooltip';
import { InstructionLink } from '@components/instruction-link';
import { PlacesType, TooltipRefProps } from 'react-tooltip';
import { ForwardedRef, forwardRef, RefObject } from 'react';

interface Props {
	anchorSelect?: string;
	id?: string;
	place?: PlacesType;
	ref?: RefObject<TooltipRefProps>;
}

export const OldCertsTooltip = forwardRef((props: Props, ref: ForwardedRef<TooltipRefProps>) => (
	<Tooltip
		{...props}
		ref={ref}
		clickable
	>
		С 1 сентября 2024 года изменилось законодательство
		<br />в рамках использования подписей
		<br />
		<InstructionLink
			link={process.env.REACT_APP_INSTR_CERT_FL!}
			text={'Подробнее  ›'}
		/>
	</Tooltip>
));
