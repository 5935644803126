import { ReactComponent as Exclamation } from '@assets/icons/exclamation-triangle.svg';
import { ReactElement } from 'react';
import styles from './exclamation-block.module.scss';

export interface ExclamationBlockProps {
	content: string | ReactElement;
}

export function ExclamationBlock(props: ExclamationBlockProps) {
	return (
		<div className={styles.exclamationBlock}>
			<Exclamation className={styles.exclamationIcon} />
			<div>{props.content}</div>
		</div>
	);
}
