import { commonInfo } from '@businessLogic/common-info';
import { RedirectTypes } from '@features/shortcuts-creator/enums/redirect-types';
import logger from '@logger';
import { getInstalledIdAppInfo, getUtmAppInfo } from '@businessLogic/api/utm-app-operator';
import { ProductInfo } from '@models/api/product-info';
import { RedirectInfo } from '@models/redirect-info';

export async function fetchRedirectInfo(): Promise<RedirectInfo> {
	let res = new RedirectInfo();
	res.redirectType = RedirectTypes.ToAllProduct;

	if (commonInfo.sourceInfo.installerId || commonInfo.sourceInfo.utmAppId) {
		await prepareProductInfo(res);
	}
	return res;
}

async function prepareProductInfo(redInfo: RedirectInfo) {
	let appInfo: ProductInfo | undefined;
	if (commonInfo.sourceInfo.installerId) {
		appInfo = await getInstalledIdAppInfo(commonInfo.sourceInfo.installerId);
	}
	if (commonInfo.sourceInfo.utmAppId) {
		appInfo = await getUtmAppInfo(commonInfo.sourceInfo.utmAppId);
	}
	if (appInfo == null) {
		logger.warn('Не удалось получить информацию о продукте к редиректу. Покажем все!');
		return;
	}
	redInfo.buttonText = appInfo.buttonText!;
	redInfo.productId = appInfo.productId!;
	redInfo.redirectType = RedirectTypes.ToOneProduct;

	if (commonInfo.sourceInfo.utmAppId) {
		redInfo.redirectQueryPart = `?${process.env.REACT_APP_PARAMETER_NAME_UTM_APP_ID!}=${
			commonInfo.sourceInfo.utmAppId
		}`;
	}
	if (commonInfo.sourceInfo.installerId) {
		redInfo.redirectQueryPart = `?${process.env.REACT_APP_PARAMETER_NAME_INSTALLER_ID!}=${
			commonInfo.sourceInfo.installerId
		}`;
	}
}
