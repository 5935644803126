import styles from './checkbox.module.scss';

export interface CheckBoxProps {
	checked: boolean;
	onChange?: (checked: boolean, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function Checkbox(props: CheckBoxProps) {
	return (
		<div className={styles.body}>
			<input
				type="checkbox"
				checked={props.checked}
				readOnly
			/>
			<div
				className={styles.checkbox}
				onClick={e => props.onChange?.(!props.checked, e)}
			>
				<svg
					className={styles.checkmark}
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1 4L4.33333 7L11 1" />
				</svg>
			</div>
		</div>
	);
}
