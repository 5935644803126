import styles from './cert-card.module.scss';
import { ReactComponent as CertIcon } from '@assets/icons/cert.svg';
import { ReactComponent as ExclamationIcon } from '@assets/icons/exclamation-triangle.svg';
import { convertToString } from '@helpers/date-helper';
import { Cert } from '@models/api/cert';
import { OldCertsTooltip } from '../old-certs-tooltip';

interface Props {
	certInfo: Cert;
	onClick: (certInfo: Cert) => void;
	checked?: boolean;
	showObsoleteSoonWarning?: boolean;
}

export function CertCard(props: Props) {
	return (
		<div
			className={[styles.main, props.checked && styles.checked].join(' ')}
			onClick={() => props.onClick(props.certInfo)}
		>
			<input
				id={styles.checkbox}
				type="checkbox"
				checked={props.checked}
				readOnly
			/>
			{props.showObsoleteSoonWarning ? (
				<>
					<ExclamationIcon
						data-tooltip-id="certExclamationIcon"
						className={styles.icon}
					/>
					{/* TODO SMA возможно вынести на уровень выше, ибо нет нужды дублировать в каждом компоненте*/}
					<OldCertsTooltip id="certExclamationIcon" />
				</>
			) : (
				<CertIcon
					id={styles.cert}
					className={styles.icon}
				/>
			)}
			<div id={styles.content}>
				<div id={styles.fullName}>
					{props.certInfo.ownerSurName} {props.certInfo.ownerGivenName}
				</div>
				<div id={styles.additionalInfo}>
					<span id={styles.company}>{props.certInfo.companyName}</span>
					<span id={styles.inn}> ИНН {props.certInfo.companyInn}</span>
				</div>
				<div id={styles.expirationDate}>Действует до {convertToString(props.certInfo.certExpDate)}</div>
			</div>
		</div>
	);
}
