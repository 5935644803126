import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import spinner from '@assets/icons/spinners/spinner-thin.svg';
import { observer } from 'mobx-react-lite';
import styles from './ta-restart-modal-card.module.scss';

function TaRestartModalCardElement() {
	return (
		<InfoModalCardTemplate
			title={'Такском-Агент перезапускается'}
			info={<>Ориентировочно процесс запуска занимает 20–30 секунд</>}
			image={
				<img
					src={spinner}
					className={styles.spinner}
				/>
			}
			innerWidth={416}
		/>
	);
}

export const TaRestartModalCard = observer(TaRestartModalCardElement);
