import { NonActualVersion } from '@pages/non-actual-version';
import { observer } from 'mobx-react-lite';
import { storeSettings } from '@features/settings/common/stores/store-settings';
import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import { DirectorySelector } from '@pages/settings/auto-import';
import { AutoImport } from '@pages/settings/auto-import';
import { ProxySettings } from '@pages/settings/proxy/proxy-settings';
import { NoConnection } from '@pages/no-connection/basic';

export const Settings = observer(() => {
	switch (storeSettings.page) {
		case PagesSettings.NoConnection:
			return <NoConnection />;
		case PagesSettings.NonActualVersion:
			return <NonActualVersion />;

		case PagesSettings.AutoImportSettings:
			return <AutoImport />;
		case PagesSettings.DirectorySelector:
			return <DirectorySelector />;
		case PagesSettings.ProxySettings:
			return <ProxySettings />;
		default:
			return <></>;
	}
});
