import styles from './instruction-link.module.scss';

export interface InstructionLinkProps {
	link: string;
	text: string;
}

export function InstructionLink(props: InstructionLinkProps) {
	return (
		<a
			href={props.link}
			className={styles.instructionLink}
			target="_blank"
			rel="noreferrer"
		>
			{props.text}
		</a>
	);
}
