import { MouseEventHandler } from 'react';
import styles from './something-went-wrong-modal-card.module.scss';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import { observer } from 'mobx-react-lite';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';

export interface SomethingWentWrongModalCardProps {
	onClickCrossButton: MouseEventHandler<HTMLButtonElement>;
}

function SomethingWentWrongModalCardElement(props: SomethingWentWrongModalCardProps) {
	return (
		<InfoModalCardTemplate
			title={'Что-то пошло не так'}
			info={<>Попробуйте снова или перезапустите Такском-Агент</>}
			image={<Attention className={styles.image} />}
			onClickCrossButton={props.onClickCrossButton}
			innerWidth={362}
		/>
	);
}

export const SomethingWentWrongModalCard = observer(SomethingWentWrongModalCardElement);
