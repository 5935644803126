import { makeAutoObservable } from 'mobx';

export class CpLicenseStore {
	public licenseKey?: string | null = undefined;
	public showNotification: boolean = false;
	public scheduledEnterCpKey: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public resetLicense(): void {
		this.licenseKey = null;
	}

	public setNotificationStatus(show: boolean) {
		this.showNotification = show;
	}

	public scheduleEnterCpKey(value: boolean): void {
		this.scheduledEnterCpKey = value;
	}

	public setLicenseKey(value: string | null) {
		this.licenseKey = value;
	}
}

export const cpLicenseStore = new CpLicenseStore();
