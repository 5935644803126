import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SerialInfo {
	@jsonMember(String, { name: 'serial' })
	public serial!: string;

	@jsonMember(Date, { name: 'activationDate' })
	public activationDate?: Date;

	@jsonMember(Date, { name: 'expirationDate' })
	public expirationDate?: Date;

	@jsonMember(Boolean, { name: 'isServer' })
	public isServer!: boolean;
}
