import styles from './checking-cert-modal.module.scss';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ReactComponent as Spinner } from '@assets/icons/spinners/spinner-thin.svg';
import { ModalStore } from '@base/stores/modal-store';

export interface Props {
	modalStore: ModalStore;
}

function CheckingCertModal() {
	return (
		<InfoModalCardTemplate
			title="Проверяем сертификат"
			image={<Spinner className={styles.spinner} />}
		/>
	);
}

export const showCheckingCertModal = (props: Props) => {
	props.modalStore.show({ children: <CheckingCertModal />, active: true });
};
