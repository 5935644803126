import { shortcutCreatorStore } from '@features/shortcuts-creator/stores/shortcut-creator-store';
import { observer } from 'mobx-react-lite';
import styles from './create-shotcuts-button.module.scss';

function CreateShortCutsButtonElement() {
	function getButtonText(): string {
		if (!shortcutCreatorStore.createShortCutMode) {
			return 'Создать ярлыки...';
		}

		if (!shortcutCreatorStore.anyNeedToCreateShortcut) {
			return 'Создать ярлыки';
		}

		return `Создать ярлыки (${shortcutCreatorStore.neededToCreateShortcutCount})`;
	}

	function onClick() {
		if (!shortcutCreatorStore.createShortCutMode) {
			shortcutCreatorStore.enableCreateShortCutMode();
			return;
		}

		if (!shortcutCreatorStore.anyNeedToCreateShortcut) {
			return;
		}

		shortcutCreatorStore.createShortcuts();
	}

	return (
		<button
			className={
				shortcutCreatorStore.createShortCutMode ? styles.buttonBlue : styles.buttonTransparentWithBorderBlue
			}
			onClick={onClick}
			disabled={!shortcutCreatorStore.anyNeedToCreateShortcut && shortcutCreatorStore.createShortCutMode}
		>
			{getButtonText()}
		</button>
	);
}

export const CreateShortCutsButton = observer(CreateShortCutsButtonElement);
