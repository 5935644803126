import { InnerServerErrorModalCard } from '@components/modal-cards/inner-server-error-modal-card';
import { ModalStore } from '../../../../../stores/modal-store';

function refreshPage() {
	window.location.reload();
}

export interface Props {
	modalStore: ModalStore;
}

const SomethingWentWrongModal = () => {
	return <InnerServerErrorModalCard onClickCrossButton={refreshPage} />;
};

export const showSomethingWentWrongModal = (props: Props) => {
	props.modalStore.show({
		children: <SomethingWentWrongModal />,
		onClickOutOfChild: refreshPage,
		active: true,
	});
};
