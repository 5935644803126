import { commonInfo } from '@businessLogic/common-info';
import logger from '@logger';
import { resetMainPage } from '@features/setup-wizard/stores/store-adapter';
import { useNavigate } from 'react-router-dom';
import styles from './check-connection-button.module.scss';

export interface CheckConnectionButtonProps {
	needResetMain?: boolean;
}

export function CheckConnectionButton(props: CheckConnectionButtonProps) {
	const navigate = useNavigate();

	async function onClick() {
		logger.info('Начинаем переход на главную страницу');
		if (props.needResetMain) {
			let mainPageUrl = commonInfo.getMainPageUrl();
			resetMainPage();
			navigate(mainPageUrl, { replace: true, preventScrollReset: true });
			return;
		}
		window.location.reload();
	}

	return (
		<button
			className={styles.btn1}
			onClick={onClick}
		>
			Проверить
		</button>
	);
}
