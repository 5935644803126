import { observer } from 'mobx-react-lite';
import styles from './text-with-error-indication.module.scss';

export interface TextWithErrorIndicationProps {
	text: string;
	error?: boolean;
}

function TextWithErrorIndicationElement(props: TextWithErrorIndicationProps) {
	return (
		<div className={styles.textBlock}>
			<span className={styles.text}>{props.text}</span>
			<svg
				className={props.error ? styles.errorIndicator : styles.displayNone}
				viewBox="0 0 4 4"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="2"
					cy="2"
					r="2"
				/>
			</svg>
		</div>
	);
}

export const TextWithErrorIndication = observer(TextWithErrorIndicationElement);
