import { MouseEventHandler } from 'react';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import styles from './not-same-org-modal-card.module.scss';
import { observer } from 'mobx-react-lite';

export interface NotSameOrgModalCardElementProps {
	closeButtonEvent: MouseEventHandler<HTMLButtonElement>;
	retryAuthEvent: MouseEventHandler<HTMLButtonElement>;
}

function NotSameOrgModalCardElement(props: NotSameOrgModalCardElementProps) {
	return (
		<ActionModalCardTemplate
			title="Ошибка авторизации"
			body={<div id={styles.mainText}>Учетные данные не соответствуют выбранной организации</div>}
			footerButtons={[
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.retryAuthEvent}
				>
					Повторить вход
				</button>,
			]}
			onClickCrossButton={props.closeButtonEvent}
		/>
	);
}

export const NotSameOrgModalCard = observer(NotSameOrgModalCardElement);
