import { updateStateForAllOrg } from '@features/settings/auto-import/business-logic/api-provider';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { OrgCard } from '../../org-card/org-card';
import { OrgsList } from '@features/settings/common/components/orgs-list';
import { useOrgQueryParams } from '@features/settings/common/hooks/use-org-query-params';
import logger from '@logger';

function ContentNormalElement() {
	useOrgQueryParams((type, orgId) => {
		switch (type?.toLowerCase()) {
			case 'editdir':
				autoImportStore.startOrgEditingById(orgId);
				break;
			case 'reauth':
				autoImportStore.selectOrg(orgId);
				autoImportStore.setSelectedOrgHighlight(true);
				break;
			default:
				logger.info(`Передан несуществующий тип операции: [${type}]`);
		}
	});

	async function onAllButtonEnableDisableClick(currentState: boolean) {
		let newOrgList = await updateStateForAllOrg(currentState, autoImportStore.searchText);
		if (newOrgList !== undefined) {
			autoImportStore.setOrgList(newOrgList);
		}
	}

	async function onSearchInput(searchText: string) {
		autoImportStore.setSearchText(searchText);
		await autoImportStore.loadOrgs();
	}

	function createOrgCards(): ReactElement[] {
		return autoImportStore.orgList.map(org => (
			<OrgCard
				key={org.orgId}
				orgInfo={org}
			/>
		));
	}

	return (
		<OrgsList
			orgs={createOrgCards()}
			addOrgAction={autoImportStore.makeOauth}
			search={{ onChange: onSearchInput, placeholder: 'Название организации...' }}
			enableDisableSwitch={{
				enableAll: autoImportStore.isAllOrgDisabled,
				onClick: onAllButtonEnableDisableClick,
			}}
		/>
	);
}

export const ContentNormal = observer(ContentNormalElement);
