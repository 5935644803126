import { SystemTypes } from '@enums/system-types';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SystemInfo {
	@jsonMember(() => String)
	public kernel!: string;

	@jsonMember(() => String)
	public architecture!: string;

	@jsonMember(() => Number)
	public type!: SystemTypes;

	@jsonMember(() => String)
	public build!: string;

	@jsonMember(() => String)
	public name!: string;

	@jsonMember(() => String)
	public versionMajor!: string;

	@jsonMember(() => String)
	public versionMinor!: string;

	@jsonMember(() => Boolean)
	public isServer?: boolean;
}
