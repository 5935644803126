import styles from './pages-navigation-grid.module.scss';
import { ReactElement } from 'react';

export function PagesNavigationGrid() {
	function getImgSrc(name: string) {
		return require('@assets/page-icons/' + name);
	}

	return (
		<div id={styles.links}>
			<Link
				title="Настройка прокси"
				route="/settings/proxy"
				imgSrc={getImgSrc('proxy.svg')}
			/>
			<Link
				title={
					<>
						Автозагрузка файлов
						<br />
						в Онлайн-Спринтер
					</>
				}
				route="/settings/autoimport"
				imgSrc={getImgSrc('auto-import.svg')}
			/>
			<Link
				title={
					<>
						Мастер настройки
						<br />
						рабочего места
					</>
				}
				route="/"
				imgSrc={getImgSrc('setup-wizard.svg')}
			/>
			{/*TODO SMA Раскомментить перед релизом автоподписи*/}
			{/* <Link
				title={
					<>
						Автоматическое
						<br />
						подписание документов
					</>
				}
				route="/settings/autosign"
				imgSrc={getImgSrc('auto-sign.svg')}
			/> */}
		</div>
	);
}

function Link(props: { imgSrc: string; title: string | ReactElement; route: string }) {
	return (
		<button
			className={styles.link}
			onClick={() => (window.location.href = props.route)}
		>
			<img src={props.imgSrc}></img>
			<span className={styles.title}>{props.title}</span>
		</button>
	);
}
