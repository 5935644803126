import { jsonMember, jsonObject } from 'typedjson';
import { CertStatus } from '../../enums/api/cert-status';

@jsonObject
export class Cert {
	@jsonMember(String)
	public certThumbprint!: string;

	@jsonMember(Date)
	public certExpDate!: Date;

	@jsonMember(String)
	public ownerGivenName!: string;

	@jsonMember(String)
	public ownerSurName!: string;

	@jsonMember(String)
	public companyName!: string;

	@jsonMember(String, { name: 'companyINN' })
	public companyInn!: string;

	@jsonMember(String)
	public certStatus!: CertStatus;

	@jsonMember(String)
	public certBody!: string;

	@jsonMember(Boolean, { name: 'isCertNewQES' })
	public isCertNewQes!: boolean;
}
