import dateFormat from 'dateformat';
import log, { LogLevelDesc } from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

prefix.reg(log);
prefix.apply(log, {
	template: '[%t] %l (%n):',
	levelFormatter(level) {
		return level.toUpperCase();
	},
	nameFormatter(name) {
		return name || 'global';
	},
	timestampFormatter(date) {
		return dateFormat(date, 'dd.mm.yyyy HH:MM:ss');
	},
});

const loggerName = process.env.REACT_APP_LOGGER_NAME as string;
const loggerLevel: LogLevelDesc = (process.env.REACT_APP_LOGGER_LEVEL as LogLevelDesc) || 'trace';

log.setLevel(loggerLevel);
export default log.getLogger(loggerName);

/**
 * @param name
 */
export function createLogger(name: string): log.Logger {
	return log.getLogger(name);
}
