import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import { ModalStore } from '../../../../../stores/modal-store';

export interface Props {
	modalStore: ModalStore;
}

function NoServerLicenseModal(props: Props) {
	return (
		<InfoModalCardTemplate
			title="Отсутствует серверная лицензия"
			image={<Attention />}
			onClickCrossButton={props.modalStore.hide}
			centerCross
		/>
	);
}

export const showNoServerLicenseModal = (props: Props) => {
	props.modalStore.show({
		children: <NoServerLicenseModal {...props} />,
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
