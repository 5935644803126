import { InstructionLink } from '@components/instruction-link';
import { DemoInstallModalCard } from '@components/modal-cards/demo-install-modal-card/demo-install-modal-card';
import { Page, PageImage } from '@components/page-template';
import { RadioWithContent } from '@components/radio-with-content';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { useState } from 'react';
import styles from './cryptography-license.module.scss';
import { EnterSerialRadio } from './enter-serial-radio';
import { findSerial } from '@features/setup-wizard/models/pages';
import { CpLicenseEnterType } from '@features/setup-wizard/enums/cp-license-enter-type';

enum ActivationMethod {
	SerialNumber,
	ByCert,
	Demo,
}

export function CryptographyLicense() {
	const [activationMethod, setActivationMethod] = useState<ActivationMethod>(ActivationMethod.SerialNumber);
	const [serialNumber, setSerialNumber] = useState('');
	const [showModal, setModalVisibility] = useState(false);
	const { setupMode, restartStatus } = cpStore;
	const enterLicenseWindowType = cpStore.enterLicenseWindowType();
	const findSerialAvailable =
		setupMode === CpSetupMode.UpdateMajorVersion ||
		(setupMode === CpSetupMode.UpdateMinorVersion && restartStatus === null);

	const disableNextButton =
		activationMethod === ActivationMethod.SerialNumber && !/^[a-zA-Z\d]{25}$/.test(serialNumber);

	function handleLicense() {
		cpLicenseStore.setLicenseKey(serialNumber);
		store.setPage(cpStore.cpSetupPage);
	}

	function onNextButtonClick() {
		switch (activationMethod) {
			case ActivationMethod.ByCert:
				if (findSerialAvailable) {
					store.setPage(findSerial());
					break;
				}
				cpLicenseStore.scheduleEnterCpKey(true);
				handleLicense();
				break;
			case ActivationMethod.Demo:
				setModalVisibility(true);
				break;
			case ActivationMethod.SerialNumber:
				handleLicense();
				break;
		}
	}

	function openBuyLicenseUrl() {
		window.open(process.env.REACT_APP_INSTR_UPDATE_LICENSE!, '_blank');
	}

	return (
		<Page
			image={PageImage.Cryptography}
			child={
				<div className={styles.main}>
					<div id={styles.title}>
						{setupMode === CpSetupMode.UpdateMajorVersion ? 'Обновление КриптоПро' : 'Настройка КриптоПро'}
					</div>
					<div id={styles.subtitle}>Выберите вариант активации КриптоПро CSP 5</div>
					<div className={styles.form}>
						<RadioWithContent
							title={'Серийный номер'}
							checked={activationMethod === ActivationMethod.SerialNumber}
							onClick={() => setActivationMethod(ActivationMethod.SerialNumber)}
						>
							<div className={styles.description}>
								{enterLicenseWindowType === CpLicenseEnterType.Default ? (
									<>
										Введите серийный номер лицензии для версии 4 или 5, доступный в 
										<InstructionLink
											link={process.env.REACT_APP_INSTR_LK_CP_LICENSE!}
											text="личном кабинете"
										/>
									</>
								) : (
									<>Введите серийный номер, указанный в письме «Уведомление о выделении лицензии»</>
								)}
							</div>
							<EnterSerialRadio
								serialNumber={serialNumber}
								setSerialNumber={setSerialNumber}
							/>
						</RadioWithContent>
						{/*<RadioWithContent*/}
						{/*	title={'Серийный номер по сертификату'}*/}
						{/*	checked={activationMethod === ActivationMethod.ByCert}*/}
						{/*	onClick={() => setActivationMethod(ActivationMethod.ByCert)}*/}
						{/*>*/}
						{/*	<div className={styles.description}>*/}
						{/*		{findSerialAvailable*/}
						{/*			? 'Выберите сертификат, который вы использовали для входа в сервисы Такском'*/}
						{/*			: 'После установки демо-лицензии выберите сертификат, который вы использовали для входа в сервисы Такском'}*/}
						{/*	</div>*/}
						{/*</RadioWithContent>*/}
						<RadioWithContent
							title={'Демо-лицензия на 30 дней'}
							checked={activationMethod === ActivationMethod.Demo}
							onClick={() => setActivationMethod(ActivationMethod.Demo)}
						>
							<div className={styles.description}>
								Если вы уже использовали КриптоПро с демо-лицензией на этом компьютере, то 
								<InstructionLink
									link={process.env.REACT_APP_INSTR_UPDATE_LICENSE!}
									text="купите новый серийный номер"
								/>
							</div>
						</RadioWithContent>
					</div>
					<div className={styles.buttonsArea}>
						<button
							disabled={disableNextButton}
							onClick={() => onNextButtonClick()}
							className={styles.buttonBlue}
						>
							Продолжить
						</button>
						<button
							onClick={() => openBuyLicenseUrl()}
							className={styles.buttonTransparentBlue}
						>
							Купить лицензию
						</button>
					</div>
				</div>
			}
			showMobileView={false}
			modalCard={
				<DemoInstallModalCard
					onBuyNewKeyClick={() => {
						openBuyLicenseUrl();
						setModalVisibility(false);
					}}
					onContinueInstallationClick={() => handleLicense()}
					onCancel={() => setModalVisibility(false)}
				/>
			}
			modalActive={showModal}
			onClickOutOfModalCard={() => setModalVisibility(false)}
		/>
	);
}
