import { OrgCutInfo } from '@features/settings/auto-import/models/org-cut-info';

export function cutOrgName(orgName: string): OrgCutInfo {
	let nameRes = orgName;
	let hoverName: string | undefined = undefined;
	let len = orgName.length;
	if (len > 150) {
		nameRes = `${orgName.substring(0, 150)}...`;
		hoverName = orgName;
	}

	return new OrgCutInfo(nameRes, hoverName);
}
