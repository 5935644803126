import { InputWithHint } from '@components/input-with-hint';
import { SelectDropDown } from '@components/select-drop-down';
import { showError } from '@features/settings/proxy/business-logic/validation';
import { protocols } from '@features/settings/proxy/data/protocols';
import { proxySettingsStore } from '@features/settings/proxy/stores/proxy-settings-store';
import { Authentication } from '../../authentication';
import { InputErrorHint } from '../../input-error-hint';
import { PingStatusInfo } from '../../ping-status-info';
import { TextWithErrorIndication } from '../../text-with-error-indication';
import styles from './content-normal.module.scss';
import { observer } from 'mobx-react-lite';

function ContentNormalPage() {
	return (
		<div id={styles.content}>
			<div id={styles.mainInput}>
				<label htmlFor="protocol">
					<TextWithErrorIndication text="Протокол" />
				</label>
				<label htmlFor="serverAddress">
					<TextWithErrorIndication
						text="Адрес сервера"
						error={showError(proxySettingsStore.addressInputState)}
					/>
				</label>
				<label
					htmlFor="port"
					data-tooltip-id="my-tooltip"
				>
					<TextWithErrorIndication
						text="Порт"
						error={showError(proxySettingsStore.portInputState)}
					/>
				</label>
				<SelectDropDown
					options={protocols}
					optionIndex={proxySettingsStore.protocolIndex}
					onChange={i => {
						proxySettingsStore.setProtocolIndex(i);
					}}
				/>
				<InputWithHint
					tooltip={{
						children: (
							<div>
								Укажите доменное имя, IPv4 адрес
								<br /> или комбинацию из них с портом
								<br /> через двоеточие. Например, 8.8.0.0,
								<br /> прокси.рф или proxy.org:8080.
							</div>
						),
						id: 'address',
					}}
					placeholder="example.ru или 10.10.10.5"
					value={proxySettingsStore.address}
					onChange={e => proxySettingsStore.setAddress(e.target.value)}
					onBlur={() => proxySettingsStore.handleAddress()}
					wrong={showError(proxySettingsStore.addressInputState)}
				/>
				<InputWithHint
					tooltip={{
						children: (
							<div>
								Используйте числа в диапазоне
								<br />
								от 1 до 65535
							</div>
						),
						id: 'port',
						place: 'top-end',
					}}
					placeholder="8000"
					numberInput
					value={proxySettingsStore.port ?? ''}
					onChange={e => proxySettingsStore.trySetPort(e.target.value)}
					onBlur={() => proxySettingsStore.updatePortInputState()}
					wrong={showError(proxySettingsStore.portInputState)}
				/>
			</div>
			<InputErrorHint
				addressState={proxySettingsStore.addressInputState}
				portState={proxySettingsStore.portInputState}
			/>
			<Authentication />
			<PingStatusInfo
				status={proxySettingsStore.pingStatus}
				onClickInterruptButton={() => proxySettingsStore.saveStore.stopPing()}
				onClickRepeatButton={() => proxySettingsStore.saveStore.saveSettings()}
			/>
		</div>
	);
}

export const ContentNormal = observer(ContentNormalPage);
