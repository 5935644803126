import { RefObject, useState } from 'react';
import { useResizeObserver } from './resize-observer';

export function useOverflowObserver(ref: RefObject<HTMLDivElement>): boolean {
	const [hasOverflow, setOverflowState] = useState(false);

	useResizeObserver(ref, element => {
		setOverflowState(element.scrollHeight > element.clientHeight);
	});

	return hasOverflow;
}
