import styles from './delete-organization-modal-card-template.module.scss';
import { MouseEventHandler, ReactElement } from 'react';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import { ExclamationBlock } from '../../exclamation-block';

export interface DeleteOrganizationModalCardProps {
	title: string;
	description: string | ReactElement;
	exclamation: string | ReactElement;

	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
	onClickCancelButton?: MouseEventHandler<HTMLButtonElement>;
	onClickDelete?: MouseEventHandler<HTMLButtonElement>;
}

export function DeleteOrganizationModalCardTemplate(props: DeleteOrganizationModalCardProps) {
	return (
		<ActionModalCardTemplate
			title={props.title}
			body={
				<div id={styles.body}>
					<div className={styles.description}>{props.description}</div>
					<ExclamationBlock content={props.exclamation} />
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonRed}
					onClick={props.onClickDelete}
				>
					Удалить
				</button>,
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.onClickCancelButton}
				>
					Отменить
				</button>,
			]}
			onClickCrossButton={props.onClickCrossButton}
		/>
	);
}
