import { Page, PageImage } from '@components/page-template';
import { ButtonSkeleton } from '@components/skeletons/button-skeleton';
import { TitleWithArrow } from '@components/title-with-arrow';
import { CertList } from '@features/cert-list';
import { Loading, State as CertState } from '@features/cert-list/models/states';
import { ChooseCertStatus } from '@features/cp-license/enums/choose-cert-status';
import { showSomethingWentWrongModal } from '@features/settings/auto-sign/common/modals/something-went-wrong-modal';
import { cryptographyLicense, setupSucceeded } from '@features/setup-wizard/models/pages';
import { store } from '@features/setup-wizard/stores/store';
import { Cert } from '@models/api/cert';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';
import { handleCert } from '../../../features/cp-license/business-logic/cert-handler';
import { chooseCertModalStore } from '../../../features/cp-license/business-logic/choose-cert-modal-store';
import styles from './choose-cert.module.scss';

export interface Props {
	status: ChooseCertStatus;
}

export const ChooseCert = observer((props: Props) => {
	const [certState, setCertState] = useState<CertState>(new Loading());
	const [selected, select] = useState<Cert>();
	const withArrow = props.status === ChooseCertStatus.UpdateCp;

	useEffect(() => {
		chooseCertModalStore.hide();
	}, []);

	function renderTitle(): ReactElement {
		switch (props.status) {
			case ChooseCertStatus.UpdateCp:
				return (
					<TitleWithArrow
						title="Найти серийный номер"
						subtitle="по сертификату"
						onArrowClick={() => {
							store.setPage(cryptographyLicense());
						}}
					/>
				);
			case ChooseCertStatus.InstalledCp5Demo:
				return <div id={styles.title}>Установлена демо-лицензия КриптоПро CSP 5</div>;
		}
	}

	function renderSubtitle(): string {
		const subtitle = 'Выберите сертификат, который вы использовали для входа в продукты Такском';
		switch (props.status) {
			case ChooseCertStatus.UpdateCp:
				return subtitle;
			case ChooseCertStatus.InstalledCp5Demo:
				return subtitle + ', чтобы активировать КриптоПро';
		}
	}

	function renderButton(button: ReactElement): ReactElement {
		switch (certState.kind) {
			case 'Loading':
				return (
					<div className={styles.nextButton}>
						<ButtonSkeleton width={159} />
					</div>
				);
			case 'ShowCerts':
				return button;
			case 'NoCerts':
				return <></>;
		}
	}

	async function onNextButtonClick() {
		if (!selected) {
			return;
		}
		await handleCert(selected, props.status);
	}

	function onWithoutActivationClick() {
		store.setPage(setupSucceeded());
	}

	return (
		<>
			<Page
				image={PageImage.Cryptography}
				rightPaneContentClassName={[styles.rightPaneContent, withArrow ? styles.withArrow : null].join(' ')}
				child={
					<div className={[styles.main, withArrow ? styles.withArrow : null].join(' ')}>
						<div id={styles.title}>{renderTitle()}</div>
						<div id={styles.subtitle}>{renderSubtitle()}</div>
						<CertList
							state={certState}
							setState={setCertState}
							selected={selected}
							select={select}
							onError={() => showSomethingWentWrongModal({ modalStore: chooseCertModalStore })}
						/>
						<div className={styles.buttons}>
							{renderButton(
								<button
									disabled={selected === undefined}
									className={styles.buttonBlue}
									onClick={async () => await onNextButtonClick()}
								>
									Продолжить
								</button>,
							)}
							{props.status === ChooseCertStatus.InstalledCp5Demo &&
								renderButton(
									<button
										onClick={() => onWithoutActivationClick()}
										className={styles.buttonTransparentBlue}
									>
										Продолжить без активации
									</button>,
								)}
						</div>
					</div>
				}
				fixedHeight
			/>
			{chooseCertModalStore.modal}
		</>
	);
});
