import styles from './page-not-found.module.scss';
import { Page, PageImage } from '@components/page-template';
import { observer } from 'mobx-react-lite';
import { PagesNavigationGrid } from '@components/pages-navigation-grid';

function PageNotFoundElement() {
	return (
		<Page
			image={PageImage.Warning}
			child={
				<>
					<div id={styles.title}>Страница не найдена</div>
					<div id={styles.subtitle}>Возможно Вы искали:</div>
					<PagesNavigationGrid />
				</>
			}
			showMobileView={false}
		/>
	);
}

export const PageNotFound = observer(PageNotFoundElement);
