import styles from './progress-list.module.scss';
import { ReactElement } from 'react';

export interface Props {
	selected: number;
	stages: string[];
}

export function ProgressList(props: Props) {
	function checkOnSelected(index: number): string {
		if (index < props.selected) return styles.done;
		if (index === props.selected) return styles.selected;
		return styles.unselected;
	}

	// TODO SMA подумать касательно нормальности использования индекса в качества ключа здесь
	function getStages(): ReactElement[] {
		return props.stages.map((stage, index) => {
			return (
				<div key={index}>
					<span className={checkOnSelected(index)}>{stage}</span>
				</div>
			);
		});
	}

	return <div id={styles.progressList}>{getStages()}</div>;
}
