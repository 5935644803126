export enum ModalState {
	OrgDouble,
	DeleteOrg,

	DirectoryAlreadyInUse,
	WrongDirectory,

	EnterOs,
	AuthError,
	NotSameOrg,

	SomethingWentWrong,
	None,
}
