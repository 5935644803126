import { TaConnectionLostCard } from '@features/settings/common/components/ta-connection-lost-card';
import { PageStatus } from '@features/settings/common/enums/page-body-status';
import { proxySettingsStore } from '@features/settings/proxy/stores/proxy-settings-store';
import { ContentSkeleton } from './content-skeleton';
import { ContentNormal } from '@features/settings/proxy/components/content-block/content-normal';
import { observer } from 'mobx-react-lite';

function ContentBlockElement() {
	switch (proxySettingsStore.pageStatus) {
		case PageStatus.Ok:
			return <ContentNormal />;
		case PageStatus.Skeleton:
			return <ContentSkeleton />;
		default:
			return <TaConnectionLostCard />;
	}
}

export const ContentBlock = observer(ContentBlockElement);
