import { Modal as BasicModal } from '@components/modal';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { proxySettingsStore } from '../../stores/proxy-settings-store';
import { ResetSettingsModalCard } from '../modal-cards/reset-settings-modal-card';
import { SettingsNotSavedModalCard } from '@features/settings/proxy/components/modal-cards/settings-not-saved-modal-card';
import { SomethingWentWrongModalCard } from '@features/settings/proxy/components/modal-cards/something-went-wrong-modal-card';
import { TaRestartModalCard } from '@features/settings/proxy/components/modal-cards/ta-restart-modal-card';
import { ModalState } from '../../enums/modal-state';

function ModalElement() {
	function refreshPage() {
		window.location.reload();
	}

	function getModalCard(): ReactElement {
		switch (proxySettingsStore.modalState) {
			case ModalState.ResetSettings:
				return (
					<ResetSettingsModalCard
						onReset={() => proxySettingsStore.saveStore.deleteSettings()}
						onCancel={cancel}
					/>
				);
			case ModalState.SettingsNotSaved:
				return <SettingsNotSavedModalCard onClickCrossButton={cancel} />;
			case ModalState.SomethingWentWrong:
				return <SomethingWentWrongModalCard onClickCrossButton={cancel} />;
			case ModalState.TaRestart:
				return <TaRestartModalCard />;
			default:
				return <></>;
		}
	}

	function cancel(): void {
		switch (proxySettingsStore.modalState) {
			case ModalState.SettingsNotSaved:
			case ModalState.SomethingWentWrong:
				refreshPage();
				break;
			case ModalState.ResetSettings:
				proxySettingsStore.setModalState(ModalState.None);
				break;
		}
	}

	return (
		<BasicModal
			active={proxySettingsStore.modalState !== ModalState.None}
			children={getModalCard()}
			onClickOutOfChild={cancel}
		/>
	);
}

export const Modal = observer(ModalElement);
