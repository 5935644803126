import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import '@fontsource/open-sans';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans';
import { BrowserRouter } from 'react-router-dom';
import { NotificationContainer } from '@components/notification';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<App />
		<NotificationContainer />
	</BrowserRouter>,
	// </React.StrictMode>
);
