import { MouseEventHandler, ReactNode } from 'react';
import styles from './radio-with-content.module.scss';

interface Props {
	title: string;
	children?: ReactNode;
	checked: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
}

export function RadioWithContent(props: Props) {
	return (
		<div
			onClick={props.onClick}
			className={[styles.main, props.checked ? styles.checked : undefined].join(' ')}
		>
			<input
				type={'radio'}
				checked={props.checked}
				readOnly
			></input>
			<button />
			<div className={styles.content}>
				<div className={styles.name}>{props.title}</div>
				{props.children}
			</div>
		</div>
	);
}
