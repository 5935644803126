import { store } from './store';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { commonInfo } from '@businessLogic/common-info';
import { preliminaryChecks } from '../models/pages';

export function resetMainPage() {
	commonInfo.setDefaultInfo();
	stagesWorker.updateStages();
	store.setPage(preliminaryChecks());
}
