import { CheckCryptoResult } from '@features/setup-wizard/models/api/cryptography/check-crypto-result';
import { getFromAgentHttps } from '@helpers/fetch-helper';
import logger from '@logger';
import { deserializeJson } from '@helpers/json-helper';
import { CryptoCheckResult } from '@features/setup-wizard/enums/crypto-check-result';
import { License } from '@features/setup-wizard/models/api/cryptography';
import { ExtendData } from '@features/setup-wizard/models/api/cryptography/check-crypto-result/extend-data';

const dayDurationMs = 8.64e7;

function isExpireSoon(expiration: Date): boolean {
	const current = new Date();
	const licenseValidity = dayDurationMs * parseInt(process.env.REACT_APP_CP_LICENSE_VALIDITY_PERIOD_DAYS!);
	return expiration.getMilliseconds() - current.getMilliseconds() > licenseValidity;
}

function getUpdateMinorVersionCheckResult(license: License): CryptoCheckResult {
	const extendData = license.extendData;
	if (extendData) {
		if (extendData.isPerpetual || (extendData.expDate && !isExpireSoon(extendData.expDate))) {
			return CryptoCheckResult.UpdateMinorVersion;
		}
		return CryptoCheckResult.UpdateMinorVersionWithNewLicense;
	}
	if (license.isActive) {
		return CryptoCheckResult.UpdateMinorVersion;
	}
	return CryptoCheckResult.UpdateMinorVersionWithNewLicense;
}

function getUpdateMajorVersionCheckResult(license: License): CryptoCheckResult {
	const extendData = license.extendData;
	if ((!extendData && license.isActive) || extendData?.isPerpetual) {
		return CryptoCheckResult.UpdatePerpetualLicense;
	}
	if (license.isActive) {
		return CryptoCheckResult.UpdateAnnualLicense;
	}
	return CryptoCheckResult.LicenseExpired;
}

function getVipnetCheckResult(extendData?: ExtendData): CryptoCheckResult {
	if (extendData?.isPerpetual) {
		return CryptoCheckResult.VipnetUpdatePerpetualLicense;
	}
	return CryptoCheckResult.VipnetUpdateAnnualLicense;
}

export async function cryptoCheck(): Promise<CryptoCheckResult> {
	let result = await checkCryptoProvider();
	if (!result) return CryptoCheckResult.NotFound;

	let majorNumber = parseInt(result.cryptoPro.major);
	let build = parseInt(result.cryptoPro.build);

	if (isNaN(majorNumber) || isNaN(build)) return CryptoCheckResult.NotFound;
	if (majorNumber === 5 && build >= 12000) return CryptoCheckResult.UpdateNotRequired;
	if (result.isVipNet) return getVipnetCheckResult(result.cryptoPro.license.extendData);
	if (majorNumber === 5) return getUpdateMinorVersionCheckResult(result.cryptoPro.license);
	return getUpdateMajorVersionCheckResult(result.cryptoPro.license);
}

// TODO SMA возможно вынести
export async function checkCryptoProvider(): Promise<CheckCryptoResult | undefined> {
	let response = await getFromAgentHttps(`${process.env.REACT_APP_AGENT_EP_CHECK_CRYPTO!}`);
	if (response.ok) {
		return deserializeJson(CheckCryptoResult, await response.text());
	}
	if (response.status === 409) {
		logger.info('На компьютере не найден КриптоПро!');
		return undefined;
	}
	throw new Error(`Получен необрабатываемый код ${response.status} при запросе информации о КриптоПро!`);
}
