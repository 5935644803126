import styles from './search-input.module.scss';
import { ReactComponent as Loupe } from '@assets/icons/magnifier.svg';
import { ReactComponent as Cross } from '@assets/icons/cross.svg';
import { useEffect, useState } from 'react';

export interface SearchInputProps {
	onChange: (content: string) => void;
	placeholder: string;
}

export function SearchInput(props: SearchInputProps) {
	let [searchString, setSearchString] = useState('');
	let [isInput, setIsInput] = useState(false);

	useEffect(() => {
		if (isInput) {
			const inputTimeOut = setTimeout(() => {
				const searchStringHandled = searchString.trim();
				if (searchStringHandled.length < 3 && searchStringHandled.length !== 0) {
					return;
				}
				props.onChange(searchStringHandled);
			}, 750);
			return () => clearTimeout(inputTimeOut);
		}
	}, [searchString]);

	return (
		<div id={styles.main}>
			<Loupe id={styles.magnifier} />
			<input
				type={'text'}
				placeholder={props.placeholder}
				value={searchString}
				autoComplete="off"
				onChange={e => {
					setIsInput(true);
					setSearchString(e.target.value);
				}}
			/>
			{searchString.length !== 0 && (
				<Cross
					onMouseDown={() => {
						setSearchString('');
						props.onChange('');
					}}
					id={styles.cross}
				/>
			)}
		</div>
	);
}
