import { MouseEventHandler } from 'react';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import styles from './auth-error-modal-card.module.scss';
import { observer } from 'mobx-react-lite';

export interface AuthErrorModalCardProps {
	closeButtonEvent: MouseEventHandler<HTMLButtonElement>;
	openSupportEvent: MouseEventHandler<HTMLButtonElement>;
	retryAuthEvent: MouseEventHandler<HTMLButtonElement>;
}

function AuthErrorModalCardElement(props: AuthErrorModalCardProps) {
	return (
		<ActionModalCardTemplate
			title="Ошибка авторизации"
			body={<div className={styles.description}>Не удалось обновить организацию</div>}
			footerButtons={[
				<button
					className={styles.buttonBlue}
					onClick={props.retryAuthEvent}
				>
					Повторить вход
				</button>,
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.openSupportEvent}
				>
					Техподдержка
				</button>,
			]}
			onClickCrossButton={props.closeButtonEvent}
		/>
	);
}

export const AuthErrorModalCard = observer(AuthErrorModalCardElement);
