import styles from './select-drop-down.module.scss';
import { ReactComponent as Chevron } from '@assets/icons/chevron.svg';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '@helpers/outside-click';

export interface SelectDropDownProps {
	options: readonly string[];
	onChange?: (optionIndex: number) => void;
	optionIndex?: number;
}

export function SelectDropDown(props: SelectDropDownProps) {
	let [optionIndex, setOptionIndex] = useState(props.optionIndex ?? 0);
	let [checked, setCheckedState] = useState(false);

	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, () => {
		setCheckedState(false);
	});

	useEffect(() => {
		if (props.optionIndex !== undefined) {
			setOptionIndex(props.optionIndex);
		}
	}, [props.optionIndex]);

	function onChangeOption(index: number) {
		props.onChange?.(index);
		if (props.optionIndex === undefined) {
			setOptionIndex(index);
		}
		setCheckedState(false);
	}

	return (
		<div
			ref={wrapperRef}
			className={styles.dropdown}
		>
			<input
				checked={checked}
				onChange={() => setCheckedState(!checked)}
				type="checkbox"
				id={styles.selectCheckbox}
			/>
			<label htmlFor={styles.selectCheckbox}>
				<span>{props.options[optionIndex]}</span>
				<Chevron className={checked ? [styles.chevron, styles.chevronShowContent].join(' ') : styles.chevron} />
			</label>
			<div className={styles.menu}>
				{props.options.map((option, index) => (
					<button
						className={optionIndex === index ? styles.checkedOption : ''}
						key={index}
						onClick={() => onChangeOption(index)}
					>
						{option}
					</button>
				))}
			</div>
		</div>
	);
}
