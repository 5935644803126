import { MouseEventHandler, ReactNode } from 'react';
import styles from './modal.module.scss';

export interface ModalProps {
	children?: ReactNode;
	active: boolean;
	onClickOutOfChild?: MouseEventHandler<HTMLDivElement>;
	style?: string;
}

export function Modal(props: ModalProps) {
	return (
		<div
			className={[styles.modal, props.active ? styles.active : null, props.style].join(' ')}
			onClick={props.onClickOutOfChild}
		>
			<div onClick={e => e.stopPropagation()}>{props.children}</div>
		</div>
	);
}
