import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import { makeAutoObservable } from 'mobx';

class StoreSettings {
	public page?: PagesSettings;

	public constructor() {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	public setPage(page: PagesSettings) {
		this.page = page;
	}
}

const storeSettings = new StoreSettings();

export { storeSettings };
