import { UpdateCpMinorVersionErrorType } from '@features/update-cp-minor-version/enums/update-cp-minor-version-error-type';
import { UpdateCpMinorVersionStage } from '@features/update-cp-minor-version/enums/update-cp-minor-version-stage';

export interface PreliminaryChecks {
	kind: 'PreliminaryChecks';
}

export interface CheckUp {
	kind: 'CheckUp';
}

export interface NoConnection {
	kind: 'NoConnection';
}

export interface NoConnectionTerminal {
	kind: 'NoConnectionTerminal';
}

export interface NotFound {
	kind: 'NotFound';
}

export interface NotSupportedOs {
	kind: 'NotSupportedOs';
}

export interface Setup {
	kind: 'Setup';
}

export interface SetupFailed {
	kind: 'SetupFailed';
}

export interface SetupSettings {
	kind: 'SetupSettings';
}

export interface SetupSucceeded {
	kind: 'SetupSucceeded';
}

export interface CryptographyLicense {
	kind: 'CryptographyLicense';
}

export interface NonActualVersion {
	kind: 'NonActualVersion';
}

export interface FindSerial {
	kind: 'FindSerial';
}

export interface InstalledCp5Demo {
	kind: 'InstalledCp5Demo';
}

export interface CpInstallErrorWhenVipnet {
	kind: 'CpInstallErrorWhenVipnet';
}

export interface CpNoActualLicense {
	kind: 'CpNoActualLicense';
}

export interface InstallCp {
	kind: 'InstallCp';
}

export interface NoAccessToRestartAfterInstallCp {
	kind: 'NoAccessToRestartAfterInstallCp';
}

export interface DownloadCpError {
	kind: 'DownloadCpError';
}

export interface InstallCpError {
	kind: 'InstallCpError';
}

export interface NoAccessToInstallCp {
	kind: 'NoAccessToInstallCp';
}

export interface UpdateCpMinorVersionStart {
	kind: 'UpdateCpMinorVersionStart';
}

export interface UpdateCpMinorVersionProcess {
	kind: 'UpdateCpMinorVersionProcess';
}

export interface UpdateCpMinorVersionRestartFailure {
	kind: 'UpdateCpMinorVersionRestartFailure';
}

export interface UpdateCpMinorVersionError {
	kind: 'UpdateCpMinorVersionError';
	errorType: UpdateCpMinorVersionErrorType;
}

export interface UpdateCpMinorVersion {
	kind: 'UpdateCpMinorVersion';
	stage: UpdateCpMinorVersionStage;
}

export const preliminaryChecks = (): PreliminaryChecks => ({ kind: 'PreliminaryChecks' });
export const checkUp = (): CheckUp => ({ kind: 'CheckUp' });
export const noConnection = (): NoConnection => ({ kind: 'NoConnection' });
export const noConnectionTerminal = (): NoConnectionTerminal => ({ kind: 'NoConnectionTerminal' });
export const notFound = (): NotFound => ({ kind: 'NotFound' });
export const notSupportedOs = (): NotSupportedOs => ({ kind: 'NotSupportedOs' });
export const setup = (): Setup => ({ kind: 'Setup' });
export const setupFailed = (): SetupFailed => ({ kind: 'SetupFailed' });
export const setupSettings = (): SetupSettings => ({ kind: 'SetupSettings' });
export const setupSucceeded = (): SetupSucceeded => ({ kind: 'SetupSucceeded' });
export const cryptographyLicense = (): CryptographyLicense => ({ kind: 'CryptographyLicense' });
export const nonActualVersion = (): NonActualVersion => ({ kind: 'NonActualVersion' });
export const findSerial = (): FindSerial => ({ kind: 'FindSerial' });
export const installedCp5Demo = (): InstalledCp5Demo => ({ kind: 'InstalledCp5Demo' });
export const cpInstallErrorWhenVipnet = (): CpInstallErrorWhenVipnet => ({ kind: 'CpInstallErrorWhenVipnet' });
export const cpNoActualLicense = (): CpNoActualLicense => ({ kind: 'CpNoActualLicense' });
export const installCp = (): InstallCp => ({ kind: 'InstallCp' });
export const noAccessToRestartAfterInstallCp = (): NoAccessToRestartAfterInstallCp => ({
	kind: 'NoAccessToRestartAfterInstallCp',
});
export const downloadCpError = (): DownloadCpError => ({ kind: 'DownloadCpError' });
export const installCpError = (): InstallCpError => ({ kind: 'InstallCpError' });
export const noAccessToInstallCp = (): NoAccessToInstallCp => ({ kind: 'NoAccessToInstallCp' });
export const updateCpMinorVersionStart = (): UpdateCpMinorVersionStart => ({ kind: 'UpdateCpMinorVersionStart' });
export const updateCpMinorVersionProcess = (): UpdateCpMinorVersionProcess => ({ kind: 'UpdateCpMinorVersionProcess' });
export const updateCpMinorVersionRestartFailure = (): UpdateCpMinorVersionRestartFailure => ({
	kind: 'UpdateCpMinorVersionRestartFailure',
});
export const updateCpMinorVersionError = (errorType: UpdateCpMinorVersionErrorType): UpdateCpMinorVersionError => ({
	kind: 'UpdateCpMinorVersionError',
	errorType,
});
