import { getModelFromAgentHttps } from '@helpers/fetch-helper';
import logger from '@logger';
import { CryptoProviderVersion } from '@features/setup-wizard/models/api/cryptography/crypto-provider-version';

export async function checkCryptoProviderExists(): Promise<boolean> {
	try {
		let versionInfo = await checkCryptoProviderVersion();
		return versionInfo != null;
	} catch (e) {
		logger.error('Ошибка при попытке запроса данных об крипто провайдере. Вернем что его нет!', e);
		return false;
	}
}

export async function checkCryptoProviderVersion(): Promise<CryptoProviderVersion | undefined> {
	try {
		return await getModelFromAgentHttps(CryptoProviderVersion, process.env.REACT_APP_AGENT_EP_CRYPTO_VERSION!);
	} catch (e) {
		logger.error('Ошибка при попытке запроса данных об крипто провайдере. Вернем что его нет!', e);
		return undefined;
	}
}
