import {
	getModelFromBackend,
	postToAgentHttps,
	getModelFromAgentHttps,
	getFromAgentHttps,
	putToAgentHttps,
} from '@helpers/fetch-helper';
import logger from '@logger';
import {
	StageInfoWithTextContent,
	StageInfo,
	StageInfoWithLinkContent,
} from '@features/setup-wizard/models/stages/infos';
import { AcrobatReaderInfo, PdfSettings, Association } from '@features/setup-wizard/models/api/pdf';
import { TypedJSON } from 'typedjson';
import { SoftTypes } from '@features/setup-wizard/enums/soft-types';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { SystemTypes } from '@enums/system-types';
import { Stage } from '@features/setup-wizard/models/stages';
import { GetDistrInfo } from '@features/setup-wizard/business-logic/api/get-distr-info';
import { commonInfo } from '@businessLogic/common-info';
import { PdfStates } from '@features/setup-wizard/enums/pdf-states';

export class PdfStage extends Stage {
	private pdfState = PdfStates.None;
	private pdfFileProgId?: string;
	private association?: Association;

	private readonly serializerAcrobatReaderInfo = new TypedJSON(AcrobatReaderInfo);

	public constructor() {
		super(StageTypes.Pdf);
	}

	protected async checkUpLogic() {
		let systemInfo = await commonInfo.getSystemInfo();
		let pdfSettings = await getModelFromBackend(
			PdfSettings,
			`${process.env.REACT_APP_EP_PDF_SETTINGS!}?systemType=${systemInfo.type}`,
		);

		if (!pdfSettings.needOnlyPdfAssociation) {
			this.needInstall = false;
			return;
		}

		this.association = await this.getAssociation();
		logger.info('Проверка ассоциации с pdf ', this.association.exists);
		if (!this.association.exists) {
			if (systemInfo.type === SystemTypes.Linux) return;
			await this.checkAcrobatReader();
			return;
		}

		if (systemInfo.type === SystemTypes.Linux || !pdfSettings.associationAdobeWithPdf) {
			this.needInstall = false;
			return;
		}

		await this.checkAcrobatReader();
	}

	protected async setupLogic() {
		if (this.pdfState === PdfStates.SetAssociation) {
			await this.setAssociation();
			return;
		}

		if (this.pdfState !== PdfStates.InstallAdobe) return;

		let distrInfo = await GetDistrInfo(SoftTypes.PdfReader);

		let response = await postToAgentHttps(
			process.env.REACT_APP_AGENT_EP_ACROBAT_READER_INSTALL!,
			JSON.stringify({
				setupURL: distrInfo.linkToDistr,
			}),
		);

		this.association = await this.getAssociation();

		if (response.status === 409) {
			this.pdfFileProgId = this.serializerAcrobatReaderInfo.parse(await response.text())?.GetActual()?.[0]
				.pdfFileProgId;
			await this.setAssociation();
			return;
		}

		if (response.ok) {
			if (this.association!.exists) {
				this.needInstall = false;
				return;
			}

			let acrobatReaderInfo = await getModelFromAgentHttps(
				AcrobatReaderInfo,
				process.env.REACT_APP_AGENT_EP_ACROBAT_READER_INFO!,
			);
			logger.info(acrobatReaderInfo);
			let actual = acrobatReaderInfo.GetActual();
			this.pdfFileProgId = actual![0].pdfFileProgId;

			await this.setAssociation();
			return;
		}
	}

	public getSetupSettingsStageInfo(): StageInfoWithTextContent {
		return new StageInfoWithTextContent(
			'Установить ' + this.getProductToInstallByOs(),
			'Чтобы работать с PDF-документами',
		);
	}

	public getCheckUpStageInfo(): StageInfo {
		return new StageInfo('PDF-редактор');
	}

	public getInstallStageInfo(): StageInfo {
		return new StageInfo('Устанавливаем PDF-редактор');
	}

	public getInstallErrorStageInfo(): StageInfoWithLinkContent {
		let linkRef = process.env.REACT_APP_INSTR_FAILED_PDF_LINUX!;
		let headerText = 'Установите ' + this.getProductToInstallByOs();

		if (commonInfo.getSystemType() !== SystemTypes.Linux) linkRef = process.env.REACT_APP_INSTR_FAILED_PDF_WINDOWS!;

		return new StageInfoWithLinkContent(headerText, {
			link: linkRef,
			textOfLink: 'Скачайте с официального сайта',
			textAfterLink: ' программу для PDF-документов и установите на компьютер',
		});
	}

	private async checkAcrobatReader() {
		let response = await getFromAgentHttps(process.env.REACT_APP_AGENT_EP_ACROBAT_READER_INFO!);

		switch (response.status) {
			case 200:
				if (!this.association!.canChanged) {
					logger.info(
						'Отменяем настройку pdf, так как ранее сервер сообщил нам, что невозможно установить ассоциации pdf на данной системе.',
					);
					this.needInstall = false;
					return;
				}
				let acrobatReaderInfo = this.serializerAcrobatReaderInfo.parse(await response.text());
				this.pdfFileProgId = acrobatReaderInfo?.GetActual()?.[0].pdfFileProgId;
				this.pdfState = PdfStates.SetAssociation;
				break;
			case 204:
				this.pdfState = PdfStates.InstallAdobe;
				break;
			default:
				this.pdfState = PdfStates.None;
				break;
		}

		logger.info(`Результат проверки pdf [${this.pdfState}]:[${this.needInstall}]`);
	}

	private async setAssociation() {
		if (this.pdfFileProgId == null || !this.association?.canChanged) {
			this.needInstall = false;
			logger.warn(
				`Метод установки ассоциации для pdf по адресу ${process.env
					.REACT_APP_AGENT_EP_PDF_ASSOCIATION!} не будет вызван, так отсутствуют необходимые для этого значения или установлен флаг, что ассоциация не может быть изменена!`,
			);
			return;
		}

		let response = await putToAgentHttps(
			process.env.REACT_APP_AGENT_EP_PDF_ASSOCIATION!,
			JSON.stringify({ softwareProgId: this.pdfFileProgId }),
		);
		if (response.ok || response.status === 501) {
			this.needInstall = false;
			return;
		}
	}

	private async getAssociation(): Promise<Association> {
		let association = await getModelFromAgentHttps(Association, process.env.REACT_APP_AGENT_EP_PDF_ASSOCIATION!);
		return association;
	}

	private getProductToInstallByOs(): string {
		if (commonInfo.getSystemType() !== SystemTypes.Linux) return 'Adobe Reader';
		return 'XpdfReader';
	}
}
