import { Loader } from '@components/loader';
import { Page, PageImage } from '@components/page-template';
import { store } from '@features/setup-wizard/stores/store';
import { useEffect } from 'react';
import styles from './preliminary-checks.module.scss';
import { preparePage } from '@businessLogic/page-preparations';
import { PagePreparationStatus } from '@enums/page-preparation-status';
import logger from '@logger';
import { RestartPCType } from '@enums/restart-pc-type';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { parseEnumValue } from '@helpers/enum-helper';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { getQueryBoolean } from '@helpers/url-search-params-helper';
import { PageType } from '@features/setup-wizard/enums/page-type';
import {
	checkUp,
	installedCp5Demo,
	noConnection,
	noConnectionTerminal,
	nonActualVersion,
	notFound,
	notSupportedOs,
	setupSucceeded,
	updateCpMinorVersionProcess,
	updateCpMinorVersionStart,
} from '@features/setup-wizard/models/pages';

export const PreliminaryChecks = () => {
	useEffect(() => {
		preparePage().then(value => {
			switch (value) {
				case PagePreparationStatus.NoConnection:
					store.setPage(noConnection());
					break;
				case PagePreparationStatus.NoConnectionTerminal:
					store.setPage(noConnectionTerminal());
					break;
				case PagePreparationStatus.NonActualVersion:
					store.setPage(nonActualVersion());
					break;
				case PagePreparationStatus.NotFound:
					store.setPage(notFound());
					break;
				case PagePreparationStatus.NotSupportedOs:
					store.setPage(notSupportedOs());
					break;
				case PagePreparationStatus.Ok:
					onPagePreparationOk();
					break;
				default:
					logger.warn(
						`После предварительной подготовки страницы вернулся необрабатываемый статус: [${value}]!`,
					);
					break;
			}
		});
	}, []);

	function onPagePreparationOk() {
		try {
			parseQuery();
			store.setPage(getPageToDisplay());
		} catch (error) {
			logger.error(`Возникла ошибка при парсинге query строки: ${error}`);
		}
	}

	// TODO SMA все эт вынести возможно в отдельное место
	function parseQuery() {
		const urlParams = new URLSearchParams(window.location.search);
		parseCryptoInfo(urlParams);
		parseCpLicenseInfo(urlParams);
	}

	function parseCryptoInfo(urlParams: URLSearchParams) {
		const cryptoArmInstalled = getQueryBoolean(
			urlParams,
			process.env.REACT_APP_PARAMETER_NAME_CRYPTO_ARM_INSTALLED!,
		);
		if (cryptoArmInstalled !== null) {
			cpStore.setCryptoArmInstalled(cryptoArmInstalled);
		}
		logger.debug(
			`Флаг ${process.env
				.REACT_APP_PARAMETER_NAME_CRYPTO_ARM_INSTALLED!}, полученный из query, имеет значение: ${cryptoArmInstalled}`,
		);

		const restartPcType = parseEnumValue(
			RestartPCType,
			urlParams.get(process.env.REACT_APP_PARAMETER_NAME_RESTART_PC_TYPE!),
		);
		logger.debug(
			`Тип перезагрузки, полученный из query по ключу ${process.env
				.REACT_APP_PARAMETER_NAME_RESTART_PC_TYPE!}, имеет значение: ${restartPcType}`,
		);
		cpStore.setRestartStatus(restartPcType);

		const setupMode = parseEnumValue(
			CpSetupMode,
			urlParams.get(process.env.REACT_APP_PARAMETER_NAME_CP_SETUP_MODE!),
		);
		logger.debug(
			`Тип установки КриптоПро, полученный из query по ключу ${process.env
				.REACT_APP_PARAMETER_NAME_CP_SETUP_MODE!}, имеет значение: ${setupMode}`,
		);
		if (setupMode) {
			cpStore.setSetupMode(setupMode);
			if (setupMode === CpSetupMode.UpdateMinorVersion && restartPcType === RestartPCType.CspInstall) {
				cpLicenseStore.setNotificationStatus(true);
			}
		}
	}

	function parseCpLicenseInfo(urlParams: URLSearchParams) {
		const scheduledEnterCpKey = getQueryBoolean(
			urlParams,
			process.env.REACT_APP_PARAMETER_NAME_SCHEDULED_ENTER_CP_KEY!,
		);
		logger.debug(
			`Флаг ${process.env
				.REACT_APP_PARAMETER_NAME_SCHEDULED_ENTER_CP_KEY!}, полученный из query, имеет значение: ${scheduledEnterCpKey}`,
		);

		if (scheduledEnterCpKey) {
			cpLicenseStore.scheduleEnterCpKey(scheduledEnterCpKey);
		}

		const cpKey = urlParams.get(process.env.REACT_APP_PARAMETER_NAME_CP_KEY!);
		logger.debug(
			`Флаг ${process.env
				.REACT_APP_PARAMETER_NAME_CP_KEY!}, полученный из query, имеет значение: ${scheduledEnterCpKey}`,
		);
		if (cpKey) {
			cpLicenseStore.setLicenseKey(cpKey);
			cpLicenseStore.setNotificationStatus(true); // TODO SMA вот эта логика как будто должна делаться по другому
		}
	}

	function getPageToDisplay(): PageType {
		if (cpStore.restartStatus === null) {
			return checkUp();
		}
		// TODO SMA тут возможно стоит учесть случаи, когда этот ключ не должен обрабатываться
		// например, при CpSetupMode.UpdateMajorVersion не может быть scheduledEnterCspKey
		if (cpLicenseStore.scheduledEnterCpKey) {
			return installedCp5Demo();
		}
		if (
			cpStore.restartStatus === RestartPCType.CspInstall ||
			(cpStore.restartStatus === RestartPCType.CspUpdate &&
				(cpStore.setupMode === CpSetupMode.Install || cpStore.setupMode === CpSetupMode.UpdateMajorVersion))
		) {
			return setupSucceeded();
		}

		if (cpStore.setupMode === CpSetupMode.UpdateMinorVersion) {
			if (cpStore.restartStatus === null) {
				return updateCpMinorVersionStart();
			}
			return updateCpMinorVersionProcess();
		}
		return checkUp();
	}

	return (
		<Page
			image={PageImage.Load}
			child={
				<>
					<div id={styles.content}>
						<div id={styles.title}>Установка соединения</div>
						<Loader />
					</div>
				</>
			}
			showMobileView={true}
		/>
	);
};
