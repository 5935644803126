import styles from './notification.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, ToastOptions, Id } from 'react-toastify';
import { ReactComponent as IconSuccess } from '@assets/notification/success.svg';
import { ReactComponent as IconCircleCrossedOut } from '@assets/notification/circle-crossed-out.svg';
import { ReactComponent as IconKey } from '@assets/notification/key.svg';
import { ReactComponent as IconAttention } from '@assets/notification/attention.svg';
import { CSSProperties, ReactElement, ReactNode, useRef } from 'react';
import { useOutsideClick } from '@helpers/outside-click';
import { NotificationBodyWithTitle } from './notification-body-with-title';

const defaultNotificationWidth = 284;

export enum RedNotificationIcon {
	Key,
	CrossedOutCircle,
	Attention,
}

const CloseButton = ({ closeToast }: any) => (
	<button
		className={styles.closeButton}
		onClick={closeToast}
	>
		✖
	</button>
);

export interface NotificationProps {
	className?: string;
}

export function notifyGreen(content: string | ReactElement, width: number = defaultNotificationWidth) {
	toastWrapped(
		<div
			className={styles.body}
			style={{ width }}
		>
			<IconSuccess className={styles.icon} />
			{content}
		</div>,
		{
			className: styles.toastWrapperSuccess,
		},
	);
}

export function notifyGreenWithTitle(title: string, description: string | ReactElement, width?: number) {
	notifyGreen(
		<NotificationBodyWithTitle
			title={title}
			description={description}
		/>,
		width,
	);
}

export function notifyRed(content: string | ReactElement, icon?: RedNotificationIcon, width?: number) {
	let commonClassName = styles.icon;

	switch (icon) {
		case RedNotificationIcon.Key:
			notifyRedLogic(content, <IconKey className={commonClassName} />, width);
			break;
		case RedNotificationIcon.Attention:
			notifyRedLogic(content, <IconAttention className={commonClassName} />, width);
			break;
		case RedNotificationIcon.CrossedOutCircle:
		default:
			notifyRedLogic(content, <IconCircleCrossedOut className={commonClassName} />, width);
			break;
	}
}

export function notifyRedWithTitle(
	title: string,
	description: string | ReactElement,
	icon?: RedNotificationIcon,
	style?: CSSProperties,
	width?: number,
) {
	notifyRed(
		<NotificationBodyWithTitle
			title={title}
			description={description}
			style={style}
		/>,
		icon,
		width,
	);
}

function notifyRedLogic(content: string | ReactElement, icon: ReactElement, width: number = defaultNotificationWidth) {
	toastWrapped(
		<div
			className={styles.body}
			style={{ width }}
		>
			{icon}
			{content}
		</div>,
		{
			className: styles.toastWrapperError,
		},
	);
}

function toastWrapped(content: ReactElement, options?: ToastOptions<{}> | undefined): Id {
	return toast(<NotificationWrapper>{content}</NotificationWrapper>, options);
}

function NotificationWrapper({ children }: { children?: ReactNode }) {
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, () => toast.dismiss());

	return <div ref={wrapperRef}>{children}</div>;
}

export function NotificationContainer(props: NotificationProps) {
	return (
		<ToastContainer
			limit={2}
			position="bottom-right"
			newestOnTop
			hideProgressBar
			autoClose={5000}
			closeOnClick={false}
			draggable={false}
			closeButton={CloseButton}
			className={[styles.toastContainer, props.className].join(' ')}
			toastClassName={styles.toastWrapper}
			bodyClassName={styles.toastBody}
		/>
	);
}
