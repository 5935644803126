import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { getModelFromBackend, getModelFromAgentHttps, putModelToAgentHttps } from '@helpers/fetch-helper';
import logger from '@logger';
import { Stage } from '@features/setup-wizard/models/stages';
import {
	StageInfoWithTextContent,
	StageInfo,
	StageInfoWithLinkContent,
} from '@features/setup-wizard/models/stages/infos';
import { ProgramComponentsInfo } from '@features/setup-wizard/models/api/program-components-info';

export class ProgramComponentsStage extends Stage {
	private programComponentsInfo?: ProgramComponentsInfo;

	public constructor() {
		super(StageTypes.ProgramComponents);
	}

	protected async checkUpLogic() {
		let programComponentsBackend = await getModelFromBackend(
			ProgramComponentsInfo,
			process.env.REACT_APP_EP_ACTIVEX!,
		);
		logger.info(`Получена информация о программных компонентах с бэкенда.`);

		let result = new ProgramComponentsInfo();

		let binaryCodeTa = (
			await getModelFromAgentHttps(ProgramComponentsInfo, process.env.REACT_APP_AGENT_EP_ACTIVEX_BINARY_CODE!)
		).binaryCode;
		if (binaryCodeTa !== programComponentsBackend.binaryCode)
			result.binaryCode = programComponentsBackend.binaryCode;

		let runElementsTa = (
			await getModelFromAgentHttps(ProgramComponentsInfo, process.env.REACT_APP_AGENT_EP_ACTIVEX_RUN_ELEMENTS!)
		).runElements;
		if (runElementsTa !== programComponentsBackend.runElements)
			result.runElements = programComponentsBackend.runElements;

		logger.info(`Получена информация о программных компонентах с агента.`);

		if (result.binaryCode == null && result.runElements == null) {
			logger.info(`настройка программных компонентов не требуется.`);
			this.needInstall = false;
			return;
		} else {
			logger.info(`Требуется настройка программных компонентов.`);
		}

		this.programComponentsInfo = result;
	}

	protected async setupLogic() {
		if (this.programComponentsInfo == null) {
			logger.warn(`Нет информации о настраиваемых компонентах. Настройка не удалась!`);
			return;
		}

		let succeeded = true;
		if (this.programComponentsInfo.binaryCode != null) {
			let response = await putModelToAgentHttps(
				process.env.REACT_APP_AGENT_EP_ACTIVEX_BINARY_CODE!,
				ProgramComponentsInfo,
				this.programComponentsInfo,
			);
			if (!response.ok) {
				logger.warn(`Не удалось настроить ACTIVEX BINARY CODE!`);
				succeeded = false;
			}
		}
		if (this.programComponentsInfo.runElements != null) {
			let response = await putModelToAgentHttps(
				process.env.REACT_APP_AGENT_EP_ACTIVEX_RUN_ELEMENTS!,
				ProgramComponentsInfo,
				this.programComponentsInfo,
			);
			if (!response.ok) {
				logger.warn(`Не удалось настроить ACTIVEX RUN ELEMENTS!`);
				succeeded = false;
			}
		}
		this.needInstall = !succeeded;
	}

	public getSetupSettingsStageInfo(): StageInfoWithTextContent {
		return new StageInfoWithTextContent(
			'Настроить компоненты программ',
			'Включить в настройках элементы ActiveX и модули подключения, чтобы программы Такскома работали правильно',
		);
	}

	public getCheckUpStageInfo(): StageInfo {
		return new StageInfo('Компоненты программ');
	}

	public getInstallStageInfo(): StageInfo {
		return new StageInfo('Настраиваем компоненты программ');
	}

	public getInstallErrorStageInfo(): StageInfoWithLinkContent {
		return new StageInfoWithLinkContent('Настройте компоненты программ', {
			textBeforeLink: 'Включите в настройках элементы ActiveX и модули подключения',
		});
	}
}
