import styles from './switcher.module.scss';
import { useState } from 'react';
import { SystemTypes } from '@enums/system-types';

export interface SwitcherProps {
	showMobileView?: boolean;
	callback: (systemType: SystemTypes) => void;
	systemType: SystemTypes;
}

export function Switcher({ showMobileView = false, ...props }: SwitcherProps) {
	const [systemType, setSystemType] = useState(props.systemType);

	function setPositionWithCallback(systemType: SystemTypes): void {
		props.callback(systemType);
		setSystemType(systemType);
	}

	let main = showMobileView ? [styles.panel, styles.mobile].join(' ') : styles.panel;

	return (
		<div className={main}>
			<div className={styles.header}>
				<button
					className={[
						// В случае, если другие системы, свитчер ставится по умолчанию в режим Windows
						systemType === SystemTypes.Linux ? styles.buttonUnselected : styles.buttonSelected,
						styles.marginRight8,
					].join(' ')}
					onClick={() => setPositionWithCallback(SystemTypes.Windows)}
				>
					Windows
				</button>
				<button
					className={systemType === SystemTypes.Linux ? styles.buttonSelected : styles.buttonUnselected}
					onClick={() => setPositionWithCallback(SystemTypes.Linux)}
				>
					Linux
				</button>
			</div>
		</div>
	);
}
