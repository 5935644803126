import { getModelFromAgentHttps } from '@helpers/fetch-helper';
import logger from '@logger';
import { SystemInfo } from '@models/api';
import { SystemTypes } from '@enums/system-types';
import { ProtocolTypes } from '@enums/protocol-types';
import { isNullOrEmpty } from '@helpers/string-helper';
import { SourceData } from '@models/source-data';
import { getLowerCaseURLSearchParams } from '@helpers/url-search-params-helper';

const BaseHost = '127.0.0.1';

class CommonInfo {
	public terminalNeedUpdate = false;

	public agentHttps = `https://${BaseHost}:4923`;
	public agentHttp = `http://${BaseHost}:5023`;
	public agentWss = `wss://${BaseHost}:4823`;
	public readonly backendUrl: string;
	public readonly masterWss = `wss://${BaseHost}:4920`;
	public readonly masterWs = `ws://${BaseHost}:4919`;
	public readonly masterHttps = `https://${BaseHost}:4920`;
	public readonly masterHttp = `http://${BaseHost}:4919`;
	public readonly sourceInfo = new SourceData();

	private systemInfo?: SystemInfo;

	public constructor() {
		this.setDefaultInfo();

		logger.info(process.env.REACT_APP_API_ADDRESS_PRIORITY);
		if (isNullOrEmpty(process.env.REACT_APP_API_ADDRESS_PRIORITY)) {
			this.backendUrl = process.env.REACT_APP_API_ADDRESS!;
		} else {
			this.backendUrl = process.env.REACT_APP_API_ADDRESS_PRIORITY!;
		}
	}

	public setDefaultInfo() {
		this.initSourceInfo();
		this.systemInfo = undefined;

		this.agentHttps = `https://${BaseHost}:4923`;
		this.agentHttp = `http://${BaseHost}:5023`;
		this.agentWss = `wss://${BaseHost}:4823`;
	}

	public getMainPageUrl(): string {
		if (this.sourceInfo.installerId) {
			return `/?${process.env.REACT_APP_PARAMETER_NAME_INSTALLER_ID!}=${this.sourceInfo.installerId ?? ''}`;
		}
		if (this.sourceInfo.utmAppId) {
			return `/?${process.env.REACT_APP_PARAMETER_NAME_UTM_APP_ID!}=${this.sourceInfo.utmAppId ?? ''}`;
		}
		return '/';
	}

	public async getSystemInfo(): Promise<SystemInfo> {
		if (this.systemInfo != null) return this.systemInfo;
		this.systemInfo = await getModelFromAgentHttps(SystemInfo, process.env.REACT_APP_AGENT_EP_SYSTEM_VERSION!);

		return this.systemInfo;
	}

	public getSystemType(): SystemTypes {
		if (this.systemInfo != null) return this.systemInfo.type;

		let userAgent = window.navigator.userAgent.toLowerCase();
		if (userAgent.includes('windows'.toLowerCase())) return SystemTypes.Windows;
		if (userAgent.includes('linux x86_64'.toLowerCase())) return SystemTypes.Linux;
		return SystemTypes.Others;
	}

	public setPort(protocolType: ProtocolTypes, port: number): boolean {
		if (port == null) {
			logger.warn(`Переданный порт для [${protocolType}] не определен!`);
			return false;
		}

		if (port === 0) {
			logger.warn(`Переданный порт для [${protocolType}] равен 0!`);
			return false;
		}

		switch (protocolType) {
			case ProtocolTypes.Http:
				this.agentHttp = `http://${BaseHost}:${port}`;
				break;
			case ProtocolTypes.Https:
				this.agentHttps = `https://${BaseHost}:${port}`;
				break;
			case ProtocolTypes.Wss:
				this.agentWss = `wss://${BaseHost}:${port}`;
				break;
		}
		return true;
	}

	private initSourceInfo() {
		const urlParams = getLowerCaseURLSearchParams();
		this.sourceInfo.installerId =
			urlParams.get(process.env.REACT_APP_PARAMETER_NAME_INSTALLER_ID!.toLowerCase()) ?? undefined;
		this.sourceInfo.utmAppId =
			urlParams.get(process.env.REACT_APP_PARAMETER_NAME_UTM_APP_ID!.toLowerCase()) ?? undefined;
	}
}

export const commonInfo = new CommonInfo();
