import { CertListSkeleton } from '@features/cert-list/components/cert-list-skeleton';
import { Dispatch, SetStateAction } from 'react';
import { CertListContent } from './components/cert-list-content';
import { CertsNotFound } from './components/certs-not-found';
import { SelectProps } from './components/interfaces/select-props';
import useCerts from './hooks/use-certs';
import { State } from './models/states';

export interface Props extends SelectProps {
	onError: (error: any) => void;
	state: State;
	setState: Dispatch<SetStateAction<State>>;
}

export const CertList = (props: Props): JSX.Element => {
	const { state, setState, onError } = props;
	const load = useCerts(state, setState, onError);

	switch (state.kind) {
		case 'ShowCerts':
			return (
				<CertListContent
					info={state}
					load={searchValue => load(searchValue)}
					{...props}
				/>
			);
		case 'NoCerts':
			return (
				<CertsNotFound
					info={state}
					loadCerts={() => load()}
				/>
			);
		case 'Loading':
			return <CertListSkeleton />;
	}
};
