import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CryptographyUpdateInfo {
	@jsonMember(() => String)
	public type!: string;

	@jsonMember(() => String)
	public setupURL!: string;

	@jsonMember(() => Boolean)
	public silentInstall!: boolean;

	@jsonMember(() => String)
	public licenseKey!: string;
}
