export const WindowsTitles: string[] = [
	'Windows 7 SP1',
	'Windows 8.1',
	'Windows 10 версия 1607 (14393.3808) и выше',
	'Windows 11',
	'Windows Server 2012 R2 и выше',
];

export const LinuxTitles: string[] = [
	'Red Os MUROM 7.3.1 и выше',
	'Astra Linux Orel 2.12.43 и выше',
	'Alt Workstation 10.0 и выше',
	'Ubuntu 20.04.4 LTS и выше',
	'Rosa Fresh Desktop 12.2 и выше',
	'Rosa Linux 12.2 LXQt и выше',
];
