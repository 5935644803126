import { restartPC } from '@businessLogic/api/restart-pc';
import { commonInfo } from '@businessLogic/common-info';
import { RestartPCResult } from '@enums/restart-pc-result';
import { RestartPCType } from '@enums/restart-pc-type';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { executeDeleteOldCpStage } from './deletion';
import { executeInstallNewCpStage } from './installation';
import { UpdateCpMinorVersionStage } from '../enums/update-cp-minor-version-stage';
import { executeCleaner } from './cleaning';

export async function updateCpMinorVersion(): Promise<void> {
	const currentStage = cpStore.currentUpdateCpMinorVersionStage;
	switch (currentStage?.kind) {
		case UpdateCpMinorVersionStage.Deletion:
			await executeDeleteOldCpStage();
			break;
		case UpdateCpMinorVersionStage.Cleaning:
			await executeCleaner();
			break;
		case UpdateCpMinorVersionStage.Installation:
			await executeInstallNewCpStage();
			break;
		default:
			throw new Error(`Получено необрабатываемое значение текущего stage: ${currentStage}`);
	}
}

// TODO SMA подумать над переносом в другое место
export async function rebootPc(): Promise<boolean> {
	const params = new Map<string, string>([
		[process.env.REACT_APP_PARAMETER_NAME_CP_SETUP_MODE!, cpStore.setupMode],
		[process.env.REACT_APP_PARAMETER_NAME_CRYPTO_ARM_INSTALLED!, String(cpStore.cryptoArmInstalled)],
		[process.env.REACT_APP_PARAMETER_NAME_SCHEDULED_ENTER_CP_KEY!, String(cpLicenseStore.scheduledEnterCpKey)],
	]);
	if (commonInfo.sourceInfo.installerId)
		params.set(process.env.REACT_APP_PARAMETER_NAME_INSTALLER_ID!, commonInfo.sourceInfo.installerId);
	if (commonInfo.sourceInfo.utmAppId)
		params.set(process.env.REACT_APP_PARAMETER_NAME_UTM_APP_ID!, commonInfo.sourceInfo.utmAppId);

	const result = await restartPC(getRestartPcType(), params);
	return result === RestartPCResult.Success;
}

function getRestartPcType() {
	const currentStage = cpStore.currentUpdateCpMinorVersionStage;
	switch (currentStage?.kind) {
		case UpdateCpMinorVersionStage.Cleaning:
			return RestartPCType.Cleaner;
		case UpdateCpMinorVersionStage.Deletion:
			return RestartPCType.CspUninstall;
		case UpdateCpMinorVersionStage.Installation:
			return RestartPCType.CspInstall;
		default:
			throw new Error(`Передано неподдерживаемое значение: ${currentStage}`);
	}
}
