import { makeAutoObservable, action, runInAction } from 'mobx';
import { ProductInfo } from '../models/product-info';
import {
	getModelsArrayFromAgentHttps,
	getModelsArrayFromBackend,
	postModelsArrayToAgentHttps,
} from '@helpers/fetch-helper';
import { fetchRedirectInfo } from '../business-logic/api';
import { CreateShortcutState } from '../enums/create-shortcut-state';
import { ShortcutExtended } from '../models/api/shortcut-extended';
import { notifyGreen } from '@components/notification';
import logger from '@logger';
import { ModalState } from '../enums/modal-state';
import { RedirectInfo } from '@models/redirect-info';
import { ShortcutRecord } from '../models/api/shortcut-record';
import { Shortcut } from '../models/api/shortcut';

export class ShortcutCreatorStore {
	products: ProductInfo[];
	createShortCutMode: boolean;
	redirectInfo?: RedirectInfo;
	public modalState: ModalState;

	public constructor() {
		makeAutoObservable(this, { initAll: action, initProducts: action }, { autoBind: true });
		this.modalState = ModalState.None;
		this.createShortCutMode = false;
		this.products = [];
	}

	async initAll() {
		this.setRedirectInfo(await fetchRedirectInfo());
		await this.initProducts();
	}

	setRedirectInfo(info: RedirectInfo) {
		this.redirectInfo = info;
		logger.info(this.redirectInfo);
	}

	async initProducts() {
		let shortcutRecords = await getModelsArrayFromBackend(ShortcutRecord, process.env.REACT_APP_EP_SHORTCUTRECORD!);
		this.setProducts(shortcutRecords.map(e => new ProductInfo(e)));
		await this.updateProductsAvailabilityToShortcut();
	}

	setProducts(products: ProductInfo[]) {
		this.products = products;
	}

	async updateProductsAvailabilityToShortcut() {
		try {
			let shortcuts = await getModelsArrayFromAgentHttps(
				Shortcut,
				process.env.REACT_APP_AGENT_EP_SYSTEM_SHORTCUTS!,
			);

			runInAction(() => {
				this.products = this.products.map(product => {
					if (shortcuts.some(q => q.targetUrl.trim() === product.targetUrl.trim())) {
						return {
							...product,
							createShortcutState: CreateShortcutState.NotAvailable,
						};
					}
					return {
						...product,
						createShortcutState: CreateShortcutState.NotNeeded,
					};
				});
			});
		} catch (e) {
			logger.error(e);
		}
	}

	async createShortcuts() {
		try {
			let shortcuts: ShortcutExtended[] = this.products
				.filter(q => q.createShortcutState === CreateShortcutState.Needed)
				.map(
					q =>
						new ShortcutExtended({
							iconPath: q.iconPath,
							name: q.name,
							targetUrl: q.targetUrl,
						}),
				);

			let response = await postModelsArrayToAgentHttps(
				process.env.REACT_APP_AGENT_EP_SYSTEM_SHORTCUTS!,
				ShortcutExtended,
				shortcuts,
			);

			if (!response.ok) {
				throw new Error(
					`Результат запроса на создания ярлыков отличен от успешного. Код ответа: [${
						response.status
					}] Тело: [${await response.text()}]`,
				);
			}

			runInAction(() => {
				this.products = this.products.map(product =>
					product.createShortcutState === CreateShortcutState.Needed
						? {
								...product,
								createShortcutState: CreateShortcutState.NotAvailable,
							}
						: product,
				);
			});
			notifyGreen('Ярлыки установлены');
		} catch (e) {
			logger.error(e, 'Возникла ошибка при попытке создания ярлыков!');
			this.setModalState(ModalState.CreateShortcutsFailure);
		} finally {
			this.disableCreateShortCutMode();
		}
	}

	toggleProductCreateShortcutState(targetUrl: string) {
		this.products = this.products.map(product => {
			if (product.targetUrl !== targetUrl) {
				return product;
			}
			switch (product.createShortcutState) {
				case CreateShortcutState.Needed:
					return {
						...product,
						createShortcutState: CreateShortcutState.NotNeeded,
					};
				case CreateShortcutState.NotNeeded:
					return {
						...product,
						createShortcutState: CreateShortcutState.Needed,
					};
			}
			return product;
		});
	}

	enableCreateShortCutMode() {
		this.products = this.products.map(product => {
			if (product.createShortcutState === CreateShortcutState.NotAvailable) {
				return product;
			}
			if (product.productID === this.redirectInfo?.productId) {
				return {
					...product,
					createShortcutState: CreateShortcutState.Needed,
				};
			}
			return {
				...product,
				createShortcutState: CreateShortcutState.NotNeeded,
			};
		});
		this.createShortCutMode = true;
	}

	disableCreateShortCutMode() {
		this.createShortCutMode = false;
	}

	setModalState(modalState: ModalState) {
		this.modalState = modalState;
	}

	get anyAvailableToCreateShortcut(): boolean {
		return this.products.some(q => q.createShortcutState !== CreateShortcutState.NotAvailable);
	}

	get neededToCreateShortcutCount(): number {
		return this.products.filter(q => q.createShortcutState === CreateShortcutState.Needed).length;
	}

	get anyNeedToCreateShortcut(): boolean {
		return this.neededToCreateShortcutCount > 0;
	}
}

export const shortcutCreatorStore = new ShortcutCreatorStore();
