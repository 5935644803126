import { ServerResponseError } from '@errors/api/server-response-error';
import { DeserializeResponseJsonError } from '@errors/api/deserialize-response-json-error';

export async function getServerRequestError(response: Response) {
	return new ServerResponseError(
		`Ошибка во время обращения к серверу. URL: ${response.url} Статус: ${
			response.status
		} Тело: ${await response.text()})`,
		response,
	);
}

export function getFailedToDeserializeResponse<T>(response: Response, typeName: string) {
	return new DeserializeResponseJsonError(
		`При попытке десериализовать результат после запроса на адрес ${response.url} не получили объект!`,
		response,
		typeName,
	);
}
