import { Stage } from '@features/setup-wizard/models/stages';
import logger from '@logger';
import {
	StageInfo,
	StageInfoWithLinkContent,
	StageInfoWithTextContent,
} from '@features/setup-wizard/models/stages/infos';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { SystemTypes } from '@enums/system-types';
import { installCryptoProvider } from '@features/setup-wizard/business-logic/api/crypto/crypto';
import { getFromAgentHttps } from '@helpers/fetch-helper';
import { CpLicenseEnterType } from '@features/setup-wizard/enums/cp-license-enter-type';
import { CryptoInstallResult } from '@features/setup-wizard/enums/crypto-install-result';
import { cryptoCheck } from '@features/setup-wizard/business-logic/api/crypto/crypto-check';
import { commonInfo } from '@businessLogic/common-info';
import { CryptoCheckResult } from '@features/setup-wizard/enums/crypto-check-result';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';

export class CryptographyStage extends Stage {
	public cryptoCheckResult: CryptoCheckResult = CryptoCheckResult.NotFound;
	private isLicenseSetupFailed: boolean = false;

	public constructor() {
		super(StageTypes.Cryptography, false);
	}

	public get enterLicenseWindowType() {
		if (this.cryptoCheckResult === CryptoCheckResult.LicenseExpired) {
			return CpLicenseEnterType.NoDemo;
		}
		if (this.cryptoCheckResult === CryptoCheckResult.UpdatePerpetualLicense) {
			return CpLicenseEnterType.NewLicenseInfoInMail;
		}
		return CpLicenseEnterType.Default;
	}

	protected async checkUpLogic() {
		let response = await getFromAgentHttps(process.env.REACT_APP_AGENT_EP_CRYPTO_VERSION!);
		let systemInfo = await commonInfo.getSystemInfo();
		if (response.status === 200) {
			if (systemInfo.type !== SystemTypes.Windows) {
				this.updateNotRequired();
				return;
			}
			logger.info('Проверяем на КП 5');
			const checkCpResult = await cryptoCheck();
			logger.info(`Результат проверки КП: ${CryptoCheckResult[checkCpResult]}`);

			if (checkCpResult === CryptoCheckResult.UpdateNotRequired || checkCpResult === CryptoCheckResult.NotFound) {
				//Почему завязка на notFound - считаем что при 200 от основного метода проверки какой-то крипто провайдер есть.
				//Если при этом статус проверки 404 при получении детальной инфы о крипто про - значит есть випнет.
				this.updateNotRequired();
				return;
			}

			if (
				checkCpResult === CryptoCheckResult.UpdateMinorVersion ||
				checkCpResult === CryptoCheckResult.UpdateMinorVersionWithNewLicense
			) {
				logger.info('Считаем, что нужно обновить КП 5 до более поздней версии!');
				cpStore.setSetupMode(CpSetupMode.UpdateMinorVersion);
			} else {
				logger.info('Считаем, что нужно обновить КП до 5');
				cpStore.setSetupMode(CpSetupMode.UpdateMajorVersion);
			}
			this.cryptoCheckResult = checkCpResult;
		}
		this.needInstall = true;
	}

	protected async setupLogic() {
		if (commonInfo.getSystemType() !== SystemTypes.Linux) {
			logger.info(`Установка не на Linux'е будет произведена на отдельной странице!`);
			return;
		}
		let installCpResult = await installCryptoProvider();
		if (installCpResult !== CryptoInstallResult.Success) {
			cpLicenseStore.setNotificationStatus(false);
		}
		switch (installCpResult) {
			case CryptoInstallResult.Success:
			case CryptoInstallResult.CryptoAlreadyInstall:
				this.needInstall = false;
				break;
			case CryptoInstallResult.NotNormalLicenseInstallDemo:
				this.isLicenseSetupFailed = true;
				break;
			default:
				logger.error(
					`Пришел необрабатываемый статус на этапе установки: ${CryptoInstallResult[installCpResult]}!`,
				);
		}
	}

	public getSetupSettingsStageInfo(): StageInfoWithTextContent {
		return new StageInfoWithTextContent(
			cpStore.setupMode === CpSetupMode.UpdateMajorVersion
				? `Обновить КриптоПро CSP до версии 5`
				: cpStore.setupMode === CpSetupMode.UpdateMinorVersion
					? 'Обновить КриптоПро CSP'
					: `Установить КриптоПро CSP 5`,
			'Чтобы подписывать документы электронной подписью и работать с сертификатами. Будет установлена последняя сертифицированная версия',
		);
	}

	public getCheckUpStageInfo(): StageInfo {
		return new StageInfo('Криптографию');
	}

	public getInstallStageInfo(): StageInfo {
		return new StageInfo('Устанавливаем криптографию');
	}

	public getInstallErrorStageInfo(): StageInfoWithLinkContent {
		if (!this.isLicenseSetupFailed) {
			return new StageInfoWithLinkContent('Установите КриптоПро CSP', {
				link: process.env.REACT_APP_INSTR_FAILED_CRYPTO_PROVIDER!,
				textOfLink: 'в\xa0инструкции',
				textBeforeLink: 'Где скачать программу и как установить её на компьютер можете найти ',
			});
		}
		return new StageInfoWithLinkContent('Проверьте серийный номер КриптоПро CSP', {
			link: process.env.REACT_APP_INSTR_FAILED_CRYPTO_LICENSE!,
			textOfLink: 'по\xa0инструкции',
			textBeforeLink:
				'Вы указали неверный номер, поэтому мы активировали пробную версию на 90 дней. Можете указать другой номер ',
		});
	}

	private updateNotRequired() {
		logger.info('Криптопровайдер в наличии. Настройка не требуется.');
		this.needInstall = false;
		return;
	}
}
