import styles from './button-with-spinner.module.scss';
import { isNullOrEmpty } from '@helpers/string-helper';
import { MouseEventHandler } from 'react';
import { ReactComponent as Spinner } from '@assets/icons/spinners/spinner-thick.svg';

export enum ButtonWithSpinnerStyle {
	Primary,
	Secondary,
}

interface Props {
	defaultText: string;
	loadingText?: string;
	loading: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	style?: ButtonWithSpinnerStyle;
	disabled?: boolean;
}

export function ButtonWithSpinner(props: Props) {
	function onBtnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		if (props.loading) {
			return;
		}
		props.onClick?.(e);
	}

	function getStyle(): string {
		switch (props.style) {
			case ButtonWithSpinnerStyle.Secondary:
				return styles.secondary;
			default:
				return styles.primary;
		}
	}

	const style = getStyle();

	return (
		<button
			className={[styles.button, props.loading && styles.loading, style].join(' ')}
			onClick={onBtnClick}
			disabled={props.disabled}
		>
			{props.loading ? (
				<div className={styles.btnContent}>
					<Spinner className={[styles.spinner, style].join(' ')} />
					<div>{isNullOrEmpty(props.loadingText) ? props.defaultText : props.loadingText}</div>
				</div>
			) : (
				props.defaultText
			)}
		</button>
	);
}
