import styles from './setup-settings.module.scss';
import { ReactComponent as Checkmark } from '@assets/icons/checkmark.svg';
import { DropDown } from '@components/drop-down';
import { Stage } from '@features/setup-wizard/models/stages';
import { Page, PageImage } from '@components/page-template';
import { store } from '@features/setup-wizard/stores/store';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { CryptographyStage } from '@stages/cryptography-stage';
import { CryptoCheckResult } from '@features/setup-wizard/enums/crypto-check-result';
import {
	cpInstallErrorWhenVipnet,
	cryptographyLicense,
	setup,
	setupSucceeded,
	updateCpMinorVersionStart,
} from '@features/setup-wizard/models/pages';
import { PageType } from '@features/setup-wizard/enums/page-type';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { getModelFromBackend } from '@helpers/fetch-helper';
import { CurrentDateTime } from '@models/api';
import logger from '@logger';

function getNextPage(): PageType {
	let needToInstallStage = stagesWorker.getNeededToInstall();
	if (needToInstallStage.length === 0) {
		return setupSucceeded();
	}
	let crypto = needToInstallStage.find(q => q.type === StageTypes.Cryptography);
	if (!crypto) {
		return setup();
	}
	let cryptoStage = crypto as CryptographyStage;
	if (
		cryptoStage.cryptoCheckResult === CryptoCheckResult.VipnetUpdatePerpetualLicense ||
		cryptoStage.cryptoCheckResult === CryptoCheckResult.VipnetUpdateAnnualLicense
	) {
		return cpInstallErrorWhenVipnet();
	}
	if (cryptoStage.cryptoCheckResult === CryptoCheckResult.UpdateMinorVersion) {
		if (needToInstallStage.length === 1) {
			return updateCpMinorVersionStart();
		}
		return setup();
	}
	return cryptographyLicense();
}

export function SetupSettings() {
	useEffect(() => {
		fetchPromptBlockStyle().catch(reason =>
			logger.error(`Ошибка при попытке получить корректный тип блока на основе даты ${reason}`),
		);
	}, []);
	const stages = useMemo(() => {
		let stages = stagesWorker.getNeededToInstall();
		if (stages.length === 0) store.setPage(setupSucceeded());
		return stages;
	}, []);

	const errorBlockDate = new Date(2024, 9, 1);
	const [promptBlockStyle, setPromptBlockStyle] = useState<PromptBlockType>(PromptBlockType.Warning);
	const [settingsVisibility, setSettingsVisibility] = useState<boolean>(false);

	const [chosenDropdown, chooseDropdown] = useState<[id: string, show: boolean]>(['', false]);

	function onChangeSetting(stage: Stage, event: React.ChangeEvent<HTMLInputElement>) {
		stage.needInstall = event.target.checked;
	}

	async function fetchPromptBlockStyle() {
		try {
			let currentDateTime = await getModelFromBackend(
				CurrentDateTime,
				process.env.REACT_APP_EP_CURRENT_DATETIME!,
			);

			if (currentDateTime.datetime >= errorBlockDate) {
				setPromptBlockStyle(PromptBlockType.ErrorTriangle);
			}
		} catch (e) {
			logger.error(`Ошибка при попытке получить текущую дату [${e}]`);
		}
	}

	function getDropdowns(): ReactElement[] {
		return stages.map(stage => {
			return (
				<div
					className={styles.listComponent}
					key={stage.id}
				>
					<label className={styles.checkboxContainer}>
						<input
							defaultChecked={stage.needInstall}
							type="checkbox"
							onChange={event => onChangeSetting(stage, event)}
						/>
						<div className={styles.checkmark}>
							<Checkmark />
						</div>
					</label>
					<DropDown
						title={stage.getSetupSettingsStageInfo().title}
						child={
							<div className={styles.setupSettingsDescription}>
								{stage.getSetupSettingsStageInfo().content}
							</div>
						}
						callback={(showContent: boolean) => chooseDropdown([stage.id, showContent])}
						showContent={stage.id === chosenDropdown[0] ? chosenDropdown[1] : false}
					/>
				</div>
			);
		});
	}

	return (
		<Page
			image={PageImage.Settings}
			child={
				<>
					<div id={styles.content}>
						<div id={styles.title}>Настройка компьютера</div>
						<div id={styles.subtitle}>Установим и настроим программы для работы с сервисами Такскома</div>
						<button
							onClick={() => setSettingsVisibility(!settingsVisibility)}
							className={styles.btn2}
						>
							Управление настройкой
						</button>
						{settingsVisibility ? getDropdowns() : <></>}
					</div>
					{cpStore.setupMode !== CpSetupMode.Install && (
						<div className={styles.cpPrompt}>
							<PromptBlock
								type={promptBlockStyle}
								text={
									'С ноября 2024 года для отправки и получения документов ФНС нужна версия КриптоПро 5.0.12000 и выше'
								}
							/>
						</div>
					)}
					<button
						className={styles.btn1}
						onClick={() => store.setPage(getNextPage())}
					>
						Настроить
					</button>
				</>
			}
			showMobileView={false}
		/>
	);
}
