import { CSSProperties, ReactElement } from 'react';
import styles from './notification-body-with-title.module.scss';

export interface Props {
	title: string;
	description: string | ReactElement;
	style?: CSSProperties;
}

export function NotificationBodyWithTitle(props: Props) {
	return (
		<div
			className={styles.body}
			style={props.style}
		>
			<div className={styles.title}>{props.title}</div>
			<div className={styles.description}>{props.description}</div>
		</div>
	);
}
