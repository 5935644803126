import { MouseEventHandler } from 'react';
import RefreshIcon from '@assets/icons/refresh.png';
import styles from './refresh-button.module.scss';

interface Props {
	onClick: MouseEventHandler<HTMLButtonElement>;
}

export function RefreshButton(props: Props) {
	return (
		<button
			onClick={props.onClick}
			className={styles.buttonTransparentBlue}
		>
			<img
				className={styles.buttonIcon}
				src={RefreshIcon}
			/>
			Обновить
		</button>
	);
}
