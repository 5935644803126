import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CurrentEnvironment {
	@jsonMember(() => String)
	public hostname!: string;

	@jsonMember(() => String)
	public username!: string;
}
