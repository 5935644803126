import styles from './wrong-install.module.scss';
import { v4 as getUuid } from 'uuid';
import { DropDownData } from '@components/drop-downs';
import { InstructionLink } from '@components/instruction-link';

export const WindowsElements: DropDownData[] = [
	[
		'Недостаточно места на диске',
		<div className={styles.dropDownContent}>
			Проверьте свободное место на системном диске — обычно это диск С. Для установки нужно 200 Мб.
		</div>,
		getUuid(),
	],
	[
		'Не установлено обновление безопасности',
		<div className={styles.dropDownContent}>
			<InstructionLink
				link={process.env.REACT_APP_INSTR_KB_DOWNLOAD!}
				text={'Скачайте обновление KB3063858'}
			/>
			 и установите с правами администратора. Перезагрузите компьютер и заново установите Такском-Агент.
		</div>,
		getUuid(),
	],
	[
		'Нет доступа к папке Roaming',
		<div className={styles.dropDownContent}>
			Проверьте есть ли у вас разрешения на доступ к этой папке — шаги ниже нужно выполнять под пользователем с
			правами администратора:
			<br />
			<div className={styles.additionalText}>
				1. Откройте Проводник или Мой компьютер.
				<br />
				2. В строке поиска введите %appdata% и нажмите Enter, должна открыться папка Roaming.
				<br />
				3. Там же в строке поиска нажмите на AppData. Дальше правой кнопкой мыши нажмите на папку Roaming и
				выберите Свойства.
				<br />
				4. Перейдите на вкладку Безопасность.
				<br />
				5. Нажмите Изменить и выберите пользователя, для которого нужно дать доступ к папке.
				<br />
				6. Поставьте все галочки в столбце Разрешить.
				<br />
				7. Нажмите ОК и перезагрузите компьютер.
				<br />
			</div>
			<br />
			Попробуйте ещё раз установить Такском-Агент.
			<br />
			Если ошибка появилась снова, пожалуйста, обратитесь в техподдержку.
		</div>,
		getUuid(),
	],
];

export const LinuxElements: DropDownData[] = [
	[
		'Недостаточно места на диске',
		<div className={styles.dropDownContent}>
			Проверьте свободное место на системном диске. Для установки нужно 200 Мб.
		</div>,
		getUuid(),
	],
	[
		'Не установлены необходимые библиотеки',
		<div className={styles.dropDownContent}>
			Установите библиотеки для вашей операционной системы
			<InstructionLink
				link={process.env.REACT_APP_INSTR_INSTALL_LIB_LINUX!}
				text={' по инструкции'}
			/>
		</div>,
		getUuid(),
	],
];
