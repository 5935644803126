import styles from './page-image.module.scss';
import spinner from '@assets/icons/spinners/spinner-thin.svg';
import { PageImage as PageImageEnum } from '../page-image-enum';

export function PageImage(props: { image: PageImageEnum }) {
	let isPathFull = false;
	let iconPath: string;
	let className: string | undefined;

	switch (props.image) {
		case PageImageEnum.Settings:
			return (
				<img
					className={styles.backgroundImage}
					src={require(`@assets/computer/settings.png`)}
				/>
			);
		case PageImageEnum.Attention:
			iconPath = 'attention.png';
			break;
		case PageImageEnum.CrossedOutCircle:
			iconPath = 'crossed-out-circle.png';
			break;
		case PageImageEnum.Cryptography:
			iconPath = 'cryptography.png';
			break;
		case PageImageEnum.Error:
			iconPath = 'error.png';
			break;
		case PageImageEnum.Folder:
			iconPath = 'folder.png';
			break;
		case PageImageEnum.Gear:
			iconPath = 'gear.png';
			break;
		case PageImageEnum.Load:
			className = styles.spinner;
			iconPath = spinner;
			isPathFull = true;
			break;
		case PageImageEnum.Magnifier:
			className = styles.magnifier;
			iconPath = 'magnifier.png';
			break;
		case PageImageEnum.Success:
			iconPath = 'success.png';
			break;
		case PageImageEnum.Warning:
			iconPath = 'warning.png';
			break;
		case PageImageEnum.Cert:
			iconPath = 'cert.png';
			break;
		case PageImageEnum.CertError:
			iconPath = 'cert-error.png';
	}

	return (
		<div className={styles.main}>
			<img
				src={require('@assets/computer/empty.png')}
				className={styles.backgroundImage}
				alt={'computer'}
			/>
			<img
				className={[styles.iconImage, className].join(' ')}
				src={isPathFull ? iconPath : require(`@assets/computer/icons/${iconPath}`)}
			/>
		</div>
	);
}
