import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { getModelFromAgentHttps, deleteModelFromAgentHttps } from '@helpers/fetch-helper';
import { TypedJSON } from 'typedjson';
import { CacheSavingResult } from '../enums/cache-saving-result';
import { CryptoProviderType } from '../enums/crypto-provider-type';
import { UpdateCpMinorVersionErrorType } from '../enums/update-cp-minor-version-error-type';
import { DeleteCpDistrRequestDto } from '../models/dtos/requests/delete-cp-distr-request.dto';
import { CpLicenseResponseDto } from '../models/dtos/responses/cp-license-response.dto';
import { DeleteCpDistr500ResponseDto } from '../models/dtos/responses/delete-cp-distr-500-response.dto';
import { executeCleaner } from './cleaning';
import { deleteKeyFromCache, saveToCache } from './ta-cache-logic';
import logger from '@logger';

async function getCpLicenseKey(): Promise<string | null> {
	if (cpLicenseStore.licenseKey !== undefined) return cpLicenseStore.licenseKey;
	const cryptoLicenseResponse = await getModelFromAgentHttps(
		CpLicenseResponseDto,
		process.env.REACT_APP_AGENT_EP_CRYPTO_LICENSE!,
	);
	return cryptoLicenseResponse.serial;
}

async function saveCpKeyToCache(): Promise<void> {
	let licenseKey = await getCpLicenseKey();
	if (licenseKey) {
		const result = await saveToCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!, licenseKey);
		if (result !== CacheSavingResult.Ok) {
			throw new Error(
				`Ошибка при попытке сохранения ключа КриптоПро в кэш. Результат: ${CacheSavingResult[result]}`,
			);
		}
	}
}

async function deleteCp(): Promise<void> {
	const deleteCpDistrRequestDto = new DeleteCpDistrRequestDto();
	deleteCpDistrRequestDto.silent = true;
	deleteCpDistrRequestDto.type = CryptoProviderType.CryptoPro;

	const response = await deleteModelFromAgentHttps(
		process.env.REACT_APP_AGENT_EP_CRYPTO!,
		DeleteCpDistrRequestDto,
		deleteCpDistrRequestDto,
	);

	if (!response.ok) {
		if (response.status === 403) {
			await deleteKeyFromCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!);
			store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.NotEnoughRights);
			return;
		}
		if (cpStore.cryptoArmInstalled && response.status === 500) {
			const serializer = new TypedJSON(DeleteCpDistr500ResponseDto);
			const deleteCpDistr500ResponseDto = serializer.parse(await response.text());
			if (deleteCpDistr500ResponseDto?.globalError) {
				await executeCleaner();
				return;
			}
		}
		await deleteKeyFromCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!);
		store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.CommonFailure);
		return;
	}
}

export async function executeDeleteOldCpStage(): Promise<void> {
	await saveCpKeyToCache();
	await deleteCp();
}
