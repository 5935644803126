import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CryptoProviderVersion {
	@jsonMember(() => String)
	public type!: string;

	@jsonMember(() => String)
	public version!: string;
}
