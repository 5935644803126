import styles from './proxy-settings.module.scss';
import { useEffect } from 'react';
import { Page } from '@components/page-template';
import { observer } from 'mobx-react-lite';
import { proxySettingsStore } from '../../../features/settings/proxy/stores/proxy-settings-store';
import { Modal } from '../../../features/settings/proxy/components/modal';
import { ButtonsBlock } from '../../../features/settings/proxy/components/buttons-block';
import { ContentBlock } from '../../../features/settings/proxy/components/content-block';

function ProxySettingsPage() {
	useEffect(() => {
		proxySettingsStore.preparePage();
	}, []);

	return (
		<>
			<Page
				rightPaneContentClassName={styles.rightPaneContent}
				image={proxySettingsStore.pageImage}
				child={
					<div id={styles.main}>
						<div>
							<div id={styles.title}>Настройка прокси</div>
							<ContentBlock />
						</div>
						<ButtonsBlock />
					</div>
				}
				showMobileView={false}
			/>
			<Modal />
		</>
	);
}

export const ProxySettings = observer(ProxySettingsPage);
