import { ReactComponent as AttentionNoFill } from '@assets/icons/attention-no-fill.svg';
import { ReactComponent as InfoFill } from '@assets/icons/info-fill.svg';
import { ReactComponent as ErrorFill } from '@assets/icons/exclamation-circle.svg';
import { ReactComponent as ErrorTriangleNoFill } from '@assets/icons/error-triangle-no-fill.svg';
import styles from './prompt-block.module.scss';
import { ReactElement } from 'react';

export enum PromptBlockType {
	Warning,
	Message,
	Error,
	ErrorTriangle,
}

export interface PromptBlockProps {
	type: PromptBlockType;
	text: string | ReactElement;
	title?: string;
}

export function PromptBlock(props: PromptBlockProps) {
	function getColorStyle() {
		switch (props.type) {
			case PromptBlockType.Warning:
				return styles.warning;
			case PromptBlockType.Message:
				return styles.message;
			default:
				return styles.error;
		}
	}

	return (
		<div
			id={styles.block}
			className={getColorStyle()}
		>
			{props.type === PromptBlockType.Warning ? (
				<AttentionNoFill id={styles.blockIcon} />
			) : props.type === PromptBlockType.Message ? (
				<InfoFill id={styles.blockIcon} />
			) : props.type === PromptBlockType.ErrorTriangle ? (
				<ErrorTriangleNoFill
					id={styles.blockIcon}
					className={styles.errorIcon}
				/>
			) : (
				<ErrorFill
					id={styles.blockIcon}
					className={styles.errorIcon}
				/>
			)}
			<div id={styles.blockTextBlock}>
				{props.title && <div id={styles.blockTextBlockTitle}>{props.title}</div>}
				<div id={styles.blockTextBlockContent}>{props.text}</div>
			</div>
		</div>
	);
}
