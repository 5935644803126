import { jsonMember, jsonObject } from 'typedjson';
import { License } from './license';

@jsonObject
export class CryptoPro {
	@jsonMember(String)
	public major!: string;

	@jsonMember(String)
	public minor!: string;

	@jsonMember(String)
	public build!: string;

	@jsonMember(License)
	public license!: License;
}
