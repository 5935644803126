import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DistrInfo {
	@jsonMember(() => String)
	public linkToDistr!: string;

	@jsonMember(() => String)
	public version!: string;

	@jsonMember(() => String)
	public versionDescription!: string;
}
