import { Modal as BasicModal } from '@components/modal';
import { shortcutCreatorStore } from '@features/shortcuts-creator/stores/shortcut-creator-store';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { ModalState } from '../../enums/modal-state';
import { CreateShortcutsFailureModalCard } from '../modal-cards/create-shortcuts-failure-modal-card';

function ModalElement() {
	function getModalCard(): ReactElement {
		switch (shortcutCreatorStore.modalState) {
			case ModalState.CreateShortcutsFailure:
				return <CreateShortcutsFailureModalCard onCancel={cancel} />;
			default:
				return <></>;
		}
	}

	function cancel(): void {
		switch (shortcutCreatorStore.modalState) {
			case ModalState.CreateShortcutsFailure:
				shortcutCreatorStore.setModalState(ModalState.None);
				break;
		}
	}

	return (
		<BasicModal
			active={shortcutCreatorStore.modalState !== ModalState.None}
			children={getModalCard()}
			onClickOutOfChild={cancel}
		/>
	);
}

export const Modal = observer(ModalElement);
