import { commonInfo } from '@businessLogic/common-info';
import { DeserializeResponseJsonError } from '@errors/api/deserialize-response-json-error';
import { ServerResponseError } from '@errors/api/server-response-error';
import logger from '@logger';
import { Serializable, TypedJSON } from 'typedjson';
import { getServerRequestError, getFailedToDeserializeResponse } from './fetch-error-helper';

export async function fetchModel<T>(rootConstructor: Serializable<T>, url: string, init?: RequestInit): Promise<T> {
	return fetchModelInner<T, T>(rootConstructor, url, (serializer, body) => serializer.parse(body), init);
}

export async function fetchModelFromAgentHttps<T>(
	rootConstructor: Serializable<T>,
	endpoint: string,
	init?: RequestInit,
): Promise<T> {
	return fetchModel(rootConstructor, `${commonInfo.agentHttps}${endpoint}`, init);
}

export async function fetchModelFromBackend<T>(
	rootConstructor: Serializable<T>,
	endpoint: string,
	init?: RequestInit,
): Promise<T> {
	return fetchModel(rootConstructor, `${commonInfo.backendUrl}${endpoint}`, init);
}

export async function fetchModelsArray<T>(
	rootConstructor: Serializable<T>,
	url: string,
	init?: RequestInit,
): Promise<Array<T>> {
	return fetchModelInner<T, Array<T>>(
		rootConstructor,
		url,
		(serializer, body) => {
			if (body.length === 0) {
				return [];
			}
			return serializer.parseAsArray(body);
		},
		init,
	);
}

export async function fetchModelsArrayFromAgentHttps<T>(
	rootConstructor: Serializable<T>,
	endpoint: string,
	init?: RequestInit,
): Promise<Array<T>> {
	return fetchModelsArray(rootConstructor, `${commonInfo.agentHttps}${endpoint}`, init);
}

export async function fetchModelsArrayFromBackend<T>(
	rootConstructor: Serializable<T>,
	endpoint: string,
	init?: RequestInit,
): Promise<Array<T>> {
	return fetchModelsArray(rootConstructor, `${commonInfo.backendUrl}${endpoint}`, init);
}

async function fetchModelInner<T, K>(
	rootConstructor: Serializable<T>,
	url: string,
	parseFunc: (serializer: TypedJSON<T>, body: string) => K | undefined,
	init?: RequestInit,
): Promise<K> {
	try {
		logger.info(`Осуществляется запрос по адресу: ${url}`);
		let response = await fetch(new URL(url), init);
		if (!response.ok) {
			throw await getServerRequestError(response);
		}
		let body = await response.text();
		let serializer = new TypedJSON(rootConstructor);
		let result = parseFunc(serializer, body);
		if (result === undefined) throw getFailedToDeserializeResponse(response, typeof rootConstructor);
		return result;
	} catch (error) {
		if (error instanceof ServerResponseError || error instanceof DeserializeResponseJsonError) {
			throw error;
		}
		throw new Error(`Ошибка при запросе по адресу ${url}. Ошибка: ${error}`);
	}
}
