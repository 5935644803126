import { InstructionLink } from '@components/instruction-link';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import { MouseEventHandler } from 'react';

export interface Props {
	title: string;
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
}

export function CheckCertModalCardTemplate(props: Props) {
	return (
		<InfoModalCardTemplate
			title={props.title}
			info={
				<>
					Проверьте сертификат в{' '}
					<InstructionLink
						link={process.env.REACT_APP_OS_ADDRESS!}
						text="Онлайн-Спринтере"
					/>
				</>
			}
			image={<Attention />}
			centerCross
			onClickCrossButton={props.onClickCrossButton}
		/>
	);
}
