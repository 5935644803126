import ContentLoader from 'react-content-loader';
import styles from './content-skeleton.module.scss';

export const ContentSkeleton = () => (
	<div className={styles.content}>
		<ContentLoader
			speed={4}
			viewBox="0 0 600 19"
			backgroundColor="#A6B5C3"
			foregroundColor="#f7f9fc"
			title={'Загрузка...'}
			className={styles.labels}
		>
			<rect
				x="0"
				y="5"
				rx="8"
				ry="8"
				width="76"
				height="14"
			/>
			<rect
				x="156"
				y="5"
				rx="8"
				ry="8"
				width="114"
				height="14"
			/>
			<rect
				x="480"
				y="5"
				rx="8"
				ry="8"
				width="39"
				height="14"
			/>
		</ContentLoader>
		<ContentLoader
			speed={4}
			viewBox="0 0 600 147"
			backgroundColor="#F2F5FA"
			foregroundColor="#f7f9fc"
			title={'Загрузка...'}
		>
			<rect
				x="0"
				y="13"
				rx="8"
				ry="8"
				width="140"
				height="56"
			/>
			<rect
				x="156"
				y="13"
				rx="8"
				ry="8"
				width="308"
				height="56"
			/>
			<rect
				x="480"
				y="13"
				rx="8"
				ry="8"
				width="120"
				height="56"
			/>
			<rect
				x="0"
				y="91"
				rx="8"
				ry="8"
				width="600"
				height="56"
			/>
		</ContentLoader>
	</div>
);
