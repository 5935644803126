import styles from './error-main.module.scss';
import { useRef } from 'react';
import { useState } from 'react';
import { DropDowns, DropDownData } from '@components/drop-downs';
import { Switcher } from '@components/switcher';
import { SystemTypes } from '@enums/system-types';
import { commonInfo } from '@businessLogic/common-info';

const showMobileView = false;

export type { DropDownData };

export interface ErrorPageProps {
	windowsElements: DropDownData[];
	linuxElements: DropDownData[];
	title: string;
	switchOsText: string;
}

export function ErrorMain(props: ErrorPageProps) {
	const dropDownsRef = useRef<DropDowns>(null);
	const [systemType, setSystemType] = useState<SystemTypes>(commonInfo.getSystemType());

	const callbackSwitcher = (systemType: SystemTypes): void => {
		setSystemType(systemType);
		dropDownsRef.current?.changeDropdownState(
			// В случае, если другие системы, отображаем по умолчанию Windows
			systemType === SystemTypes.Linux ? props.linuxElements[0][2] : props.windowsElements[0][2],
			true,
		);
	};

	return (
		<>
			<div id={styles.title}>{props.title}</div>
			<div id={styles.subtitleContainer}>
				<div id={styles.subtitle}>{props.switchOsText}</div>
				<Switcher
					showMobileView={showMobileView}
					callback={callbackSwitcher}
					systemType={systemType}
				/>
			</div>
			<DropDowns
				// В случае, если другие системы, отображаем по умолчанию Windows
				elements={systemType === SystemTypes.Linux ? props.linuxElements : props.windowsElements}
				openFirst={true}
				ref={dropDownsRef}
			/>
		</>
	);
}
