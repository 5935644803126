import spinner from '@assets/icons/spinners/spinner-thin.svg';
import { PingStatus } from '@features/settings/proxy/enums/ping-status';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { ReactComponent as Warning } from '@assets/icons/exclamation-triangle.svg';
import styles from './ping-status-info.module.scss';

export interface PingStatusInfoProps {
	status: PingStatus;
	onClickInterruptButton?: Function;
	onClickRepeatButton?: Function;
}

function PingStatusInfoElement(props: PingStatusInfoProps) {
	function getHintText() {
		if (props.status !== PingStatus.NotSucceeded) {
			return <></>;
		}
		return (
			<>
				<div></div>
				<div className={styles.hintText}>
					Пожалуйста, убедитесь, что протокол, адрес сервера, порт и пара логин&nbsp;и&nbsp;пароль (при их
					наличии) указаны правильно, и повторите попытку.
				</div>
			</>
		);
	}

	function getImage(): ReactElement {
		switch (props.status) {
			case PingStatus.Checking:
				return (
					<img
						src={spinner}
						className={[styles.loadImage, styles.spinner].join(' ')}
					/>
				);
			case PingStatus.Interrupted:
			case PingStatus.NotSucceeded:
				return <Warning className={styles.attentionImage} />;
			default:
				return <></>;
		}
	}

	function getInfo(): ReactElement {
		let headerText: string;

		switch (props.status) {
			case PingStatus.Checking:
				headerText = 'Проверяем соединение с прокси...';
				break;
			case PingStatus.Interrupted:
				headerText = 'Проверка соединения прервана';
				break;
			case PingStatus.NotSucceeded:
				headerText = 'Не удалось установить соединение через прокси-сервер';
				break;
			default:
				return <></>;
		}

		return (
			<div className={styles.info}>
				{getImage()}
				<div className={styles.title}>{headerText}</div>
				{getHintText()}
			</div>
		);
	}

	function getButton(): ReactElement {
		if (props.status === PingStatus.Checking) {
			return (
				<button
					className={styles.button}
					onClick={() => props.onClickInterruptButton?.()}
				>
					Прервать
				</button>
			);
		}
		if (props.status === PingStatus.Interrupted) {
			return (
				<button
					className={styles.button}
					onClick={() => props.onClickRepeatButton?.()}
				>
					Повторить
				</button>
			);
		}
		return <></>;
	}

	function getMainDivClassName(): string {
		switch (props.status) {
			case PingStatus.Interrupted:
			case PingStatus.NotSucceeded:
				return styles.backgroundColorWarn;
			case PingStatus.Ok:
				return styles.displayNone;
			default:
				return '';
		}
	}

	return (
		<div
			id={styles.main}
			className={getMainDivClassName()}
		>
			{getInfo()}
			{getButton()}
		</div>
	);
}

export const PingStatusInfo = observer(PingStatusInfoElement);
