import logger from '@logger';
import {
	CryptographyInstallInfo,
	CryptographyLogInfo,
	CurrentEnvironment,
} from '@features/setup-wizard/models/api/cryptography';
import { getModelFromAgentHttps, postModelToAgentHttps, postModelToBackend } from '@helpers/fetch-helper';
import { isNullOrEmpty } from '@helpers/string-helper';
import { SoftTypes } from '@features/setup-wizard/enums/soft-types';
import { CryptoInstallResult } from '@features/setup-wizard/enums/crypto-install-result';
import { restartAgent } from '@businessLogic/agent-restarter';
import { DistrInfo } from '@models/api';
import { CryptographyUpdateInfo } from '@features/setup-wizard/models/api/cryptography/cryptography-update-info';
import { GetDistrInfo } from '@features/setup-wizard/business-logic/api/get-distr-info';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { License } from '@features/setup-wizard/models/api/cryptography/license';
import { commonInfo } from '@businessLogic/common-info';
import { SystemTypes } from '@enums/system-types';

async function makeAfterInstallOperation(version: string) {
	await sendLogOfInstallCryptoPro(version);
	await restartAgent();
}

async function afterOperationWork(response: Response, distrInfo: DistrInfo): Promise<CryptoInstallResult> {
	switch (response.status) {
		case 200:
			await makeAfterInstallOperation(distrInfo.version);
			logger.info('КриптоПро успешно настроен!');
			return CryptoInstallResult.Success;
		case 422:
			logger.warn('Неправильный ключ КриптоПро, но крипто провайдер был установлен!');
			if ((await commonInfo.getSystemInfo()).type === SystemTypes.Linux) {
				await makeAfterInstallOperation(distrInfo.version);
			}
			return CryptoInstallResult.NotNormalLicenseInstallDemo;
		case 400:
			logger.warn('Установка завершилась ошибкой связанной с лицензионным ключом!');
			return CryptoInstallResult.NotNormalLicense;
		case 403:
			logger.warn('Установка завершилась ошибкой доступа.');
			return CryptoInstallResult.AccessDenied;
		case 409:
			logger.warn('КриптоПро уже есть на компьютере!');
			return CryptoInstallResult.CryptoAlreadyInstall;
		case 410:
			logger.warn('Установка завершилась ошибкой так как не смогли скачать инсталлятор по ссылке!');
			return CryptoInstallResult.ErrorWhenReceiveInstaller;
		case 503:
			logger.warn('Установка завершилась так как запущен другой инсталлятор.');
			return CryptoInstallResult.InstallerAlreadyRun;
		default:
			logger.warn(`Установка завершилась со статус кодом [${response.status}]`);
			return CryptoInstallResult.NotSuccess;
	}
}

export async function installCryptoProvider(): Promise<CryptoInstallResult> {
	try {
		logger.info('Получена информация о дистрибутиве КриптоПро.');
		let distrInfo = await GetDistrInfo(SoftTypes.CryptoPro);
		let cryptographyInstallInfo = new CryptographyInstallInfo();
		cryptographyInstallInfo.setupURL = distrInfo.linkToDistr;
		cryptographyInstallInfo.silentInstall = true;
		cryptographyInstallInfo.type = 'CryptoPro'; // TODO SMA использовать enum вместо этого, который сейчас находится в update-minor-cp-version фиче. посмотреть также в других местах

		if (cpLicenseStore.licenseKey) {
			cryptographyInstallInfo.license = new License();
			cryptographyInstallInfo.license.licenseKey = cpLicenseStore.licenseKey;
		}

		let installResponse = await postModelToAgentHttps(
			process.env.REACT_APP_AGENT_EP_CRYPTO_INSTALL!,
			CryptographyInstallInfo,
			cryptographyInstallInfo,
		);

		return await afterOperationWork(installResponse, distrInfo);
	} catch (e) {
		logger.error(`Ошибка при попытке установить Крипто про ${e}`);
		return CryptoInstallResult.NotSuccess;
	}
}

export async function updateCryptoProvider(): Promise<CryptoInstallResult> {
	try {
		let distr = await GetDistrInfo(SoftTypes.CryptoPro);
		if (distr === undefined) {
			return CryptoInstallResult.ErrorWhenReceiveInstaller;
		}
		logger.info(`Получена информация о дистрибутиве`);

		let updateCryptoInfo = new CryptographyUpdateInfo();
		if (cpLicenseStore.licenseKey) {
			updateCryptoInfo.licenseKey = cpLicenseStore.licenseKey;
		}
		updateCryptoInfo.type = 'CryptoPro';
		updateCryptoInfo.setupURL = distr.linkToDistr;
		updateCryptoInfo.silentInstall = true;

		let installResponse = await postModelToAgentHttps(
			process.env.REACT_APP_AGENT_EP_CRYPTO_UPDATE!,
			CryptographyUpdateInfo,
			updateCryptoInfo,
		);
		return await afterOperationWork(installResponse, distr);
	} catch (e) {
		logger.error(`Ошибка при попытке обновить Крипто про ${e}`);
		return CryptoInstallResult.NotSuccess;
	}
}

export async function sendLogOfInstallCryptoPro(version: string | undefined) {
	try {
		let currentEnvironment = await getModelFromAgentHttps(
			CurrentEnvironment,
			process.env.REACT_APP_AGENT_EP_CURRENT_ENVIRONMENT!,
		);
		if (isNullOrEmpty(version) || currentEnvironment == null) {
			logger.warn(
				'Логи по криптографии не были отправлены, т.к. отсутствует необходимая для передачи информация.',
			);
			return false;
		}
		let cryptographyLogInfo = new CryptographyLogInfo();
		cryptographyLogInfo.cryptoProVersion = version!;
		cryptographyLogInfo.machineName = currentEnvironment.hostname;
		cryptographyLogInfo.userName = currentEnvironment.username;
		await postModelToBackend(process.env.REACT_APP_EP_CRYPTO_LOG!, CryptographyLogInfo, cryptographyLogInfo);
		logger.info(`Успешно отправлены логи по криптографии.`);
		return true;
	} catch (error) {
		logger.error('Ошибка при отправке логов по криптографии на сервер!');
		return false;
	}
}
