import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ReAuthRequest {
	@jsonMember(String)
	public abonentId!: string;

	@jsonMember(String)
	public name!: string;

	@jsonMember(String)
	public accessToken!: string;

	@jsonMember(String)
	public refreshToken!: string;

	@jsonMember(Date)
	public accessTokenExpTime!: Date;

	@jsonMember(Date)
	public refreshTokenExpTime!: Date;
}
