import { Page, PageImage } from '@components/page-template';
import styles from './directory-selector.module.scss';
import { ReactComponent as Folder } from '@assets/icons/folder.svg';
import { useEffect, useState } from 'react';
import { storeSettings } from '@features/settings/common/stores/store-settings';
import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import logger from '@logger';
import Logger from '@logger';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { appendNewOrg, checkPath, updateOrgPath } from '@features/settings/auto-import/business-logic/api-provider';
import { revokeToken } from '@businessLogic/api/oauth/oauth-adapter';
import { CheckDirectoryResult } from '@features/settings/auto-import/enums/check-directory-result';
import { ModalState } from '@features/settings/auto-import/enums/modal-state';
import { Modal } from '@features/settings/auto-import/components/modal/modal';
import { notifyGreen } from '@components/notification';
import { observer } from 'mobx-react-lite';
import { cutOrgName } from '@features/settings/auto-import/business-logic/support';
import { isNullOrEmpty } from '@helpers/string-helper';
import { NewOrgData } from '@features/settings/auto-import/models/api/new-org-data';
import { OrgAuthData } from '@features/settings/auto-import/models/api/org-auth-data';
import { TitleWithArrow } from '@components/title-with-arrow';

function DirectorySelectorElement() {
	useEffect(() => {
		if (autoImportStore.isNewOrg) {
			setOrgNameAll(autoImportStore.newOrgData!.result.orgData.name);
		} else {
			setOrgNameAll(autoImportStore.editedOrgInfo?.savedOrg.name!);
		}
	}, []);

	let [orgName, setOrgName] = useState('');
	let [orgHoverName, setOrgHoverName] = useState<string | undefined>('');

	function isFolderSelected(): boolean {
		return !isNullOrEmpty(autoImportStore.selectedDirectoryPath);
	}

	function setOrgNameAll(name: string) {
		let cutRes = cutOrgName(name);
		setOrgName(cutRes.presentOrgName);
		setOrgHoverName(cutRes.tooltipOrgName);
	}

	function getSelectButtonText(): string {
		if (isFolderSelected()) {
			return 'Изменить...';
		}
		return 'Выбрать...';
	}

	async function onSaveSettings() {
		try {
			let checkPathResult = await checkPathNormal();
			if (checkPathResult === CheckDirectoryResult.UsedByAnotherOrg) {
				logger.log(
					'Папка использоваться не может ибо используется в другой организации ' +
						autoImportStore.selectedDirectoryPath!,
				);
				autoImportStore.setModal(ModalState.DirectoryAlreadyInUse);
				return;
			} else if (checkPathResult === CheckDirectoryResult.NotAccessedOrNotCorrect) {
				logger.log(
					'К папке нет доступа и она не может использоваться ' + autoImportStore.selectedDirectoryPath!,
				);
				autoImportStore.setModal(ModalState.WrongDirectory);
				return;
			}
			if (autoImportStore.isNewOrg) {
				let newOrgOauthData = autoImportStore.newOrgData?.result;
				let newOrgToAddData = new NewOrgData();
				newOrgToAddData.directoryPath = autoImportStore.selectedDirectoryPath!;

				let taAuthData = new OrgAuthData();
				taAuthData.inn = newOrgOauthData!.orgData.inn!;
				taAuthData.name = newOrgOauthData!.orgData.name!;
				taAuthData.abonentId = newOrgOauthData?.orgData.abonentId!;
				taAuthData.accessToken = newOrgOauthData?.accessToken!;
				taAuthData.refreshToken = newOrgOauthData?.refreshToken!;
				taAuthData.accessTokenExpTime = newOrgOauthData?.accessTokenExpTime!;
				taAuthData.refreshTokenExpTime = newOrgOauthData?.refreshTokenExpTime!;
				newOrgToAddData.orgAuthData = taAuthData;

				let newOrgFromTA = await appendNewOrg(newOrgToAddData);
				if (newOrgFromTA === undefined) {
					logger.error('Не удалось сохранить данные при добавлении. Покажем об этом модалку');
					autoImportStore.setModal(ModalState.SomethingWentWrong);
					return;
				}
				autoImportStore.appendOrgList(newOrgFromTA);
			} else {
				let editResult = await updateOrgPath(
					autoImportStore.editedOrgInfo?.orgId!,
					autoImportStore.selectedDirectoryPath!,
				);
				if (editResult === undefined) {
					logger.error('Не удалось сохранить данные при изменении пути. Покажем об этом модалку');
					autoImportStore.setModal(ModalState.SomethingWentWrong);
					return;
				}
				autoImportStore.editedOrgInfo!.directoryPath = autoImportStore.selectedDirectoryPath!;
			}
			autoImportStore.setModal(ModalState.None);
			returnBack(true);
		} catch (ex) {
			Logger.error('Ошибка при сохранении настроек');
			autoImportStore.setModal(ModalState.SomethingWentWrong);
		}
	}

	async function cancel() {
		logger.info('Юзер отказался от редактирования директории');
		if (autoImportStore.isNewOrg) {
			await revokeToken(autoImportStore.newOrgData?.id!);
		}
		autoImportStore.directorySelectorEnd();
		returnBack(false);
	}

	function returnBack(notify: boolean) {
		storeSettings.setPage(PagesSettings.AutoImportSettings);
		if (notify) {
			notifyGreen('Настройки сохранены');
		}
	}

	async function checkPathNormal(): Promise<CheckDirectoryResult> {
		let checkPathResult = await checkPath(autoImportStore.selectedDirectoryPath!);
		if (checkPathResult.isPathBusyByAnother) {
			return CheckDirectoryResult.UsedByAnotherOrg;
		} else if (checkPathResult.isNoAccessToPath || checkPathResult.isPathInvalid) {
			return CheckDirectoryResult.NotAccessedOrNotCorrect;
		} else {
			return CheckDirectoryResult.Normal;
		}
	}

	return (
		<>
			<Page
				image={PageImage.Folder}
				child={
					<>
						<div id={styles.main}>
							<TitleWithArrow
								title="Папка для автозагрузки"
								subtitle={orgName}
								subtitleHint={orgHoverName}
								onArrowClick={() => cancel()}
							/>
							<div id={styles.directorySelect}>
								<input
									id={styles.inputDirectory}
									type={'text'}
									placeholder={'-- папка не выбрана --'}
									value={
										isNullOrEmpty(autoImportStore.selectedDirectoryPath)
											? ''
											: autoImportStore.selectedDirectoryPath!
									}
									readOnly={true}
								/>
								<div id={styles.selectButtonBlock}>
									<button
										id={
											isFolderSelected() ? styles.selectButtonFolderSelected : styles.selectButton
										}
										onClick={async () => {
											await autoImportStore.selectDirectory();
										}}
									>
										<div id={styles.selectButtonContent}>
											<div id={styles.selectButtonIcon}>
												<Folder
													id={
														isFolderSelected()
															? styles.folderInButtonSelected
															: styles.folderInButton
													}
												/>
											</div>
											<div
												id={
													isFolderSelected()
														? styles.selectButtonTextFolderSelected
														: styles.selectButtonText
												}
											>
												{getSelectButtonText()}
											</div>
										</div>
									</button>
								</div>
							</div>
							<div id={styles.saveButtonBlock}>
								<button
									id={styles.saveButton}
									onClick={onSaveSettings}
									disabled={!isFolderSelected()}
								>
									Сохранить настройки
								</button>
							</div>
						</div>
						<Modal />
					</>
				}
				rightPaneContentClassName={styles.rightPaneContent}
				showMobileView={false}
			/>
		</>
	);
}

export const DirectorySelector = observer(DirectorySelectorElement);
