import { getModelFromAgentHttps, getModelFromBackend, postToAgentHttps } from '@helpers/fetch-helper';
import { CertsInfo, CertsSetupInfo } from '@features/setup-wizard/models/api/certs';
import logger from '@logger';
import { checkCryptoProviderExists } from '@features/setup-wizard/business-logic/api/crypto/check-crypto-provider';
import {
	StageInfo,
	StageInfoWithLinkContent,
	StageInfoWithTextContent,
} from '@features/setup-wizard/models/stages/infos';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { StorageTypes } from '@features/setup-wizard/enums/storage-types';
import { SystemTypes } from '@enums/system-types';
import { Stage } from '@features/setup-wizard/models/stages';
import { commonInfo } from '@businessLogic/common-info';

const BreakError = {};

class StorageDescription {
	public readonly storageType: StorageTypes;
	public readonly agentEndPoint: string;

	public constructor(storageType: StorageTypes, agentEndPoint: string) {
		this.storageType = storageType;
		this.agentEndPoint = agentEndPoint;
	}

	public getStoreTypeToServerEndPoint(): string {
		return `${process.env.REACT_APP_EP_CERT_THUMB_TO_INSTALL}?storeType=${this.storageType}`;
	}

	public static getCaDesc(): StorageDescription {
		return new StorageDescription(StorageTypes.Ca, process.env.REACT_APP_AGENT_EP_CERT_CA!);
	}

	public static getRootDesc(): StorageDescription {
		return new StorageDescription(StorageTypes.Root, process.env.REACT_APP_AGENT_EP_CERT_ROOT!);
	}
}

class StorageDescAdapter {
	private static descOfCa = StorageDescription.getCaDesc();
	private static descOfRoot = StorageDescription.getRootDesc();

	public static getDescOf(storageType: StorageTypes): StorageDescription {
		switch (storageType) {
			case StorageTypes.Root:
				return this.descOfRoot;
			case StorageTypes.Ca:
				return this.descOfCa;
			default:
				throw new Error(`Не реализован тип: ${storageType}`);
		}
	}
}

export class CertsStage extends Stage {
	private certOfCAToInstall: string[] = [];
	private certOfRootToInstall: string[] = [];

	public constructor() {
		super(StageTypes.Certs);
	}

	protected async checkUpLogic() {
		this.certOfCAToInstall = await this.checkUpCertHandler(StorageTypes.Ca);
		this.certOfRootToInstall = await this.checkUpCertHandler(StorageTypes.Root);
		if (this.certOfCAToInstall.length === 0 && this.certOfRootToInstall.length === 0) {
			this.needInstall = false;
			logger.info(`Проверка шага установки сертификатов завершилась без необходимости установки сертификатов!`);
			return;
		}
		logger.info(
			`Будем устанавливать следующие сертификаты CA=${this.certOfCAToInstall} и ROOT${this.certOfRootToInstall}`,
		);
	}

	protected async setupLogic() {
		if ((await commonInfo.getSystemInfo()).type === SystemTypes.Linux) {
			let cryptoExist = await checkCryptoProviderExists();
			if (!cryptoExist) {
				logger.warn('Не удалось найти крипто провайдер. Фейлим шаг!');
				return;
			}
		}

		let setupCA: boolean = true;
		let setupRoot: boolean = true;

		if (this.certOfCAToInstall!.length !== 0) {
			logger.info(`Поставим сертификаты типа ${StorageTypes.Ca} в количестве [${this.certOfCAToInstall.length}]`);
			setupCA = await this.setupCerts(StorageTypes.Ca, this.certOfCAToInstall);
		}
		if (this.certOfRootToInstall.length !== 0) {
			logger.info(
				`Поставим сертификаты типа ${StorageTypes.Root} в количестве [${this.certOfRootToInstall.length}]`,
			);
			setupRoot = await this.setupCerts(StorageTypes.Root, this.certOfRootToInstall);
		}

		if (!setupCA || !setupRoot) {
			logger.warn('Не удалось настроить сертификаты. Фейлим весь шаг!');
			return;
		}
		this.needInstall = false;
		logger.info('Установка завершилась!');
	}

	public getSetupSettingsStageInfo(): StageInfoWithTextContent {
		return new StageInfoWithTextContent(
			'Установить сертификаты',
			'Минкомсвязи, Минцифры, Такскома, чтобы проверять электронные подписи',
		);
	}

	public getCheckUpStageInfo(): StageInfo {
		return new StageInfo('Сертификаты');
	}

	public getInstallStageInfo(): StageInfo {
		return new StageInfo('Устанавливаем сертификаты');
	}

	public getInstallErrorStageInfo(): StageInfoWithLinkContent {
		if (commonInfo.getSystemType() === SystemTypes.Linux) {
			return new StageInfoWithLinkContent('Установите сертификаты', {
				textBeforeLink: 'Скачайте и установите сертификаты Минкомсвязи, Минцифры и Такскома',
			});
		} else {
			return new StageInfoWithLinkContent('Установите сертификаты', {
				link: process.env.REACT_APP_INSTR_FAILED_CERTS!,
				textOfLink: 'по инструкции',
				textBeforeLink: 'Скачайте и установите сертификаты Минкомсвязи, Минцифры и Такскома ',
			});
		}
	}

	private async checkUpCertHandler(storageType: StorageTypes): Promise<string[]> {
		let findData = await this.checkUpCerts(storageType);
		if (findData == null) throw new Error(`Не удалось проверить сертификаты типа [${storageType}]!`);
		return findData;
	}

	private async checkUpCerts(storageType: StorageTypes): Promise<string[] | undefined> {
		let storeDesc = StorageDescAdapter.getDescOf(storageType);

		let certsInfoBackend = await getModelFromBackend(CertsInfo, storeDesc.getStoreTypeToServerEndPoint());

		let certsInfo = new CertsInfo();
		if ((await commonInfo.getSystemInfo()).type === SystemTypes.Linux && !(await checkCryptoProviderExists())) {
			logger.warn('Не нашли крипто провайдер на Linux запланируем установку всех сертификатов!');
			certsInfo.thumbs = certsInfoBackend.thumbs;
			return certsInfo.thumbs;
		}

		let certsInfoTa = await getModelFromAgentHttps(CertsInfo, storeDesc.agentEndPoint);
		let thumbsTa = certsInfoTa.thumbs;

		let certsResult = certsInfoBackend.thumbs.filter(thumbBackend => {
			let check = true;
			try {
				thumbsTa.forEach(thumbTa => {
					if (thumbTa.toLowerCase() === thumbBackend.toLowerCase()) {
						check = false;
						throw BreakError;
					}
				});
			} catch (err) {
				if (err !== BreakError) throw err;
			}
			return check;
		});
		logger.info(`Сертификаты типа [${storageType}] для установки `, certsInfo);
		return certsResult!;
	}

	private async setupCerts(storageType: StorageTypes, certThumbToInstall: string[]): Promise<boolean> {
		let storeDesc = StorageDescAdapter.getDescOf(storageType);

		let requestServerUrl = this.getSetupEndpoint(certThumbToInstall);
		let requestAgentUrl = storeDesc.agentEndPoint;

		let certsSetupInfo = await getModelFromBackend(CertsSetupInfo, requestServerUrl);

		let status = true;
		for (const cert of certsSetupInfo.certs) {
			try {
				let response = await postToAgentHttps(requestAgentUrl, cert.certBody);
				if (!response.ok) {
					status = false;
				}
			} catch (e) {
				logger.error(`Ошибка установки сертификата [${cert.thumb}]`, e);
				status = false;
			}
		}

		return status;
	}

	private getSetupEndpoint(thumbs: string[]) {
		let url = `${process.env.REACT_APP_EP_LOAD_CERT_TO_INSTALL}`;
		for (let i = 0; i < thumbs.length; i++) {
			if (i === 0) url += `?thumb=${thumbs[i]}`;
			else url += `&thumb=${thumbs[i]}`;
		}
		return url;
	}
}
