import styles from './setup-failed.module.scss';
import { CheckConnectionButton } from '@components/check-connection-button';
import { DropDownData, DropDowns } from '@components/drop-downs';
import { FailedToInstallInfo } from '@features/setup-wizard/components/failed-to-install-info';
import { Loader } from '@components/loader';
import { Page, PageImage } from '@components/page-template';
import { useEffect, useState } from 'react';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { SetupFailedData } from '@models/setup-failed-data';

export function SetupFailed() {
	useEffect(() => {
		async function getStages() {
			let setupInfo = new SetupFailedData();
			let stagesToView = stagesWorker.getNeededToInstall();
			for (const stage of stagesToView) {
				let element: DropDownData = [
					stage.getInstallErrorStageInfo().title,
					FailedToInstallInfo(stage.getInstallErrorStageInfo().content),
					stage.id,
				];
				setupInfo.elements.push(element);
			}
			setupInfo.isLoaded = true;
			setSetupParams(setupInfo);
		}

		getStages();
	}, []);

	const [setupInfo, setSetupParams] = useState<SetupFailedData | undefined>();

	return (
		<Page
			image={PageImage.Warning}
			child={
				<>
					<div id={styles.title}>Не смогли настроить компьютер</div>
					{setupInfo == null || !setupInfo.isLoaded ? (
						<Loader />
					) : (
						<div>
							<div id={styles.subtitle}>Пожалуйста, настройте вручную и нажмите «Проверить»</div>
							<DropDowns
								elements={setupInfo.elements}
								openFirst={true}
							/>
						</div>
					)}

					<div className={styles.btnArea}>
						{setupInfo == null || !setupInfo.isLoaded ? <Loader /> : <CheckConnectionButton />}
					</div>
				</>
			}
			showMobileView={false}
		/>
	);
}
