import { Page, PageImage } from '@components/page-template';
import { updateCpMinorVersionProcess } from '@features/setup-wizard/models/pages';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { UpdateCpMinorVersionErrorType } from '@features/update-cp-minor-version/enums/update-cp-minor-version-error-type';
import { UpdateCpMinorVersionStage } from '@features/update-cp-minor-version/enums/update-cp-minor-version-stage';
import styles from './update-cp-minor-version-error-page.module.scss';

interface Props {
	errorType: UpdateCpMinorVersionErrorType;
}

export function UpdateCpMinorVersionErrorPage(props: Props) {
	function renderTitleText(): string {
		switch (props.errorType) {
			case UpdateCpMinorVersionErrorType.NotEnoughRights:
				return 'Не хватает прав для выполнения';
			case UpdateCpMinorVersionErrorType.CommonFailure:
				switch (cpStore.currentUpdateCpMinorVersionStage?.kind) {
					case UpdateCpMinorVersionStage.Deletion:
						return 'Не удалось удалить текущую версию КриптоПро';
					case UpdateCpMinorVersionStage.Installation:
						return 'Не удалось установить новую версию КриптоПро';
					case UpdateCpMinorVersionStage.Cleaning:
						return 'Не удалось проверить файлы';
					default:
						return '';
				}
		}
	}

	function renderSubtitleText(): string {
		switch (props.errorType) {
			case UpdateCpMinorVersionErrorType.NotEnoughRights:
				return 'Попробуйте ещё раз с правами Администратора';
			case UpdateCpMinorVersionErrorType.CommonFailure:
				return 'Попробуйте ещё раз';
		}
	}

	return (
		<Page
			rightPaneContentClassName={styles.rightPaneContent}
			image={PageImage.Error}
			child={
				<div>
					<div id={styles.title}>{renderTitleText()}</div>
					<div id={styles.subtitle}>{renderSubtitleText()}</div>
					<button
						className={styles.buttonBlue}
						onClick={() => store.setPage(updateCpMinorVersionProcess())}
					>
						Повторить
					</button>
				</div>
			}
		/>
	);
}
