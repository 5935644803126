import styles from './org-card.module.scss';
import { ReactComponent as Folder } from '@assets/icons/folder.svg';
import logger from '@logger';
import { observer } from 'mobx-react-lite';
import { changeOrgStatus } from '../../business-logic/api-provider';
import { ModalState } from '../../enums/modal-state';
import { OrgInfo } from '../../models/api/org-info';
import { autoImportStore } from '../../stores/auto-import-store';
import { OrgCardTemplate } from '@features/settings/common/components/org-card-template';
import { OrgCardErrorText } from '@features/settings/auto-import/components/org-card/org-card-error-text';

interface OrgCardProps {
	orgInfo: OrgInfo;
}

function OrgCardElement(props: OrgCardProps) {
	function highlight(): boolean {
		return (
			autoImportStore.highlightSelectedOrg &&
			(props.orgInfo.savedOrg.abonentId === autoImportStore.selectedOrgId ||
				props.orgInfo.orgId === autoImportStore.selectedOrgId)
		);
	}

	function error(): boolean {
		return props.orgInfo.isExpired || props.orgInfo.isDirectoryBad;
	}

	function onEdit() {
		logger.info('Пользователь захотел отредактировать директорию');
		autoImportStore.startOrgEditing(props.orgInfo);
	}

	async function onToggle(checked: boolean) {
		let resOfChange = await changeOrgStatus(props.orgInfo.orgId, !checked);
		if (resOfChange === undefined) {
			logger.error('Ошибка при обновлении статуса организации с id ' + props.orgInfo.orgId);
			autoImportStore.setModal(ModalState.SomethingWentWrong);
			return;
		}
		autoImportStore.setOrgStatus(props.orgInfo.orgId, resOfChange.isEnabled);
	}

	async function onDelete() {
		autoImportStore.setOrgNameToModal(props.orgInfo.savedOrg.name);
		autoImportStore.setAgentOrgIdToModal(props.orgInfo.orgId);
		autoImportStore.setModal(ModalState.DeleteOrg);
	}

	function showErrorIfNeeded() {
		if (props.orgInfo.isExpired) {
			return (
				<OrgCardErrorText
					errorName="Ошибка входа"
					buttonText="Войти"
					onButtonClick={async () => {
						autoImportStore.setReAuthOrgId(props.orgInfo.orgId);
						await autoImportStore.makeOauth();
					}}
				/>
			);
		}

		if (props.orgInfo.isDirectoryBad) {
			return (
				<OrgCardErrorText
					errorName="Нет доступа к папке"
					buttonText="Выбрать другую"
					onButtonClick={async () => {
						onEdit();
					}}
				/>
			);
		}
	}

	return (
		<OrgCardTemplate
			content={
				<>
					<div id={styles.error}>{showErrorIfNeeded()}</div>
					<div id={styles.orgNameText}>{props.orgInfo.savedOrg.name}</div>
					<div id={styles.innText}>ИНН {props.orgInfo.savedOrg.inn}</div>
					<div id={styles.pathBlock}>
						<Folder id={styles.pathIcon} />
						<div id={styles.pathText}>{props.orgInfo.directoryPath}</div>
					</div>
				</>
			}
			checked={props.orgInfo.isEnabled}
			highlight={highlight()}
			error={error()}
			onEdit={onEdit}
			onDelete={onDelete}
			onToggle={async e => await onToggle(e.target.checked)}
		/>
	);
}

export const OrgCard = observer(OrgCardElement);
