import { MouseEventHandler } from 'react';
import styles from './settings-not-saved-modal-card.module.scss';
import { observer } from 'mobx-react-lite';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';

export interface SettingsNotSavedModalCardProps {
	onClickCrossButton: MouseEventHandler<HTMLButtonElement>;
}

function SettingsNotSavedModalCardElement(props: SettingsNotSavedModalCardProps) {
	return (
		<InfoModalCardTemplate
			title={'Настройки не были сохранены'}
			info={<>Успешно проверили соединение с новыми настройками, но не смогли сохранить</>}
			image={
				<img
					src={require('@assets/computer/icons/crossed-out-circle.png')}
					alt={'attention'}
				/>
			}
			onClickCrossButton={props.onClickCrossButton}
			innerWidth={362}
		/>
	);
}

export const SettingsNotSavedModalCard = observer(SettingsNotSavedModalCardElement);
