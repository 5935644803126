import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class Association {
	@jsonMember(() => Boolean, { name: 'associationExists' })
	public exists!: boolean;

	@jsonMember(() => Boolean, { name: 'associationCanChanged' })
	public canChanged: boolean = false;
}
