import { RefreshPageButton } from '@components/refresh-page-button';
import { CertsNotFoundCard } from '../certs-not-found-card';
import styles from './certs-not-found.module.scss';
import { OldCertsHint } from '../old-certs-hint';
import { NoCerts } from '@features/cert-list/models/states';

interface Props {
	loadCerts: () => void;
	info: NoCerts;
}

export const CertsNotFound = (props: Props) => {
	return (
		<div className={styles.notFound}>
			<CertsNotFoundCard />
			{props.info.showObsoleteCertsHint && (
				<div className={styles.hint}>
					<OldCertsHint text="Почему не видны сертификаты?" />
				</div>
			)}
			<div className={styles.buttonArea}>
				<RefreshPageButton customRefreshLogic={props.loadCerts} />
			</div>
		</div>
	);
};
