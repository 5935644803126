import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CleanFromCpRequestDto {
	@jsonMember(String)
	public setupURL!: string;

	@jsonMember(Boolean)
	public silent!: boolean;
}
