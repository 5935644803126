import { InputState } from '../enums/input-state';

export function checkPortValidity(value?: number): boolean {
	return value !== undefined && !isNaN(value) && value >= 1 && value <= 65535;
}

export function checkState<T>(value: T, validation: (value: T) => boolean) {
	if (!value) {
		return InputState.Empty;
	}
	if (!validation(value)) {
		return InputState.Wrong;
	}
	return InputState.Ok;
}

export function showError(state: InputState): boolean {
	switch (state) {
		case InputState.Ok:
			return false;
		default:
			return true;
	}
}
