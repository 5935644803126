import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { SerialInfo } from './serial-info';

@jsonObject
export class OrgInfo {
	@jsonMember(String, { name: 'shortName' })
	public shortName!: string;

	@jsonMember(String, { name: 'inn' })
	public inn!: string;

	@jsonMember(String, { name: 'kpp' })
	public kpp?: string;

	@jsonMember(String, { name: 'mailBox' })
	public mailBox!: string;

	@jsonMember(Boolean, { name: 'isPreferMail' })
	public isPreferedMail!: boolean;

	@jsonArrayMember(SerialInfo, { name: 'keys' })
	public keys!: SerialInfo[];
}
