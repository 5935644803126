import styles from './cert-installation-hint.module.scss';

export function CertInstallationHint() {
	return (
		<div>
			<div id={styles.mainTitle}>Устанавливаем сертификаты</div>
			<div id={styles.hint}>
				<div id={styles.title}>Для продолжения выполните следующие действия:</div>
				<ol>
					<li id={styles.taskbarTaLi}>
						Нажмите на иконку Такском-Агента в панели задач, чтобы увидеть дополнительное окно подтверждения
						установки сертификатов.
						<br />
						<img
							id={styles.taskbarTaImg}
							src={require('@assets/instructions/taskbar-ta.png')}
							alt={'TaskbarPressTa'}
						/>
					</li>
					<li>
						Дайте соглаcие на установку сертификатов. Для этого нажмите кнопку «Да» в окне подтверждения
						установки для каждого сертификата.
						<br />
						<img
							className={styles.shadow}
							src={require('@assets/instructions/message-box-warning-yes.png')}
							alt={'MessageBoxWarningPressYes'}
						/>
					</li>
				</ol>
			</div>
		</div>
	);
}
