import { jsonMember, jsonObject } from 'typedjson';
import { CryptoPro } from './crypto-pro';

@jsonObject
export class CheckCryptoResult {
	@jsonMember(CryptoPro)
	public cryptoPro!: CryptoPro;

	@jsonMember(Boolean)
	public isVipNet!: Boolean;
}
