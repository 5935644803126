import { notifyRed, notifyGreen } from '@components/notification';
import { equal, equalCaseInsensitive, indexOfCaseInsensitive } from '@helpers/operations-helper';
import { AuthData } from '@features/settings/proxy/models/api/auth-data';
import { makeAutoObservable } from 'mobx';
import {
	deleteProxySettings,
	getProxySettings,
	pingProxy,
	trySaveProxy,
} from '@features/settings/proxy/business-logic/api-provider';
import { checkPortValidity } from '../business-logic/validation';
import { protocols } from '../data/protocols';
import { addressRegex, loginRegex, passwordRegex } from '../data/regular-expressions';
import { PingStatus } from '../enums/ping-status';
import { ProxySettingsStore } from './proxy-settings-store';
import { restartAgent } from '@businessLogic/agent-restarter';
import logger from '@logger';
import { ProxySettingsDto } from '../models/api/proxy-settings-dto';
import { ModalState } from '../enums/modal-state';
import { PageImage } from '@components/page-template';

export class SaveStore {
	public savePermission!: boolean;
	public saveAvailable!: boolean;
	public deletePermission!: boolean;
	public savedProxySettings?: ProxySettingsDto;
	private proxySettingsStore: ProxySettingsStore;
	private pingAbortController!: AbortController;

	public constructor(proxySettingsStore: ProxySettingsStore) {
		this.reset();
		this.proxySettingsStore = proxySettingsStore;
		makeAutoObservable(this, {}, { autoBind: true });
	}

	reset() {
		this.savePermission = true;
		this.deletePermission = true;
		this.savedProxySettings = undefined;
		this.saveAvailable = false;
		this.pingAbortController = new AbortController();
	}

	setSavePermission(permission: boolean) {
		try {
			this.savePermission = permission;
		} finally {
			this.updateSaveAvailableState();
		}
	}

	setResetPermission(permission: boolean) {
		this.deletePermission = permission;
	}

	setAllPermissions(permission: boolean) {
		this.setSavePermission(permission);
		this.deletePermission = permission;
	}

	async loadSettings() {
		try {
			logger.info('Начата загрузка настроек');
			let proxySettings = await getProxySettings();
			this.savedProxySettings = proxySettings;
			if (proxySettings?.proto !== undefined) {
				let index = indexOfCaseInsensitive(protocols, proxySettings.proto);
				if (index === -1) {
					throw new Error(
						`При загрузке настроек прокси получено невалидное значение перечисления протоколов, равное [${proxySettings.proto}]`,
					);
				}
				this.proxySettingsStore.setProtocolIndex(index);
			}
			this.proxySettingsStore.setAddress(proxySettings?.address ?? '');
			this.proxySettingsStore.setPort(proxySettings?.port);

			this.proxySettingsStore.setLogin(proxySettings?.authData?.login ?? '');
			this.proxySettingsStore.setPassword(proxySettings?.authData?.password ?? '');
			if (proxySettings?.authData) {
				this.proxySettingsStore.setAuthenticationState(true);
			}
			logger.info('Закончена загрузка настроек');
		} catch (error) {
			logger.error(`Во время загрузки настроек что-то пошло не так: [${error}]`);
			this.proxySettingsStore.setModalState(ModalState.SomethingWentWrong);
		}
	}

	async deleteSettings() {
		try {
			logger.info('Начато удаление настроек');
			this.proxySettingsStore.setModalState(ModalState.TaRestart);
			await deleteProxySettings();
			await this._restartTA();
			this.proxySettingsStore.reset(false);
			this.proxySettingsStore.setPageImage(PageImage.Gear);
			await this.loadSettings();
			notifyRed('Прокси выключен');
			logger.info('Закончено удаление настроек');
		} catch (error) {
			logger.error(`Во время удаления настроек что-то пошло не так: [${error}]`);
			this.proxySettingsStore.setModalState(ModalState.SomethingWentWrong);
		}
	}

	async saveSettings() {
		try {
			logger.info('Начато сохранение настроек');
			this.setAllPermissions(false);
			this.proxySettingsStore.setPageImage(PageImage.Load);

			let newProxySettings = this.createSettingsFromInputs();

			this.pingAbortController = new AbortController();
			this.proxySettingsStore.setPingStatus(PingStatus.Checking);
			this.proxySettingsStore.setPingStatus(await pingProxy(newProxySettings, this.pingAbortController.signal));
			if (this.proxySettingsStore.pingStatus !== PingStatus.Ok) {
				this.proxySettingsStore.setPageImage(PageImage.Attention);
				return;
			}

			if (!(await trySaveProxy(newProxySettings))) {
				this.proxySettingsStore.setModalState(ModalState.SettingsNotSaved);
				return;
			}
			await this._restartTA();

			this.savedProxySettings = newProxySettings;
			this.proxySettingsStore.setPageImage(PageImage.Success);
			notifyGreen('Прокси настроен');
			logger.info('Закончено сохранение настроек');
		} catch (error) {
			logger.error(`Во время сохранения настроек что-то пошло не так: [${error}]`);
			this.proxySettingsStore.setModalState(ModalState.SomethingWentWrong);
		} finally {
			this.setAllPermissions(true);
		}
	}

	stopPing() {
		this.pingAbortController.abort();
		logger.info('Пингование прокси было остановлено');
	}

	updateSaveAvailableState() {
		this.saveAvailable = this.savePermission && this._anyNewValue && this._allValid;
	}

	get isDeleteAvailable(): boolean {
		return this.deletePermission && this.savedProxySettings !== undefined;
	}

	private async _restartTA() {
		this.proxySettingsStore.setModalState(ModalState.TaRestart);
		let resOfRestart = await restartAgent();
		if (!resOfRestart) {
			this.proxySettingsStore.setModalState(ModalState.SomethingWentWrong);
			return;
		}
		this.proxySettingsStore.setModalState(ModalState.None);
	}

	private get _allValid() {
		return (
			addressRegex.test(this.proxySettingsStore.address) &&
			checkPortValidity(this.proxySettingsStore.port) &&
			(!this._needAuth ||
				(loginRegex.test(this.proxySettingsStore.login) &&
					passwordRegex.test(this.proxySettingsStore.password)))
		);
	}

	private get _anyNewValue() {
		return (
			!equalCaseInsensitive(this.proxySettingsStore.address, this.savedProxySettings?.address) ||
			this.proxySettingsStore.port !== this.savedProxySettings?.port ||
			!this._authenticationStateSame ||
			(this._needAuth &&
				(!equal(this.proxySettingsStore.login, this.savedProxySettings?.authData?.login) ||
					!equal(this.proxySettingsStore.password, this.savedProxySettings?.authData?.password))) ||
			!equalCaseInsensitive(protocols[this.proxySettingsStore.protocolIndex], this.savedProxySettings?.proto)
		);
	}

	private get _authenticationStateSame() {
		if (this.savedProxySettings === undefined) {
			return false;
		}
		let savedAuthDataUsed = this.savedProxySettings.authData !== undefined;
		return savedAuthDataUsed === this._needAuth;
	}

	private get _needAuth(): boolean {
		return this.proxySettingsStore.authenticationAllowed && this.proxySettingsStore.authenticationEnabled;
	}

	private createSettingsFromInputs(): ProxySettingsDto {
		let newProxySettings = new ProxySettingsDto();
		newProxySettings.proto = protocols[this.proxySettingsStore.protocolIndex];
		newProxySettings.address = this.proxySettingsStore.address;
		newProxySettings.port = this.proxySettingsStore.port!;

		if (this._needAuth) {
			newProxySettings.authData = new AuthData();
			newProxySettings.authData.login = this.proxySettingsStore.login;
			newProxySettings.authData.password = this.proxySettingsStore.password;
		}

		return newProxySettings;
	}
}
