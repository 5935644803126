import { MouseEventHandler } from 'react';
import styles from './delete-organization-modal-card.module.scss';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { ModalState } from '@features/settings/auto-import/enums/modal-state';
import { removeOrg } from '@features/settings/auto-import/business-logic/api-provider';
import logger from '@logger';
import { notifyRed } from '@components/notification';
import { observer } from 'mobx-react-lite';

import { OrgCutInfo } from '@features/settings/auto-import/models/org-cut-info';
import { DeleteOrganizationModalCardTemplate } from '@features/settings/common/components/modal-cards/delete-organization-modal-card-template';

export interface DeleteOrganizationModalCardProps {
	info: OrgCutInfo;
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
	onClickCancelButton?: MouseEventHandler<HTMLButtonElement>;
}

function DeleteOrganizationModalCardElement(props: DeleteOrganizationModalCardProps) {
	async function onClickDelete() {
		logger.info('Удаляем организацию с id ' + autoImportStore.agentOrgIdToModal);
		await removeOrg(autoImportStore.agentOrgIdToModal);
		autoImportStore.removeOrg(autoImportStore.agentOrgIdToModal);
		autoImportStore.setModal(ModalState.None);
		notifyRed('Организация удалена');
	}

	return (
		<DeleteOrganizationModalCardTemplate
			title="Удалить настройки?"
			description={
				<>
					Подтвердите удаление настроек автозагрузки файлов для организации
					<br />
					<span
						className={styles.orgInfo}
						title={props.info.tooltipOrgName}
					>
						{props.info.presentOrgName}
					</span>
					.
				</>
			}
			exclamation="После удаления настроек, файлы из выбранной папки на компьютере не&nbsp;будут автоматически загружаться в Онлайн-Спринтер. Сама папка на&nbsp;компьютере останется без изменений."
			onClickCrossButton={props.onClickCrossButton}
			onClickCancelButton={props.onClickCancelButton}
			onClickDelete={onClickDelete}
		/>
	);
}

export const DeleteOrganizationModalCard = observer(DeleteOrganizationModalCardElement);
