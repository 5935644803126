import logger from '@logger';
import { generateLinkMakeAuth, getAuthResult } from '@businessLogic/api/oauth/oauth-adapter';
import { delay } from '@helpers/delay-helper';
import Cookies from 'js-cookie';
import { OauthResult } from '@models/oauth-result';
import { v4 as getUuid } from 'uuid';
import { popupCenter } from '@helpers/window-popup-helper';

export async function makeOauth(abortSignal?: AbortSignal): Promise<OauthResult> {
	let res = new OauthResult();
	let id = getUuid();
	res.id = id;
	try {
		let url = generateLinkMakeAuth(id);
		logger.info(`Сгенерирована ссылка для авторизации на ОС [${url}]`);
		let win = popupCenter(url, 'oauth', 1320, 720);

		let checkCookieResult = await checkCookie(id, win, abortSignal);
		if (!checkCookieResult) {
			return res;
		}

		let checkResult = await getAuthResult(id, abortSignal);
		if (checkResult === undefined) {
			return res;
		}
		res.result = checkResult!;
		res.success = true;
		return res;
	} catch (e: any) {
		if (e.name !== 'AbortError') {
			logger.error(`Ошибка при попытке авторизации \n${e}`);
		}
	}
	return res;
}

async function checkCookie(id: string, win: Window | null, abortSignal?: AbortSignal): Promise<boolean> {
	let cookie = `${process.env.REACT_APP_OAUTH_COOKIE_PREFIX!}${id}`.trimEnd();
	logger.info(`Получаем печеньки с ключом ${cookie}`);
	let tryCount = 0;
	let maxTryCount = Number(process.env.REACH_APP_OAUTH_RESULT_CHECK_TRY_COUNT);
	while (true) {
		if (abortSignal?.aborted) {
			win?.window.close();
			return false;
		}
		let cookieValue = Cookies.get(cookie);
		if (cookieValue !== undefined) {
			win?.window.close();
			logger.info(`Получили cookie с результатом авторизации [${cookieValue}]`);
			return JSON.parse(cookieValue);
		}
		if (win?.closed) {
			logger.info('Отмена авторизации!');
			console.log('тут');
			return false;
		}
		if (tryCount > maxTryCount) {
			win?.window.close();
			logger.info(
				`Не смогли получить статус за отведенные попытки ${maxTryCount} с ожиданием между попытками ${process.env.REACH_APP_OAUTH_RESULT_CHECK_TRY_DELAY}`,
			);
			return false;
		}
		tryCount = tryCount + 1;
		await delay(Number(process.env.REACH_APP_OAUTH_RESULT_CHECK_TRY_DELAY));
	}
}
