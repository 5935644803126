import { RefObject, useState } from 'react';
import { useResizeObserver } from './resize-observer';

export function useHeightController(ref: RefObject<HTMLDivElement>, fixHeight: boolean) {
	const [heightInner, setHeightInner] = useState<string>();
	const styleHeight = fixHeight ? heightInner : '100%';

	useResizeObserver(ref, element => {
		if (!fixHeight) {
			setHeightInner(`${element.scrollHeight}px`);
		}
	});

	return styleHeight;
}
