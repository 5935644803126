export enum PageImage {
	Attention,
	CrossedOutCircle,
	Cryptography,
	Error,
	Folder,
	Gear,
	Load,
	Magnifier,
	Success,
	Warning,
	Settings,
	Cert,
	CertError,
}
