import { InstructionLink } from '@components/instruction-link';
import { StageInfoLinkContent } from '@features/setup-wizard/models/stages/infos';
import styles from './failed-to-install-info.module.scss';

export function FailedToInstallInfo(props: StageInfoLinkContent) {
	return (
		<div className={styles.dropDownContent}>
			{props.textBeforeLink ?? ''}
			{props.link !== undefined ? (
				<InstructionLink
					link={props.link!}
					text={props.textOfLink ?? ''}
				/>
			) : (
				''
			)}
			{props.textAfterLink ?? ''}
		</div>
	);
}
