import { ErrorCard } from '@components/error-card/error-card';
import { RefreshPageButton } from '@components/refresh-page-button';
import { ReactElement } from 'react';
import styles from './error-card-with-refresh-page.module.scss';

interface Props {
	title: string;
	content: ReactElement[] | ReactElement;
	customRefreshLogic?: () => void;
}

// TODO SMA возможно вообще убрать этот компонент
// TODO SMA или посмотреть использования и переименовать их, ибо там многое сейчас называется как карточки, а по факту помимо карточки еще и кнопка
export function ErrorCardWithRefreshPage(props: Props) {
	return (
		<div>
			<ErrorCard {...props} />
			<div className={styles.buttonArea}>
				<RefreshPageButton {...props} />
			</div>
		</div>
	);
}
