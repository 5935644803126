import { UpdateCpMinorVersionErrorType } from '@features/update-cp-minor-version/enums/update-cp-minor-version-error-type';
import { makeAutoObservable } from 'mobx';
import { PageType } from '../enums/page-type';
import { preliminaryChecks, updateCpMinorVersionError } from '../models/pages';

class Store {
	public page: PageType = preliminaryChecks();

	public constructor() {
		makeAutoObservable(this);
	}

	setPage(page: PageType) {
		this.page = page;
	}

	setUpdateCpMinorVersionErrorPage(type: UpdateCpMinorVersionErrorType) {
		this.page = updateCpMinorVersionError(type);
	}
}

const store = new Store();

export { store };
