import ContentLoader from 'react-content-loader';

export function CertListSkeleton() {
	return (
		<ContentLoader
			speed={4}
			viewBox="0 0 600 68"
			backgroundColor="#F2F5FA"
			foregroundColor="#f7f9fc"
			title={'Загрузка...'}
		>
			<rect
				x="18"
				y="12"
				rx="100"
				ry="100"
				width="20"
				height="20"
			/>
			<rect
				x="48"
				y="15"
				rx="8"
				ry="8"
				width="530"
				height="14"
			/>
			<rect
				x="48"
				y="41"
				rx="8"
				ry="8"
				width="272"
				height="14"
			/>
		</ContentLoader>
	);
}
