import { RefObject, useEffect } from 'react';

// TODO почитать и разобраться подробнее как работает
export function useOutsideClick(ref: RefObject<HTMLElement>, callback: Function) {
	useEffect(() => {
		function handleClickOutside(e: MouseEvent) {
			if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
				callback(e);
			}
		}

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref]);
}
