import { showCertNotAttachedToOrgModal } from '@features/cert-list/components/modals/cert-not-attached-to-org-modal';
import { showFailedVerifyCertModal } from '@features/cert-list/components/modals/failed-verify-cert-modal';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { showSomethingWentWrongModal } from '@features/settings/auto-sign/common/modals/something-went-wrong-modal';
import { setupSucceeded } from '@features/setup-wizard/models/pages';
import { store } from '@features/setup-wizard/stores/store';
import { getFromAgentHttps, postModelToAgentHttps } from '@helpers/fetch-helper';
import { deserializeJsonAsArray } from '@helpers/json-helper';
import logger from '@logger';
import { Cert } from '@models/api/cert';
import { showFailedCp5ActivationModal } from '../components/modals/failed-cp5-activation';
import { showSerialNotAttachedToCertModal } from '../components/modals/serial-not-attached-to-cert-modal';
import { ActivateCpDto } from '../models/activate-cp-dto';
import { OrgInfo } from '../models/org-info';
import { chooseCertModalStore } from './choose-cert-modal-store';

export async function fetchOrgs(cert: Cert, onSuccess: (orgs: OrgInfo[]) => Promise<void> | void) {
	try {
		const response = await getFromAgentHttps(
			`${process.env.REACT_APP_AGENT_EP_STI_LICENSE_CP5!}/${cert.certThumbprint}`,
		);

		if (!response.ok) {
			logger.error(`Вернулся неудачный код(${response.status}) при попытке получения абонента!`);
			switch (response.status) {
				case 400:
					showFailedVerifyCertModal({ modalStore: chooseCertModalStore });
					return;
				case 404:
					showCertNotAttachedToOrgModal({ modalStore: chooseCertModalStore });
					return;
				default:
					showSomethingWentWrongModal({ modalStore: chooseCertModalStore });
					return;
			}
		}

		if (response.status === 204) {
			showSerialNotAttachedToCertModal({ modalStore: chooseCertModalStore });
			return;
		}

		const responseData = deserializeJsonAsArray(OrgInfo, await response.text());
		if (!responseData || responseData.length === 0) {
			logger.error('Получена пустая модель данных при десериализации ответа метода получения абонента!');
			showSerialNotAttachedToCertModal({ modalStore: chooseCertModalStore });
			return;
		}
		await onSuccess(responseData);
	} catch (error) {
		logger.error(`При попытке обработать сертификат возникла ошибка: ${error}`);
		showSomethingWentWrongModal({ modalStore: chooseCertModalStore });
		return;
	}
}

// TODO SMA не логичней ли здесь использовать ключ из cpLicenseStore?
export async function activateCp(serial: string) {
	const activateCpDto = new ActivateCpDto();
	activateCpDto.serial = serial;

	const response = await postModelToAgentHttps(
		process.env.REACT_APP_AGENT_EP_ACTIVATE_CP!,
		ActivateCpDto,
		activateCpDto,
	);
	if (!response.ok) {
		cpLicenseStore.setNotificationStatus(false);
		showFailedCp5ActivationModal({
			modalStore: chooseCertModalStore,
			serial,
			onClose: () => store.setPage(setupSucceeded()),
		});
		return;
	}
	store.setPage(setupSucceeded());
}
