import { commonInfo } from '@businessLogic/common-info';
import { showActivateLicenseSerialModal } from '@features/cp-license/components/modals/activate-license-serial-modal';
import { showCheckingCertModal } from '@features/cert-list/components/modals/checking-cert-modal';
import { showChooseOrgModal, Org } from '@features/cert-list/components/modals/choose-org-modal';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { Cert } from '@models/api/cert';
import { SerialInfo } from '../models/serial-info';
import { activateCp, fetchOrgs } from './api';
import { chooseCertModalStore } from './choose-cert-modal-store';
import { ChooseCertStatus } from '../enums/choose-cert-status';
import { showChooseLicenseSerialModal } from '../components/modals/choose-license-serial-modal';
import { showNoServerLicenseModal } from '../components/modals/no-server-license-modal';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';

function handleSerial(serial: SerialInfo, status: ChooseCertStatus) {
	showActivateLicenseSerialModal({
		modalStore: chooseCertModalStore,
		licenseSerial: serial,
		onActivate: async serial => {
			await activateCpCommon(serial.serial, status);
		},
	});
}

async function activateCpCommon(serialNumber: string, status: ChooseCertStatus) {
	cpLicenseStore.setNotificationStatus(true);
	cpLicenseStore.setLicenseKey(serialNumber);
	status === ChooseCertStatus.UpdateCp ? store.setPage(cpStore.cpSetupPage) : await activateCp(serialNumber);
}

function handleSerials(serials: SerialInfo[], status: ChooseCertStatus) {
	if (serials.length === 1) {
		handleSerial(serials[0], status);
		return;
	}
	showChooseLicenseSerialModal({
		modalStore: chooseCertModalStore,
		licenseSerials: serials,
		onChoose: serial => handleSerial(serial, status),
	});
}

export async function handleCert(cert: Cert, status: ChooseCertStatus) {
	showCheckingCertModal({ modalStore: chooseCertModalStore });
	await fetchOrgs(cert, async orgs => {
		if ((await commonInfo.getSystemInfo()).isServer) {
			orgs.forEach(org => (org.keys = org.keys.filter(key => key.isServer)));
			orgs = orgs.filter(org => org.keys.length > 0);
		}

		if (orgs.length === 0) {
			showNoServerLicenseModal({ modalStore: chooseCertModalStore });
			return;
		}

		if (orgs.length === 1) {
			handleSerials(orgs[0].keys, status);
			return;
		}

		showChooseOrgModal({
			modalStore: chooseCertModalStore,
			orgs: orgs.map(org => ({ name: org.shortName, inn: org.inn, kpp: org.kpp, login: org.mailBox })),
			onChoose: (org: Org) => {
				handleSerials(orgs.find(q => q.inn === org.inn && q.kpp === org.kpp)!.keys, status);
			},
		});
	});
}
