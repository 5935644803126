import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import styles from './no-access-to-directory-modal-card.module.scss';
import { MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

export interface NotAccessToDirectoryModalCardProps {
	pathToDirectory: string;
	commonButtonEvent: MouseEventHandler<HTMLButtonElement>;
	newSelectEvent: MouseEventHandler<HTMLButtonElement>;
}

function NoAccessToDirectoryModalCardElement(props: NotAccessToDirectoryModalCardProps) {
	return (
		<ActionModalCardTemplate
			title="Нет доступа к выбранной папке"
			body={
				<div id={styles.mainText}>
					Смените папку <span id={styles.path}>«{props.pathToDirectory}»</span> на другую
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.newSelectEvent}
				>
					Выбрать другую папку
				</button>,
			]}
			onClickCrossButton={props.commonButtonEvent}
		/>
	);
}

export const NoAccessToDirectoryModalCard = observer(NoAccessToDirectoryModalCardElement);
