import { isMobile } from 'react-device-detect';
import ContentLoader from 'react-content-loader';
import styles from './loader.module.scss';

// TODO SMA переделать, чтобы был гибким, а не как сейчас. по аналогии с скелетонами для настройки прокси и автоимпорта
export function Loader() {
	return (
		<div className={isMobile ? styles.loaderMobile : styles.loader}>
			<ContentLoader
				speed={4}
				width={333}
				height={isMobile ? 176 : 260}
				viewBox={isMobile ? '0 0 333 176' : '0 0 333 260'}
				backgroundColor="#EAECF0"
				foregroundColor="#F2F4F7"
				title={'Загрузка...'}
			>
				<rect
					x="0"
					y="0"
					rx="0"
					ry="0"
					width="0"
					height="0"
				/>
				<rect
					x="0"
					y="0"
					rx="6"
					ry="6"
					width="282"
					height="16"
				/>
				<rect
					x="0"
					y={isMobile ? 40 : 61}
					rx="6"
					ry="6"
					width="317"
					height="16"
				/>
				<rect
					x="0"
					y={isMobile ? 80 : 122}
					rx="6"
					ry="6"
					width="295"
					height="16"
				/>
				<rect
					x="0"
					y={isMobile ? 120 : 183}
					rx="6"
					ry="6"
					width="333"
					height="16"
				/>
				<rect
					x="0"
					y={isMobile ? 160 : 244}
					rx="6"
					ry="6"
					width="317"
					height="16"
				/>
			</ContentLoader>
		</div>
	);
}
