import { InstructionLink } from '@components/instruction-link';
import { ErrorCard } from '@components/error-card';

export function CertsNotFoundCard() {
	return (
		<ErrorCard
			title="Нет сертификатов"
			content={[
				<div>Убедитесь, что к компьютеру подключён нужный токен.</div>,
				<div>
					Перейдите на 
					<InstructionLink
						link={process.env.REACT_APP_INSTR_CERTS_NOT_FOUND!}
						text="страницу"
					/>{' '}
					для получения рекомендаций по устранению ошибки, если она сохраняется.
				</div>,
			]}
		/>
	);
}
