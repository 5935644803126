import { jsonMember, jsonObject } from 'typedjson';
import { AuthData } from './auth-data';

@jsonObject
export class ProxySettingsDto {
	@jsonMember(() => String)
	proto?: string;

	@jsonMember(() => String)
	address?: string;

	@jsonMember(() => Number)
	port?: number;

	@jsonMember(() => AuthData)
	authData?: AuthData;
}
