import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import styles from './enter-serial-radio.module.scss';

export function EnterSerialRadio(props: { serialNumber: string; setSerialNumber: (serialNumber: string) => void }) {
	const serialValidity = /^[a-zA-Z\d]*$/.test(props.serialNumber);
	const isCp4SerialNumber = props.serialNumber.startsWith('4040');

	function normalizeSerialNumber(value: string): string {
		let serialNumber = value.replace(/[-\s]/g, '').substring(0, 25);
		props.setSerialNumber(serialNumber);
		return serialNumber.match(/.{1,5}/g)?.join('-') ?? '';
	}

	return (
		<div className={styles.inputBlock}>
			<input
				id={styles.inputLicense}
				autoFocus={true}
				placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
				className={!serialValidity ? styles.wrongInputCustom1 : styles.inputCustom1}
				onChange={event => {
					event.target.value = normalizeSerialNumber(event.target.value);
				}}
			/>
			{!serialValidity ? (
				<div className={styles.inputErrorHint}>Некорректный серийный номер</div>
			) : isCp4SerialNumber ? (
				<div className={styles.warningContainer}>
					<PromptBlock
						type={PromptBlockType.Warning}
						text={
							'Нажмите "Продолжить", если у вас годовая лицензия. ' +
							'Серийный номер для активации бессрочной лицензии КриптоПро CSP 5 начинается на "5050..."'
						}
					/>
				</div>
			) : null}
		</div>
	);
}
