import styles from './choose-org-modal.module.scss';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';
import { ModalStore } from '../../../../../stores/modal-store';
import { Org } from './models/org';

export interface Props {
	modalStore: ModalStore;
	orgs: Org[];
	onChoose: (org: Org) => void;
}

// TODO SMA Здесь пока не получилось нормального сжатия на маленьком экране
function ChooseOrgModal(props: Props) {
	function renderInnKppText(org: Org) {
		if (org.kpp) {
			return `ИНН/КПП ${org.inn} / ${org.kpp}`;
		}
		return `ИНН ${org.inn}`;
	}

	return (
		<ActionModalCardTemplate
			title="Выберите организацию"
			body={
				<div
					id={styles.body}
					className={styles.scrollbarWithoutBorder}
				>
					{props.orgs.map(org => (
						<div
							id={styles.org}
							key={org.kpp ? `${org.inn} ${org.kpp}` : org.inn}
							onClick={() => props.onChoose(org)}
						>
							<div id={styles.name}>{org.name}</div>
							<div id={styles.inn}>{renderInnKppText(org)}</div>
							<div id={styles.mail}>{org.login}</div>
						</div>
					))}
				</div>
			}
			footerButtons={[]}
			onClickCrossButton={props.modalStore.hide}
		/>
	);
}

export const showChooseOrgModal = (props: Props) => {
	props.modalStore.show({
		children: <ChooseOrgModal {...props} />,
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
