import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { installCryptoProvider } from '@features/setup-wizard/business-logic/api/crypto/crypto';
import { CryptoInstallResult } from '@features/setup-wizard/enums/crypto-install-result';
import { cpNoActualLicense } from '@features/setup-wizard/models/pages';
import { store } from '@features/setup-wizard/stores/store';
import logger from '@logger';
import { UpdateCpMinorVersionErrorType } from '../enums/update-cp-minor-version-error-type';
import { deleteKeyFromCache, getKeyFromCache } from './ta-cache-logic';

// TODO SMA подумать, не логичней ли это делать на этапе PreliminaryChecks
async function extractCpKeyFromCache(): Promise<void> {
	let key: string | null = null;
	try {
		key = await getKeyFromCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!);
	} catch (error) {
		logger.error(`Ошибка при попытке полученния ключа из кэша: ${error}!`);
	}
	cpLicenseStore.setLicenseKey(key);
}

async function installCp(): Promise<void> {
	const installationResult = await installCryptoProvider();
	if (installationResult === CryptoInstallResult.NotNormalLicenseInstallDemo) {
		await deleteCpKeyFromCache();
		store.setPage(cpNoActualLicense());
		return;
	}
	if (installationResult === CryptoInstallResult.AccessDenied) {
		store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.NotEnoughRights);
		return;
	}
	if (
		installationResult !== CryptoInstallResult.Success &&
		installationResult !== CryptoInstallResult.CryptoAlreadyInstall
	) {
		store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.CommonFailure);
		return;
	}
	await deleteCpKeyFromCache();
}

async function deleteCpKeyFromCache(): Promise<void> {
	await deleteKeyFromCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!);
}

export async function executeInstallNewCpStage() {
	if (!cpLicenseStore.licenseKey) {
		await extractCpKeyFromCache();
	}
	await installCp();
}
