export function pathToDesign(path: string): string {
	let res = '';
	let elementsOfPath;
	let slash = '/';
	if (path.includes('/')) {
		elementsOfPath = path.split('/');
		if (elementsOfPath[0] === '') {
			elementsOfPath.shift();
		}
		res = '/...';
	} else {
		slash = '\\';
		elementsOfPath = path.split('\\');
		res = `${elementsOfPath[0]}\\...`;
		elementsOfPath.shift();
	}

	if (elementsOfPath.length <= 3) {
		return path;
	}
	elementsOfPath.splice(elementsOfPath.length - 2).forEach(elem => {
		res = res + `${slash}${elem}`;
	});
	return res;
}
