import { makeAutoObservable, runInAction } from 'mobx';
import {
	checkOrgNotExists,
	makeReAuth,
	receiveOrgList,
	selectDirectory,
} from '@features/settings/auto-import/business-logic/api-provider';
import { isNullOrEmpty } from '@helpers/string-helper';
import { OauthResult } from '@models/oauth-result';
import { ModalState } from '@features/settings/auto-import/enums/modal-state';
import { pathToDesign } from '@features/settings/auto-import/business-logic/path-operator';
import { PageStatus } from '@features/settings/common/enums/page-body-status';
import { prepareSettingsPage } from '@features/settings/common/helpers/prepare-settings-page';
import { cutOrgName } from '@features/settings/auto-import/business-logic/support';
import { OrgCutInfo } from '@features/settings/auto-import/models/org-cut-info';
import logger from '@logger';
import Logger from '@logger';
import { makeOauth } from '@businessLogic/api/oauth/oauth-client';
import { notifyGreen, notifyRed, RedNotificationIcon } from '@components/notification';
import { storeSettings } from '@features/settings/common/stores/store-settings';
import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import { revokeToken } from '@businessLogic/api/oauth/oauth-adapter';
import { OrgInfo } from '../models/api/org-info';
import { ReAuthRequest } from '../models/api/re-auth-request';
import { PageImage } from '@components/page-template';

export class AutoImportStore {
	public newOrgData: OauthResult | null = null;
	public isNewOrg: boolean = false;
	public editedOrgInfo: OrgInfo | null = null;
	public selectedDirectoryPath: string | null = null;

	public maxSizeOfOrgList: number = 0;
	public orgList: OrgInfo[];

	public searchText: string = '';

	public pageImage: PageImage = PageImage.Load;

	public modalState: ModalState = ModalState.None;
	public orgNameToModal: OrgCutInfo | null = null;
	public agentOrgIdToModal: string = '';
	public pathToDirectoryModal: string = '';

	public selectedOrgId?: string;
	public highlightSelectedOrg: boolean = false;

	public reAuthOrgId: string = '';

	public isAllOrgDisabled: boolean = false;

	public pageStatus: PageStatus = PageStatus.Skeleton;

	private oauthAbortController: AbortController = new AbortController();

	public constructor() {
		makeAutoObservable(this, {}, { autoBind: true });
		this.orgList = [];

		document.addEventListener('mouseup', () => {
			this.setSelectedOrgHighlight(false);
		});
	}

	/*Все что касается отображения*/
	setSearchText(searchText: string) {
		this.searchText = searchText;
	}

	refreshAll() {
		this.refreshPageImage();
		this.refreshIsAllOrgDisabled();
	}

	refreshPageImage() {
		if (!isNullOrEmpty(this.searchText)) {
			return;
		}

		if (this.orgList.length === 0) {
			this.pageImage = PageImage.Gear;
			return;
		}

		let anyBad = this.orgList.find(q => q.isExpired || q.isDirectoryBad);
		if (anyBad !== undefined) {
			this.pageImage = PageImage.Attention;
		} else {
			this.pageImage = PageImage.Success;
		}
	}

	refreshIsAllOrgDisabled() {
		let enabledOrg = this.orgList.find(q => q.isEnabled && !q.isExpired && !q.isDirectoryBad);
		this.isAllOrgDisabled = enabledOrg === undefined;
	}

	setPageImage(newImage: PageImage) {
		this.pageImage = newImage;
	}

	setPageStatus(status: PageStatus) {
		this.pageStatus = status;
	}

	/*Все что касается модалок*/
	setModal(newModal: ModalState) {
		if (newModal === ModalState.None) {
			this.orgNameToModal = null;
			this.agentOrgIdToModal = '';
		}
		this.modalState = newModal;
	}

	setOrgNameToModal(name: string) {
		this.orgNameToModal = cutOrgName(name);
	}

	setAgentOrgIdToModal(orgAgentId: string) {
		this.agentOrgIdToModal = orgAgentId;
	}

	setPathToDirectoryToModal(path: string) {
		this.pathToDirectoryModal = pathToDesign(path);
	}

	selectOrg(id: string) {
		this.selectedOrgId = id;
	}

	setSelectedOrgHighlight(highlight: boolean) {
		this.highlightSelectedOrg = highlight;
	}

	/*Прочее*/
	setReAuthOrgId(orgId: string) {
		this.reAuthOrgId = orgId;
	}

	setOauthInfo(newData: OauthResult) {
		this.newOrgData = newData;
		this.isNewOrg = true;
	}

	directorySelectorEnd() {
		this.isNewOrg = false;
		this.newOrgData = null;
		this.editedOrgInfo = null;
		this.selectedDirectoryPath = null;
		this.pathToDirectoryModal = '';
	}

	async selectDirectory() {
		let res = await selectDirectory();
		if (res === undefined) {
			logger.info('Юзер отказался от выбора директории или произошла ошибка!');
			return;
		}
		this.selectedDirectoryPath = res;
		this.pathToDirectoryModal = pathToDesign(res);
		Logger.info('Юзер выбрал папку ' + res);
	}

	async makeOauth() {
		this.setModal(ModalState.EnterOs);
		this.oauthAbortController = new AbortController();
		let abortSignal = this.oauthAbortController.signal;
		let res = await makeOauth(abortSignal);
		if (!res.success) {
			this.setModal(ModalState.None);
			if (!abortSignal.aborted) {
				if (isNullOrEmpty(this.reAuthOrgId)) {
					logger.error('Не удалось авторизоваться!');
					notifyRed('Не удалось войти', RedNotificationIcon.Key);
				} else {
					logger.error('Не удалось авторизоваться при повторной авторизации!');
					this.setModal(ModalState.AuthError);
				}
			}
			return;
		}

		if (isNullOrEmpty(this.reAuthOrgId)) {
			await this.saveAuthResult(res);
		} else {
			await this.saveAuthResultReAuth(res);
		}
	}

	private async saveAuthResult(res: OauthResult) {
		logger.info('Получили успешный результат авторизации! Проверим что нет организации с тем же id у нас в БД!');
		let checkCurrentOrgExist = await checkOrgNotExists(res.result.orgData.abonentId);
		if (checkCurrentOrgExist) {
			logger.info('Дублирование организации с id ' + res.result.orgData.abonentId);
			await revokeToken(res.id!);
			autoImportStore.setOrgNameToModal(res.result.orgData.name);
			autoImportStore.selectOrg(res.result.orgData.abonentId);
			autoImportStore.setModal(ModalState.OrgDouble);
			return;
		}
		this.setModal(ModalState.None);
		autoImportStore.setOauthInfo(res);
		storeSettings.setPage(PagesSettings.DirectorySelector);
	}

	private async saveAuthResultReAuth(res: OauthResult) {
		try {
			let org = this.getOrgById(this.reAuthOrgId);
			if (org === undefined) {
				await revokeToken(res.id!);
				this.setModal(ModalState.SomethingWentWrong);
				return;
			}

			if (res.result.orgData.abonentId !== org.savedOrg.abonentId) {
				logger.warn(`Идентификатор организации [${res.result.orgData.abonentId}] не идентичен идентификатору 
                                организации по которой нажали авторизоваться [${org.savedOrg.abonentId}]`);
				this.setModal(ModalState.NotSameOrg);
				await revokeToken(res.id!);
				return;
			}

			let reAuthRequest = new ReAuthRequest();
			reAuthRequest.abonentId = res.result.orgData.abonentId;
			reAuthRequest.name = res.result.orgData.name;
			reAuthRequest.accessToken = res.result.accessToken;
			reAuthRequest.accessTokenExpTime = res.result.accessTokenExpTime;
			reAuthRequest.refreshToken = res.result.refreshToken;
			reAuthRequest.refreshTokenExpTime = res.result.refreshTokenExpTime;

			let resOfReAuth = await makeReAuth(this.reAuthOrgId, reAuthRequest);
			if (resOfReAuth.isServerError || resOfReAuth.isOrgNotFound) {
				this.setModal(ModalState.SomethingWentWrong);
				return;
			}
			if (resOfReAuth.isNoSameOrg) {
				this.setModal(ModalState.NotSameOrg);
				return;
			}
			await this.loadOrgs();
			this.setModal(ModalState.None);
			this.setReAuthOrgId('');
			notifyGreen('Настройки сохранены');
		} catch (e) {
			logger.error('Ошибка при попытке сохранить данные после пере авторизации!' + e);
			this.setModal(ModalState.SomethingWentWrong);
		}
	}

	stopOauth() {
		this.oauthAbortController.abort();
	}

	/*Все что касается работы с организациями*/
	startOrgEditing(value: OrgInfo) {
		this.isNewOrg = false;
		this.editedOrgInfo = value;
		this.selectedDirectoryPath = value.directoryPath;
		this.pathToDirectoryModal = pathToDesign(this.selectedDirectoryPath);

		storeSettings.setPage(PagesSettings.DirectorySelector);
	}

	startOrgEditingById(id: string) {
		const orgInfo = this.orgList.find(q => q.orgId === id);
		if (!orgInfo) {
			logger.warn(`Не была найдена организация с переданным id: [${id}]!`);
			return;
		}
		this.startOrgEditing(orgInfo);
	}

	appendOrgList(value: OrgInfo) {
		this.orgList.push(value);
		this.maxSizeOfOrgList = this.orgList.length;
		this.selectedDirectoryPath = '';
		this.pathToDirectoryModal = '';
		this.refreshAll();
	}

	async preparePage() {
		try {
			await prepareSettingsPage(
				async () => {
					await this.loadOrgs();
					this.setPageStatus(PageStatus.Ok);
				},
				() => {
					this.setPageImage(PageImage.Attention);
					this.setPageStatus(PageStatus.ConnectionLost);
				},
			);
		} catch (error) {
			console.error(`При подготовке страницы возникла ошибка: ${error}`);
			this.setModal(ModalState.SomethingWentWrong);
		}
	}

	async loadOrgs() {
		try {
			let res = await receiveOrgList(this.searchText);
			runInAction(() => {
				if (isNullOrEmpty(this.searchText)) {
					this.maxSizeOfOrgList = res.length;
				}
				this.orgList = res;
				this.refreshAll();
			});
		} catch (error) {
			console.error(`При загрузке организаций возникла ошибка: ${error}`);
			this.setModal(ModalState.SomethingWentWrong);
		}
	}

	removeOrg(orgId: string) {
		let orgInfo = this.orgList.find(q => q.orgId === orgId);
		if (orgInfo !== undefined) {
			let ind = this.orgList.indexOf(orgInfo, 0);
			if (ind !== undefined) {
				this.orgList.splice(ind, 1);
				if (isNullOrEmpty(this.searchText)) {
					this.maxSizeOfOrgList = this.orgList.length;
				}
			}
		}
		this.refreshAll();
	}

	setOrgList(orgList: OrgInfo[]) {
		this.orgList = orgList;
		this.refreshAll();
	}

	getOrgStatus(orgId: string) {
		let orgInfo = this.orgList.find(q => q.orgId === orgId);
		if (orgInfo !== undefined) {
			return orgInfo.isEnabled;
		}
	}

	setOrgStatus(id: string, enabled: boolean) {
		this.orgList = this.orgList.map(org =>
			org.orgId === id
				? {
						...org,
						isEnabled: enabled,
					}
				: org,
		);
		this.refreshAll();
	}

	private getOrgById(orgId: string): OrgInfo | undefined {
		return this.orgList.find(q => q.orgId === orgId);
	}
}

export const autoImportStore = new AutoImportStore();
