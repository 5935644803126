import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ExtendData {
	@jsonMember(Boolean)
	public isPerpetual!: boolean;

	@jsonMember(Date)
	public expDate?: Date;
}
