import styles from './refresh-page-button.module.scss';

interface Props {
	customRefreshLogic?: () => void;
}

export function RefreshPageButton(props: Props) {
	return (
		<button
			id={styles.button}
			className={styles.buttonBlue}
			onClick={() => {
				props.customRefreshLogic ? props.customRefreshLogic() : window.location.reload();
			}}
		>
			Обновить страницу
		</button>
	);
}
