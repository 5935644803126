export class DeserializeResponseJsonError extends Error {
	public response: Response;
	public typeOfTryDesObject: string;

	constructor(msg: string, response: Response, typeOf: string) {
		super(msg);
		this.response = response;
		this.typeOfTryDesObject = typeOf;
	}
}
