import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import { ModalStore } from '../../../../../stores/modal-store';

export interface Props {
	modalStore: ModalStore;
}

function FailedVerifyCertModal(props: Props) {
	return (
		<InfoModalCardTemplate
			title="Не удалось проверить сертификат"
			info="Убедитесь, что токен подключён к компьютеру"
			image={<Attention />}
			onClickCrossButton={props.modalStore.hide}
			centerCross
		/>
	);
}

export const showFailedVerifyCertModal = (props: Props) => {
	props.modalStore.show({
		children: <FailedVerifyCertModal {...props} />,
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
