import { jsonMember, jsonObject } from 'typedjson';
import { License } from './license';

@jsonObject
export class CryptographyInstallInfo {
	@jsonMember(() => String)
	public type!: string;

	@jsonMember(() => String)
	public setupURL!: string;

	@jsonMember(() => Boolean)
	public silentInstall!: boolean;

	@jsonMember(() => License)
	public license?: License;
}
