import ContentLoader from 'react-content-loader';
import styles from './cards-block-skeleton.module.scss';

export function CardBlockSkeleton() {
	return (
		<div id={styles.main}>
			<div id={styles.card}>
				<ContentLoader
					speed={4}
					viewBox="0 0 544 116"
					backgroundColor="#F2F5FA"
					foregroundColor="#F2F4F7"
					title={'Загрузка...'}
					id={styles.mainBlock}
				>
					<rect
						x="0"
						y="0"
						rx="8"
						ry="8"
						width="544"
						height="116"
					/>
				</ContentLoader>

				<ContentLoader
					speed={4}
					viewBox="0 0 544 116"
					backgroundColor="#A6B5C3"
					foregroundColor="#F2F4F7"
					title={'Загрузка...'}
					id={styles.addBlock}
				>
					<rect
						x="18"
						y="19"
						rx="10"
						ry="10"
						width="44"
						height="20"
					/>
					<rect
						x="80"
						y="22"
						rx="7"
						ry="7"
						width="440"
						height="14"
					/>
					<rect
						x="80"
						y="48"
						rx="7"
						ry="7"
						width="115"
						height="14"
					/>
					<rect
						x="108"
						y="84"
						rx="7"
						ry="7"
						width="300"
						height="14"
					/>
					<rect
						x="80"
						y="81"
						rx="10"
						ry="10"
						width="20"
						height="20"
					/>
				</ContentLoader>

				<ContentLoader
					speed={4}
					viewBox="0 0 544 116"
					backgroundColor="white"
					foregroundColor="white"
					title={'Загрузка...'}
					id={styles.roundBlock}
				>
					<rect
						x="23"
						y="24"
						rx="5"
						ry="5"
						width="10"
						height="10"
					/>
				</ContentLoader>
			</div>

			<ContentLoader
				speed={4}
				viewBox="0 0 544 56"
				backgroundColor="#F2F5FA"
				foregroundColor="#F2F4F7"
				title={'Загрузка...'}
				id={styles.buttons}
			>
				<rect
					x="0"
					y="0"
					rx="8"
					ry="8"
					width="544"
					height="56"
				/>
			</ContentLoader>
		</div>
	);
}
