import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import logger from '@logger';
import { StageInfo, StageInfoWithTextContent, StageInfoWithLinkContent } from './infos';
import { v4 as getUuid } from 'uuid';

export abstract class Stage {
	public readonly type: StageTypes;
	public readonly id: string;
	public needInstall: boolean;

	private readonly ignoreCheckUpErrors: boolean;

	protected constructor(type: StageTypes, ignoreCheckUpErrors: boolean = true) {
		this.type = type;
		this.ignoreCheckUpErrors = ignoreCheckUpErrors;
		this.id = getUuid();
		this.needInstall = true;
	}

	public abstract getCheckUpStageInfo(): StageInfo;

	public abstract getInstallStageInfo(): StageInfo;

	public abstract getSetupSettingsStageInfo(): StageInfoWithTextContent;

	public abstract getInstallErrorStageInfo(): StageInfoWithLinkContent;

	protected abstract checkUpLogic(): Promise<void>;

	protected abstract setupLogic(): Promise<void>;

	public async checkUp() {
		try {
			logger.info(`Начата проверка для ${StageTypes[this.type]}!`);
			this.needInstall = true;
			await this.checkUpLogic();
			logger.info(`Успешно закончена проверка для ${StageTypes[this.type]}!`);
		} catch (error) {
			logger.error(`Этап проверки для ${StageTypes[this.type]} завершился с ошибкой!`, error);
			if (!this.ignoreCheckUpErrors) throw error;
		}
	}

	public async setup() {
		try {
			logger.info(`Начата настройка для ${StageTypes[this.type]}!`);
			await this.setupLogic();
			logger.info(`Успешно закончена настройка для ${StageTypes[this.type]}!`);
		} catch (error) {
			logger.error(`Этап настройки для ${StageTypes[this.type]} завершился с ошибкой!`, error);
		}
	}
}
