export enum PagesSettings {
	NoConnection,
	NonActualVersion,

	// Все что касается /settings/autoimport
	AutoImportSettings,
	DirectorySelector,

	// Все что касается /settings/proxy
	ProxySettings,
}
