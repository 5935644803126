import styles from './title-with-arrow.module.scss';
import { ReactComponent as ReturnArrow } from '@assets/icons/return-arrow.svg';

export interface Props {
	title: string;
	subtitle: string;
	subtitleHint?: string;
	onArrowClick: () => void;
}

export function TitleWithArrow(props: Props) {
	return (
		<div className={styles.main}>
			<div className={styles.titleArea}>
				<ReturnArrow
					className={styles.arrowButton}
					onClick={() => props.onArrowClick()}
				/>
				<div id={styles.title}>{props.title}</div>
			</div>
			<div
				title={props.subtitleHint}
				className={styles.additional}
			>
				{props.subtitle}
			</div>
		</div>
	);
}
