import { ReactComponent as ExclamationIcon } from '@assets/icons/exclamation-circle-no-fill.svg';
import styles from './old-certs-hint.module.scss';
import { OldCertsTooltip } from '../old-certs-tooltip';
import { TooltipRefProps } from 'react-tooltip';
import { useRef } from 'react';

interface Props {
	text: string;
}

// TODO SMA подумать на досуге как можно сделать чтобы стрелка показывала на иконку и при этом работала ссылка
export const OldCertsHint = (props: Props) => {
	const tooltipRef = useRef<TooltipRefProps>(null);

	return (
		<div className={styles.hint}>
			<div
				className={styles.content}
				id={styles.exclamationIcon}
				// onMouseEnter={() => tooltipRef.current?.open({ anchorSelect: `.${styles.exclamationIcon}` })}
				// onMouseLeave={() => tooltipRef.current?.close()}
			>
				<ExclamationIcon className={styles.exclamationIcon} />
				<div className={styles.text}>{props.text}</div>
				<OldCertsTooltip
					anchorSelect={`.${styles.content}`}
					place="top-start"
				/>
			</div>
		</div>
	);
};
