import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class OrgData {
	@jsonMember(String)
	public abonentId!: string;

	@jsonMember(String)
	public name!: string;

	@jsonMember(String)
	public inn!: string;
}
