import styles from './new-org-button.module.scss';
import { ReactComponent as Plus } from '@assets/icons/plus.svg';
import { MouseEventHandler } from 'react';

interface Props {
	onClick: MouseEventHandler<HTMLButtonElement>;
	transparent: boolean;
}

export function NewOrgButton(props: Props) {
	function addTransparent(className: string) {
		return [className, props.transparent ? styles.transparent : null].join(' ');
	}

	return (
		<button
			className={props.transparent ? styles.buttonDashedBlue : styles.buttonBlue}
			onClick={props.onClick}
		>
			<div className={addTransparent(styles.buttonContent)}>
				<Plus className={addTransparent(styles.plusIcon)} />
				Добавить организацию
			</div>
		</button>
	);
}
