import { Page, PageImage } from '@components/page-template';
import styles from './no-access-to-restart.module.scss';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';

export function NoAccessToRestart() {
	return (
		<Page
			image={PageImage.Attention}
			child={
				<div>
					<div id={styles.title}>КриптоПро CSP 5 установлено</div>
					<div id={styles.supportText}>Для корректной работы программы требуется перезагрузка компьютера</div>
					<div id={styles.warnBlock}>
						<PromptBlock
							type={PromptBlockType.Warning}
							text={'Не хватает прав на перезагрузку. Обратитесь к Администратору'}
						/>
					</div>
				</div>
			}
		/>
	);
}
