import { RestartPCType } from '@enums/restart-pc-type';
import {
	Cleaning,
	Deletion,
	Installation,
	StageType,
} from '@features/update-cp-minor-version/enums/update-cp-minor-version-stage';
import { makeAutoObservable } from 'mobx';
import { stagesWorker } from '../business-logic/stages-worker';
import { CryptographyStage } from '../business-logic/stages/cryptography-stage';
import { CpLicenseEnterType } from '../enums/cp-license-enter-type';
import { CpSetupMode } from '../enums/cp-setup-mode';
import { PageType } from '../enums/page-type';
import { StageTypes } from '../enums/stage-types';
import { updateCpMinorVersionProcess, updateCpMinorVersionStart, installCp, setup } from '../models/pages';

class CpStore {
	cryptoArmInstalled: boolean = false;
	setupMode: CpSetupMode = CpSetupMode.Install;
	restartStatus: RestartPCType | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setRestartStatus(status: RestartPCType | null) {
		this.restartStatus = status;
	}

	setSetupMode(status: CpSetupMode) {
		this.setupMode = status;
	}

	setCryptoArmInstalled(installed: boolean) {
		this.cryptoArmInstalled = installed;
	}

	// TODO SMA вообще куда-нибудь в хелперы вынести наряду с методами из installCpStore
	enterLicenseWindowType() {
		let cryptoStage = stagesWorker.getIfType(StageTypes.Cryptography) as CryptographyStage;
		return cryptoStage?.enterLicenseWindowType ?? CpLicenseEnterType.Default;
	}

	get minorVersionStages(): StageType[] {
		if (this.cryptoArmInstalled) {
			return [new Deletion(), new Installation()];
		}
		return [new Deletion(), new Cleaning(), new Installation()];
	}

	get currentUpdateCpMinorVersionStage(): StageType | null {
		if (this.restartStatus === null) {
			return new Deletion();
		}
		if (this.restartStatus === RestartPCType.CspUninstall) {
			if (!this.cryptoArmInstalled) {
				return new Cleaning();
			}
			return new Installation();
		}
		if (this.restartStatus === RestartPCType.Cleaner && !this.cryptoArmInstalled) {
			return new Installation();
		}
		return null;
	}

	get cpSetupPage(): PageType {
		const needToInstallStage = stagesWorker.getNeededToInstall();
		const crypto = needToInstallStage.find(q => q.type === StageTypes.Cryptography);

		if (!crypto && cpStore.setupMode === CpSetupMode.UpdateMinorVersion) {
			return updateCpMinorVersionProcess();
		}

		if (needToInstallStage.length === 1 && crypto) {
			if (cpStore.setupMode === CpSetupMode.UpdateMinorVersion) {
				return updateCpMinorVersionStart();
			}
			return installCp();
		}
		return setup();
	}
}

export const cpStore = new CpStore();
