import styles from './no-connection-terminal.module.scss';
import { useState } from 'react';
import { installCert as installCertApi } from '@businessLogic/api/install-cert';
import { InstructionLink } from '@components/instruction-link';
import { ButtonWithSpinner } from '@components/button-with-spinner/button-with-spinner';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { Page, PageImage } from '@components/page-template';
import { CertInstallationResult } from '@features/setup-wizard/enums/cert-installation-result';

enum CertInstallationStatus {
	Idle,
	Installing,
	NeedRestartBrowser,
	Failure,
}

export function NoConnectionTerminal() {
	const [certInstallationStatus, setCertInstallationStatus] = useState(CertInstallationStatus.Idle);

	async function installCert() {
		setCertInstallationStatus(CertInstallationStatus.Installing);
		const result = await installCertApi();
		switch (result) {
			case CertInstallationResult.Success:
				window.location.reload();
				break;
			case CertInstallationResult.Failure:
				setCertInstallationStatus(CertInstallationStatus.Failure);
				break;
			case CertInstallationResult.NeedRestartBrowser:
				setCertInstallationStatus(CertInstallationStatus.NeedRestartBrowser);
				break;
		}
	}

	function ActionZone() {
		switch (certInstallationStatus) {
			case CertInstallationStatus.NeedRestartBrowser:
				return (
					<PromptBlock
						title="Сертификат установлен"
						text="Перезапустите браузер"
						type={PromptBlockType.Message}
					/>
				);
			case CertInstallationStatus.Failure:
				return (
					<PromptBlock
						title="Не удалось установить сертификат"
						text={
							<span>
								Воспользуйтесь{' '}
								<InstructionLink
									link={process.env.REACT_APP_INSTR_INSTALL_CERT_TERMINAL!}
									text={'инструкцией'}
								/>
							</span>
						}
						type={PromptBlockType.Error}
					/>
				);
			default:
				return (
					<ButtonWithSpinner
						defaultText="Установить сертификат"
						onClick={installCert}
						loading={certInstallationStatus === CertInstallationStatus.Installing}
					/>
				);
		}
	}

	return (
		<Page
			image={PageImage.Error}
			child={
				<>
					<div id={styles.title}>Нет связи с терминальным&nbsp;Такском-Агентом</div>
					<div id={styles.subtitle}>
						Отсутствует сертификат для установки безопасного соединения с терминальным Такском-Агентом
					</div>
					<div className={styles.btnArea}>
						<ActionZone />
					</div>
				</>
			}
		/>
	);
}
