import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CertSetupInfo {
	@jsonMember(() => String)
	public thumb!: string;

	@jsonMember(() => String)
	public certBody!: string;
}

@jsonObject
export class CertsSetupInfo {
	@jsonArrayMember(() => CertSetupInfo)
	public certs!: CertSetupInfo[];
}
