import { Serializable, TypedJSON } from 'typedjson';
import logger from '@logger';
import { commonInfo } from '@businessLogic/common-info';
import { DeleteInit, PatchInit, PostInit, PutInit } from './fetch-init-helper';
import { fetchModel, fetchModelsArray } from './fetch-model-helper';
import { serializeToJson, serializeToJsonArray } from './json-helper';

export async function getModelFromAgentHttps<T>(rootConstructor: Serializable<T>, url: string) {
	return await fetchModel<T>(rootConstructor, `${commonInfo.agentHttps}${url}`);
}

export async function getModelsArrayFromAgentHttps<T>(rootConstructor: Serializable<T>, url: string) {
	return await fetchModelsArray<T>(rootConstructor, `${commonInfo.agentHttps}${url}`);
}

export async function getModelFromBackend<T>(rootConstructor: Serializable<T>, url: string) {
	return await fetchModel<T>(rootConstructor, `${commonInfo.backendUrl}${url}`);
}

export async function getModelsArrayFromBackend<T>(rootConstructor: Serializable<T>, url: string) {
	return await fetchModelsArray<T>(rootConstructor, `${commonInfo.backendUrl}${url}`);
}

export async function getFromAgentHttps(url: string): Promise<Response> {
	const fullUrl = new URL(`${commonInfo.agentHttps}${url}`);
	logger.info(`Осуществляется get запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl);
}

export async function getFromAgentHttp(url: string): Promise<Response> {
	const fullUrl = new URL(`${commonInfo.agentHttp}${url}`);
	logger.info(`Осуществляется get запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl);
}

export async function getFromBackend(url: string, abortSignal?: AbortSignal): Promise<Response> {
	const fullUrl = new URL(`${commonInfo.backendUrl}${url}`);
	logger.info(`Осуществляется get запрос по адресу: ${fullUrl}`);
	const requestOptions = { signal: abortSignal };
	return await fetch(fullUrl, requestOptions);
}

export async function postModel<T>(
	url: string,
	rootConstructor: Serializable<T>,
	model: T,
	signal?: AbortSignal,
): Promise<Response> {
	return postRequest(url, serializeToJson(rootConstructor, model), signal);
}

export async function postModelsArray<T>(
	url: string,
	rootConstructor: Serializable<T>,
	models: Array<T>,
	signal?: AbortSignal,
): Promise<Response> {
	return postRequest(url, serializeToJsonArray(rootConstructor, models), signal);
}

export async function postModelToAgentHttps<T>(
	url: string,
	rootConstructor: Serializable<T>,
	model: T,
	signal?: AbortSignal,
): Promise<Response> {
	return await postModel(`${commonInfo.agentHttps}${url}`, rootConstructor, model, signal);
}

// TODO SMA сделать методы для post to Backend, put, delete и прочих по аналогии
export async function postModelsArrayToAgentHttps<T>(
	url: string,
	rootConstructor: Serializable<T>,
	models: Array<T>,
	signal?: AbortSignal,
): Promise<Response> {
	return await postModelsArray(`${commonInfo.agentHttps}${url}`, rootConstructor, models, signal);
}

export async function patchToAgentHttps(url: string): Promise<Response> {
	return await patch(`${commonInfo.agentHttps}${url}`);
}

export async function patch(url: string): Promise<Response> {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется Patch запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl, new PatchInit());
}

export async function postModelToBackend<T>(
	url: string,
	rootConstructor: Serializable<T>,
	model: T,
	signal?: AbortSignal,
): Promise<Response> {
	return await postModel(`${commonInfo.backendUrl}${url}`, rootConstructor, model, signal);
}

export async function postRequest(url: string, body?: string, signal?: AbortSignal): Promise<Response> {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется post запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl, new PostInit(body, signal));
}

export async function postToAgentHttps(url: string, body?: string): Promise<Response> {
	return await postRequest(`${commonInfo.agentHttps}${url}`, body);
}

export async function postToBackend(url: string, body?: string): Promise<Response> {
	return await postRequest(`${commonInfo.backendUrl}${url}`, body);
}

export async function putModel<T>(url: string, rootConstructor: Serializable<T>, model: T): Promise<Response> {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется put запрос по адресу: ${fullUrl}`);
	const serializer = new TypedJSON(rootConstructor);
	return await fetch(fullUrl, new PutInit(serializer.stringify(model)));
}

export async function deleteModel<T>(url: string, rootConstructor: Serializable<T>, model: T) {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется delete запрос по адресу: ${fullUrl}`);
	const serializer = new TypedJSON(rootConstructor);
	return await fetch(fullUrl, new DeleteInit(serializer.stringify(model)));
}

export async function putModelToBackend<T>(url: string, rootConstructor: Serializable<T>, model: T): Promise<Response> {
	return await putModel(`${commonInfo.backendUrl}${url}`, rootConstructor, model);
}

export async function putModelToAgentHttps<T>(
	url: string,
	rootConstructor: Serializable<T>,
	model: T,
): Promise<Response> {
	return await putModel(`${commonInfo.agentHttps}${url}`, rootConstructor, model);
}

export async function putRequest(url: string, body?: string, signal?: AbortSignal): Promise<Response> {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется post запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl, new PutInit(body, signal));
}

export async function putToAgentHttps(url: string, body?: string): Promise<Response> {
	return await putRequest(`${commonInfo.agentHttps}${url}`, body);
}

export async function putToBackend(url: string, body?: string): Promise<Response> {
	return await putRequest(`${commonInfo.backendUrl}${url}`, body);
}

export async function deleteRequest(url: string): Promise<Response> {
	const fullUrl = new URL(url);
	logger.info(`Осуществляется delete запрос по адресу: ${fullUrl}`);
	return await fetch(fullUrl, new DeleteInit());
}

export async function deleteFromBackend(url: string): Promise<Response> {
	return await deleteRequest(`${commonInfo.backendUrl}${url}`);
}

export async function deleteFromAgentHttps(url: string): Promise<Response> {
	return await deleteRequest(`${commonInfo.agentHttps}${url}`);
}

export async function deleteModelFromAgentHttps<T>(
	url: string,
	rootConstructor: Serializable<T>,
	model: T,
): Promise<Response> {
	return await deleteModel(`${commonInfo.agentHttps}${url}`, rootConstructor, model);
}
