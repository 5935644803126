import styles from './input-error-hint.module.scss';
import { InputState } from '@features/settings/proxy/enums/input-state';
import { showError } from '../../business-logic/validation';
import { observer } from 'mobx-react-lite';

export interface InputErrorHintProps {
	addressState: InputState;
	portState: InputState;
}

function InputErrorHintElement(props: InputErrorHintProps) {
	const addressInputErrorKeynote =
		'поле «Адрес сервера». Можно указать доменное имя, IPv4 адрес или комбинацию из них с портом через двоеточие. Например, «8.8.0.0», «прокси.рф» или «proxy.org:8080»';
	const portInputErrorKeynote = 'поле «Порт». Можно использовать числа в диапазоне от 1 до 65535';

	function createInputErrorFullText(keynote: string, state: InputState): string {
		switch (state) {
			case InputState.Empty:
				return 'Заполните ' + keynote;
			case InputState.Wrong:
				return 'Отредактируйте ' + keynote;
			default:
				return '';
		}
	}

	function createLi(state: InputState, text: string) {
		return <li className={showError(state) ? '' : styles.displayNone}>{text}</li>;
	}

	return (
		<ul
			id={styles.errorHint}
			className={showError(props.portState) || showError(props.addressState) ? '' : styles.displayNone}
		>
			{createLi(props.addressState, createInputErrorFullText(addressInputErrorKeynote, props.addressState))}
			{createLi(props.portState, createInputErrorFullText(portInputErrorKeynote, props.portState))}
		</ul>
	);
}

export const InputErrorHint = observer(InputErrorHintElement);
