import { OrgData } from '@models/api/org-data';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class OrgInfo {
	@jsonMember(String)
	public orgId!: string;

	@jsonMember(String)
	public directoryPath!: string;

	@jsonMember(OrgData)
	public savedOrg!: OrgData;

	@jsonMember(Boolean)
	public isEnabled!: boolean;

	@jsonMember(Boolean)
	public isExpired!: boolean;

	@jsonMember(Boolean)
	public isDirectoryBad!: boolean;
}
