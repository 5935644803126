import { getCerts, needToShowOnlyNewQesCerts } from '@businessLogic/api/cert';
import logger from '@logger';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { Loading, NoCerts, ShowCerts, State } from '../models/states';

export default function useCerts(
	state: State,
	setState: Dispatch<SetStateAction<State>>,
	onError: (error: any) => void,
): (searchValue?: string) => Promise<void> {
	async function loadInner(searchValue?: string) {
		try {
			const showOnlyNewQesCerts = await needToShowOnlyNewQesCerts();
			const allCerts = await getCerts(searchValue);
			const certs = showOnlyNewQesCerts ? allCerts.filter(cert => cert.isCertNewQes) : allCerts;
			const searchResult = searchValue !== undefined && searchValue.length > 0;

			if (!searchResult && certs.length === 0) {
				setState(new NoCerts(showOnlyNewQesCerts));
				return;
			}

			setState(new ShowCerts(certs, searchResult, showOnlyNewQesCerts));
		} catch (error) {
			logger.error(error, `Возникла ошибка во время получения данных для списка сертификатов!`);
			onError(error);
		}
	}

	async function load(searchValue?: string) {
		if (searchValue === undefined) {
			setState(new Loading());
			return;
		}
		await loadInner(searchValue);
	}

	useEffect(() => {
		async function stateHandler() {
			if (state.kind === 'Loading') {
				await loadInner();
			}
		}

		stateHandler();
	}, [state]);

	return load;
}
