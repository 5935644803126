import {
	deleteFromAgentHttps,
	getFromAgentHttps,
	getModelFromAgentHttps,
	getModelsArrayFromAgentHttps,
	patchToAgentHttps,
	postModelToAgentHttps,
	putModelToAgentHttps,
} from '@helpers/fetch-helper';
import logger from '@logger';
import { TypedJSON } from 'typedjson';
import { deserializeJson } from '@helpers/json-helper';
import { ServerResponseError } from '@errors/api/server-response-error';
import { isNullOrEmpty } from '@helpers/string-helper';
import { getFailedToDeserializeResponse, getServerRequestError } from '@helpers/fetch-error-helper';
import { DeserializeResponseJsonError } from '@errors/api/deserialize-response-json-error';
import { NotCorrectDirectoryPathError } from '@errors/api/not-correct-directory-path-error';
import { DirectoryGetInfoResult } from '@features/settings/auto-import/models/api/directory-get-info-result';
import { NewOrgData } from '../models/api/new-org-data';
import { OrgCheckResult } from '../models/api/org-check-result';
import { OrgInfo } from '../models/api/org-info';
import { PathCheckInfo } from '../models/api/path-check-info';
import { PathCheckResult } from '../models/api/path-check-result';
import { ReAuthRequest } from '../models/api/re-auth-request';
import { ReAuthResult } from '../models/api/re-auth-result';

export async function receiveOrgList(searchString?: string): Promise<OrgInfo[]> {
	let url = process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORG_ALL!;
	if (!isNullOrEmpty(searchString)) {
		url = url + `?searchString=${searchString}`;
	}
	return await getModelsArrayFromAgentHttps(OrgInfo, url);
}

export async function appendNewOrg(newOrgData: NewOrgData): Promise<OrgInfo | undefined> {
	try {
		let response = await postModelToAgentHttps(
			process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORGS_OPERATION!,
			NewOrgData,
			newOrgData,
		);

		if (response.status === 400) {
			throw getNotCorrectPathError(response);
		}

		if (response.status === 200) {
			return deserializeJson(OrgInfo, await response.text());
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError || e instanceof NotCorrectDirectoryPathError) {
			throw e;
		}
		logger.error('Ошибка при попытке сохранить данные об организации!', e);
		throw e;
	}
}

export async function changeOrgStatus(orgId: string, newStatus: boolean): Promise<OrgInfo | undefined> {
	try {
		const params = new URLSearchParams({
			isEnabled: String(newStatus),
		});

		let response = await patchToAgentHttps(
			`${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORGS_OPERATION!}/${orgId}?${params.toString()}`,
		);

		if (response.status === 200) {
			return deserializeJson(OrgInfo, await response.text());
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError) {
			throw e;
		}
		logger.error('Ошибка при попытке сохранить данные об организации!', e);
		throw e;
	}
}

export async function removeOrg(orgId: string): Promise<boolean> {
	try {
		let response = await deleteFromAgentHttps(
			`${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORGS_OPERATION!}/${orgId}`,
		);

		return response.status === 200;
	} catch (e) {
		logger.error('Ошибка при попытке удалить организацию!', e);
		return false;
	}
}

export async function updateOrgPath(orgId: string, newPath: string): Promise<OrgInfo | undefined> {
	try {
		let response = await patchToAgentHttps(
			`${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORGS_OPERATION!}/${orgId}/${process.env
				.REACT_APP_AGENT_EP_AUTOIMPORT_ORG_PATH_PART!}?directoryPath=${newPath}`,
		);
		if (response.status === 200) {
			return deserializeJson(OrgInfo, await response.text());
		}
		if (response.status === 400) {
			throw getNotCorrectPathError(response);
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError || e instanceof NotCorrectDirectoryPathError) {
			throw e;
		}
		logger.error('Ошибка при попытке обновить путь к организации!', e);
		throw e;
	}
}

export async function updateStateForAllOrg(
	currentState: boolean,
	searchString?: string,
): Promise<OrgInfo[] | undefined> {
	try {
		const params = new URLSearchParams();
		params.append('action', currentState ? 'enableAll' : 'disableAll');
		if (!isNullOrEmpty(searchString)) {
			params.append('searchString', searchString!);
		}
		let url = `${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORG_ALL!}?${params.toString()}`;
		let response = await patchToAgentHttps(url);
		if (response.status === 200) {
			let res = TypedJSON.parseAsSet(await response.text(), OrgInfo);
			if (!res) {
				throw getFailedToDeserializeResponse(response, typeof OrgInfo);
			}
			return Array.from(res);
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError || e instanceof DeserializeResponseJsonError) {
			throw e;
		}
		logger.error('Ошибка при попытке обновить статус организаций!', e);
		throw e;
	}
}

export async function checkPath(pathToCheck: string): Promise<PathCheckResult> {
	try {
		let url = `${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_DIRECTORY_CHECK!}?directoryPath=${pathToCheck}`;
		let response = await getFromAgentHttps(url);
		let res = new PathCheckResult();
		if (response.status === 200 || response.status === 201) {
			res.path = deserializeJson(PathCheckInfo, await response.text())?.fullDirectoryPath!;
			if (response.status === 201) {
				res.isPathCreated = true;
			}
		}
		if (response.status === 400) {
			res.isPathInvalid = true;
		}
		if (response.status === 401) {
			res.isNoAccessToPath = true;
		}
		if (response.status === 403) {
			res.isPathBusyByAnother = true;
		}

		if (response.status === 500) {
			throw await getServerRequestError(response);
		} else {
			return res;
		}
	} catch (e) {
		if (e instanceof ServerResponseError) {
			throw e;
		}
		logger.error('Ошибка при попытке проверить директорию!', e);
		throw e;
	}
}

export async function checkOrgNotExists(osOrgId: string): Promise<boolean | undefined> {
	try {
		let url = `${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_OS_ORG_CHECK!}?id=${osOrgId}`;
		let response = await getFromAgentHttps(url);
		if (response.status === 200) {
			return deserializeJson(OrgCheckResult, await response.text())?.exist!;
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError) {
			throw e;
		}
		logger.error('Ошибка при попытке проверить организацию на наличие!', e);
		throw e;
	}
}

export async function selectDirectory(): Promise<string | undefined> {
	try {
		let response = await getFromAgentHttps(process.env.REACT_APP_AGENT_EP_AUTOIMPORT_SELECT_DIRECTORY!);
		if (response.status === 200) {
			return deserializeJson(DirectoryGetInfoResult, await response.text())?.pathToDirectory!;
		}
		if (response.status === 500) {
			throw await getServerRequestError(response);
		}
	} catch (e) {
		if (e instanceof ServerResponseError) {
			throw e;
		}
		logger.error('Ошибка при попытке получить директорию!', e);
	}
	return undefined;
}

export async function makeReAuth(orgId: string, newAuthData: ReAuthRequest): Promise<ReAuthResult> {
	let res = new ReAuthResult();
	try {
		let response = await putModelToAgentHttps(
			`${process.env.REACT_APP_AGENT_EP_AUTOIMPORT_ORGS_OPERATION!}/${orgId}/reauth`,
			ReAuthRequest,
			newAuthData,
		);

		if (response.status === 200) {
			res.orgData = deserializeJson(OrgInfo, await response.text())!;
		} else if (response.status === 404) {
			res.isOrgNotFound = true;
		} else if (response.status === 401) {
			res.isNoSameOrg = true;
		} else {
			res.isServerError = true;
		}
	} catch (e) {
		if (e instanceof ServerResponseError) {
			throw e;
		}
		logger.error('Ошибка при попытке переавторизоваться!', e);
		res.isServerError = true;
	}
	return res;
}

function getNotCorrectPathError(response: Response) {
	return new NotCorrectDirectoryPathError(`При проверке пути на организацию он оказался не корректный`, response);
}
