import styles from './orgs-list.module.scss';
import { ReactElement, useRef, MouseEventHandler, useState } from 'react';
import {
	EnableDisableSwitch,
	EnableDisableSwitchProps,
	NewOrgButton,
	SearchInput,
	SearchInputProps,
} from '@pages/settings/auto-import';
import { useOverflowObserver } from '@hooks/overflow-observer';
import { useHeightController } from '@hooks/height-controller';
import { SearchNotFound } from '@components/search-not-found';

interface Props {
	orgs: ReactElement[];
	addOrgAction: MouseEventHandler<HTMLButtonElement>;
	search?: SearchInputProps;
	enableDisableSwitch?: EnableDisableSwitchProps;
}

export function OrgsList(props: Props) {
	const ref = useRef<HTMLDivElement>(null);
	const hasOverflow = useOverflowObserver(ref);
	const [searching, setSearchState] = useState(false);
	const height = useHeightController(ref, searching);

	async function onSearch(searchText: string) {
		setSearchState(searchText.length !== 0);
		props.search?.onChange(searchText);
	}

	return (
		<>
			<div id={styles.additionalFunctions}>
				{(props.orgs.length > 1 || searching) && (
					<>
						{props.enableDisableSwitch && (
							<EnableDisableSwitch
								enableAll={props.enableDisableSwitch.enableAll}
								onClick={props.enableDisableSwitch.onClick}
							/>
						)}
						{props.search && (
							<SearchInput
								onChange={onSearch}
								placeholder={props.search.placeholder}
							/>
						)}
					</>
				)}
			</div>

			<div className={styles.main}>
				<div
					className={styles.list}
					ref={ref}
					style={{ maxHeight: height, height: height }}
				>
					{props.orgs.length === 0 && searching ? (
						<SearchNotFound text="Организации не найдены" />
					) : (
						props.orgs
					)}
				</div>
				<div id={styles.addButton}>
					{hasOverflow && <div id={styles.addButtonShadow}></div>}
					<NewOrgButton
						transparent={props.orgs.length !== 0 || searching}
						onClick={props.addOrgAction}
					/>
				</div>
			</div>
		</>
	);
}
