import {
	deleteFromAgentHttps,
	getFromAgentHttps,
	postModelToAgentHttps,
	putModelToAgentHttps,
} from '@helpers/fetch-helper';
import { TypedJSON } from 'typedjson';
import { PingStatus } from '@features/settings/proxy/enums/ping-status';
import { getServerRequestError } from '@helpers/fetch-error-helper';
import { ProxySettingsDto } from '../models/api/proxy-settings-dto';
import logger from '@logger';

export async function trySaveProxy(proxySettings: ProxySettingsDto): Promise<boolean> {
	let response = await putModelToAgentHttps(
		process.env.REACT_APP_AGENT_EP_SYSTEM_PROXY!,
		ProxySettingsDto,
		proxySettings,
	);
	if (!response.ok) {
		logger.error(
			`Не удалось сохранить прокси! Ответ сервера: [${response.status}]. Тело ответа: [${await response.text()}]`,
		);
		return false;
	}
	return true;
}

export async function getProxySettings(): Promise<ProxySettingsDto | undefined> {
	let response = await getFromAgentHttps(process.env.REACT_APP_AGENT_EP_SYSTEM_PROXY!);
	if (response.status === 204) {
		return undefined;
	}
	if (response.status === 200) {
		let body = await response.text();
		let serializer = new TypedJSON(ProxySettingsDto);
		let proxySettings = serializer.parse(body);
		if (!proxySettings) {
			throw await getServerRequestError(response);
		}
		return proxySettings;
	}
	throw await getServerRequestError(response);
}

export async function pingProxy(proxySettings: ProxySettingsDto, abortSignal?: AbortSignal): Promise<PingStatus> {
	try {
		let response = await postModelToAgentHttps(
			process.env.REACT_APP_AGENT_EP_SYSTEM_PROXY_PING!,
			ProxySettingsDto,
			proxySettings,
			abortSignal,
		);
		if (!response.ok) {
			return PingStatus.NotSucceeded;
		}
		return PingStatus.Ok;
	} catch (error: any) {
		if (error.name === 'AbortError') {
			return PingStatus.Interrupted;
		}
		return PingStatus.NotSucceeded;
	}
}

export async function deleteProxySettings(): Promise<void> {
	let response = await deleteFromAgentHttps(process.env.REACT_APP_AGENT_EP_SYSTEM_PROXY!);
	if (!response.ok) {
		throw new Error(
			`Код возврата при вызове метода API по удалению настроек прокси отличен от 200 и равен: [${response.status}]`,
		);
	}
}
