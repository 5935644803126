import { getServerRequestError } from '@helpers/fetch-error-helper';
import { deleteFromAgentHttps, getFromAgentHttps, postModelToAgentHttps } from '@helpers/fetch-helper';
import { TypedJSON } from 'typedjson';
import { CacheSavingResult } from '../enums/cache-saving-result';
import { SaveToCacheRequestDto } from '../models/dtos/requests/save-to-cache-request.dto';
import { KeyFromCacheResponseDto } from '../models/dtos/responses/key-from-cache-response.dto';

export async function saveToCache(
	key: string,
	value: string,
	oneTimeAccess: boolean = false,
): Promise<CacheSavingResult> {
	const saveToCacheRequestDto = new SaveToCacheRequestDto();
	saveToCacheRequestDto.key = key;
	saveToCacheRequestDto.value = value;
	saveToCacheRequestDto.oneTimeAccess = oneTimeAccess;

	const response = await postModelToAgentHttps(
		process.env.REACT_APP_AGENT_EP_CACHE!,
		SaveToCacheRequestDto,
		saveToCacheRequestDto,
	);
	switch (response.status) {
		case 200:
			return CacheSavingResult.Ok;
		case 409:
			return CacheSavingResult.AlreadyAdded;
		default:
			return CacheSavingResult.Other;
	}
}

export async function getKeyFromCache(key: string): Promise<string | null> {
	let response = await getFromAgentHttps(`${process.env.REACT_APP_AGENT_EP_CACHE!}?key=${key}`);
	if (response.status === 204) {
		return null;
	}
	if (response.status === 200) {
		let body = await response.text();
		let serializer = new TypedJSON(KeyFromCacheResponseDto);
		let dto = serializer.parse(body);
		if (!dto) {
			throw await getServerRequestError(response);
		}
		return dto.value;
	}
	throw await getServerRequestError(response);
}

export async function deleteKeyFromCache(key: string): Promise<boolean> {
	let response = await deleteFromAgentHttps(`${process.env.REACT_APP_AGENT_EP_CACHE!}?key=${key}`);
	return response.ok;
}
