import styles from './setup-succeeded.module.scss';
import { useEffect } from 'react';
import { Loader } from '@components/loader';
import { Page } from '@components/page-template';
import { RedirectTypes } from '@features/shortcuts-creator/enums/redirect-types';
import { ProductsGrid } from '../../../features/shortcuts-creator/components/products-grid';
import { observer } from 'mobx-react-lite';
import { shortcutCreatorStore } from '../../../features/shortcuts-creator/stores/shortcut-creator-store';
import { CreateShortCutsButton } from '../../../features/shortcuts-creator/components/create-shotcuts-button';
import { Modal } from '../../../features/shortcuts-creator/components/modal';
import { commonInfo } from '@businessLogic/common-info';
import { PageImage } from '@components/page-template/page-image-enum';
import { notifyGreen, notifyGreenWithTitle } from '@components/notification';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';

const notificationWidth = 354;

function showCpLicenseNotificationIfNeeded() {
	if (!cpLicenseStore.showNotification) return;
	if (cpLicenseStore.licenseKey) {
		notifyGreenWithTitle(
			'Активирована лицензия КриптоПро CSP 5',
			<>
				Серийный номер
				<br />
				{cpLicenseStore.licenseKey}
			</>,
			notificationWidth,
		);
	} else {
		notifyGreen(<>Обновление КриптоПро завершено</>, notificationWidth);
	}
}

function SetupSucceededElement() {
	useEffect(() => {
		async function preparePage() {
			await shortcutCreatorStore.initAll();
			showCpLicenseNotificationIfNeeded();
			window.history.replaceState(null, '', window.location.pathname);
		}

		preparePage();
	}, []);

	function openInNewTab(queryParams: string) {
		window.open(
			`${commonInfo.backendUrl}${process.env.REACT_APP_EP_REDIRECT}${queryParams}`,
			'_self',
			'noopener,noreferrer',
		);
	}

	return (
		<>
			<Page
				rightPaneContentClassName={styles.rightPaneContent}
				image={PageImage.Success}
				child={
					<div id={styles.content}>
						<div id={styles.title}>
							{shortcutCreatorStore.createShortCutMode ? 'Создание ярлыков' : 'Компьютер настроен'}
						</div>
						{!shortcutCreatorStore.redirectInfo ||
						shortcutCreatorStore.redirectInfo.redirectType === RedirectTypes.None ? (
							<Loader />
						) : (
							<div>
								<div id={styles.subtitle}>
									{shortcutCreatorStore.createShortCutMode
										? 'Выберите сервисы, ярлыки которых нужно добавить на рабочий стол'
										: 'Можете пользоваться сервисами Такскома'}
								</div>{' '}
								{shortcutCreatorStore.redirectInfo.redirectType !== RedirectTypes.ToOneProduct ||
								shortcutCreatorStore.createShortCutMode ? (
									<ProductsGrid />
								) : null}
								<div className={styles.buttons}>
									{shortcutCreatorStore.redirectInfo.redirectType === RedirectTypes.ToOneProduct &&
									!shortcutCreatorStore.createShortCutMode ? (
										<button
											className={styles.btn1}
											onClick={() =>
												openInNewTab(shortcutCreatorStore.redirectInfo!.redirectQueryPart)
											}
										>
											{shortcutCreatorStore.redirectInfo.buttonText}
										</button>
									) : null}
									{shortcutCreatorStore.anyAvailableToCreateShortcut ? (
										<CreateShortCutsButton />
									) : null}
									{shortcutCreatorStore.createShortCutMode ? (
										<button
											className={styles.buttonTransparentBlue}
											onClick={() => shortcutCreatorStore.disableCreateShortCutMode()}
										>
											Отменить
										</button>
									) : null}
								</div>
							</div>
						)}
					</div>
				}
				showMobileView={false}
			/>
			<Modal />
		</>
	);
}

export const SetupSucceeded = observer(SetupSucceededElement);
