import { StageInfoLinkContent } from './stage-info-link-content';
import { StageInfo } from './stage-info';

export class StageInfoWithLinkContent extends StageInfo {
	public content: StageInfoLinkContent;

	public constructor(title: string, content: StageInfoLinkContent) {
		super(title);
		this.content = content;
	}
}
