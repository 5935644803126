import { ErrorCardWithRefreshPage } from '@components/error-card-with-refresh-page';
import { PagesSettings } from '../../enums/pages-settings';
import { storeSettings } from '../../stores/store-settings';
import styles from './ta-connection-lost-card.module.scss';

export const TaConnectionLostCard = () => {
	return (
		<ErrorCardWithRefreshPage
			title="Потеряли связь с Такском-Агентом"
			content={[
				<div>Проверьте, запущен ли Такском-Агент, и обновите страницу.</div>,
				<div>
					Перейдите на{' '}
					<button
						className={styles.linkButton}
						onClick={() => {
							storeSettings.setPage(PagesSettings.NoConnection);
						}}
					>
						страницу
					</button>{' '}
					для получения рекомендаций по устранению ошибки, если она сохраняется.
				</div>,
			]}
		/>
	);
};
