import { SystemTypes } from '@enums/system-types';
import { getFromAgentHttps, postToAgentHttps } from '@helpers/fetch-helper';
import logger from '@logger';
import { delay } from '@helpers/delay-helper';
import { CheckTerminalStatuses } from '@enums/check-terminal-statuses';
import { checkTerminalTa } from '@businessLogic/terminal-agent-operation';
import { commonInfo } from './common-info';

export async function restartAgent(): Promise<boolean> {
	try {
		await postToAgentHttps(process.env.REACT_APP_AGENT_EP_RESTART!, '');
		logger.info('Вызвана перезагрузка агента.');
	} catch (error) {
		// Ничего не делаем, т.к. в теории агент может просто закрыться и не успеть ответить
	}
	let isWindows = (await commonInfo.getSystemInfo()).type === SystemTypes.Windows;
	let checksCount = parseInt(process.env.REACT_APP_CHECK_AGENT_COUNT!);
	let checkDelay = parseInt(process.env.REACT_APP_CHECK_AGENT_DELAY!);
	for (let i = 0; i < checksCount; i++) {
		try {
			await delay(checkDelay);
			if (isWindows) {
				let termResult = await checkTerminalTa();
				if (termResult === CheckTerminalStatuses.NeedUpdate) {
					//TODO MGA подумать
					logger.error('Терминальный ТА вернул ошибку о том что необходимо обновиться.');
					return false;
				}
			}
			let response = await getFromAgentHttps(`${process.env.REACT_APP_AGENT_EP_VERSION!}`);
			if (response.ok) {
				logger.info('Перезагрузка агента прошла успешно.');
				return true;
			}
		} catch (error) {
			// Ничего не делаем, т.к. в теории агент может просто закрыться и не успеть ответить
			logger.warn(error);
		}
	}
	return false;
}
