import { CheckUp } from '@pages/setup-wizard/check-up';
import { CryptographyLicense } from '@pages/setup-wizard/cryptography-license/';
import { NonActualVersion } from '@pages/non-actual-version';
import { AgentNotFound } from '@pages/setup-wizard/agent-not-found';
import { NotSupportedOs } from '@pages/setup-wizard/not-supported-os';
import { PreliminaryChecks } from '@pages/setup-wizard/preliminary-checks';
import { Setup } from '@pages/setup-wizard/setup';
import { SetupFailed } from '@pages/setup-wizard/setup-failed';
import { SetupSettings } from '@pages/setup-wizard/setup-settings';
import { SetupSucceeded } from '@pages/setup-wizard/setup-succeeded';
import { store } from '@features/setup-wizard/stores/store';
import { observer } from 'mobx-react-lite';
import { CpInstallErrorWhenVipnet } from '@pages/setup-wizard/cp-install-error-when-vipnet/cp-install-error-when-vipnet';
import { CpNoActualLicense } from '@pages/setup-wizard/cp-no-actual-license/cp-no-actual-license';
import { InstallCp } from '@pages/setup-wizard/install-cp/install-cp';
import { NoAccessToRestart } from '@pages/setup-wizard/no-access-to-restart/no-access-to-restart';
import { DownloadCpError } from '@pages/setup-wizard/download-cp-error/download-cp-error';
import { InstallCpError } from '@pages/setup-wizard/install-cp-error/install-cp-error';
import { NoAccessToInstallCp } from '@pages/setup-wizard/no-access-to-install-cp/no-access-to-install-cp';
import { NoConnectionTerminal } from '@pages/no-connection/terminal';
import { NoConnection } from '@pages/no-connection/basic';
import { ChooseCert } from '@pages/setup-wizard/choose-cert';
import { ChooseCertStatus } from '@features/cp-license/enums/choose-cert-status';
import { UpdateCpMinorVersionErrorPage } from '../update-cp-minor-version/update-cp-minor-version-error-page';
import { UpdateCpMinorVersionProcessPage } from '../update-cp-minor-version/update-cp-minor-version-process-page';
import { UpdateCpMinorVersionRestartFailurePage } from '../update-cp-minor-version/update-cp-minor-version-restart-failure-page';
import { UpdateCpMinorVersionStartPage } from '../update-cp-minor-version/update-cp-minor-version-start-page';

export const Main = observer(() => {
	switch (store.page.kind) {
		case 'PreliminaryChecks':
			return <PreliminaryChecks />;
		case 'CheckUp':
			return <CheckUp />;
		case 'NoConnection':
			return <NoConnection />;
		case 'NotFound':
			return <AgentNotFound />;
		case 'NotSupportedOs':
			return <NotSupportedOs />;
		case 'Setup':
			return <Setup />;
		case 'SetupFailed':
			return <SetupFailed />;
		case 'SetupSettings':
			return <SetupSettings />;
		case 'SetupSucceeded':
			return <SetupSucceeded />;
		case 'CryptographyLicense':
			return <CryptographyLicense />;
		case 'NonActualVersion':
			return <NonActualVersion />;
		case 'CpInstallErrorWhenVipnet':
			return <CpInstallErrorWhenVipnet />;
		case 'CpNoActualLicense':
			return <CpNoActualLicense />;
		case 'InstallCp':
			return <InstallCp />;
		case 'NoAccessToRestartAfterInstallCp':
			return <NoAccessToRestart />;
		case 'DownloadCpError':
			return <DownloadCpError />;
		case 'InstallCpError':
			return <InstallCpError />;
		case 'NoAccessToInstallCp':
			return <NoAccessToInstallCp />;
		case 'NoConnectionTerminal':
			return <NoConnectionTerminal />;
		case 'InstalledCp5Demo':
			return <ChooseCert status={ChooseCertStatus.InstalledCp5Demo} />;
		case 'FindSerial':
			return <ChooseCert status={ChooseCertStatus.UpdateCp} />;

		case 'UpdateCpMinorVersionStart':
			return <UpdateCpMinorVersionStartPage />;
		case 'UpdateCpMinorVersionProcess':
			return <UpdateCpMinorVersionProcessPage />;
		case 'UpdateCpMinorVersionRestartFailure':
			return <UpdateCpMinorVersionRestartFailurePage />;
		case 'UpdateCpMinorVersionError':
			return <UpdateCpMinorVersionErrorPage errorType={store.page.errorType} />;
	}
});
