import styles from './agent-not-found.module.scss';
import { InstructionLink } from '@components/instruction-link';
import { SystemTypes } from '@enums/system-types';
import { DownloadHint } from '@components/modal-cards/download-hint';
import { useState } from 'react';
import { prepareInstallApiLink } from '@businessLogic/install-link-preparator';
import { Page, PageImage } from '@components/page-template';
import { commonInfo } from '@businessLogic/common-info';

export function AgentNotFound() {
	const systemType = commonInfo.getSystemType();
	const [modalActive, setModalState] = useState(false);

	async function downloadAgent() {
		window.open(await prepareInstallApiLink(), '_blank');
		setModalState(true);
	}

	return (
		<Page
			image={PageImage.Error}
			child={
				<div>
					<div id={styles.title}>
						Не смогли найти
						<br />
						Такском-Агент
					</div>
					<div className={styles.descriptionText}>
						Скачайте и установите Такском-Агент на компьютер. Если Такском‑Агент установлен, то запустите
						его{' '}
						{systemType === SystemTypes.Linux ? (
							<InstructionLink
								link={process.env.REACT_APP_INSTR_RUN_TA_LINUX!}
								text={'по инструкции'}
							/>
						) : (
							<></>
						)}{' '}
						и обновите страницу
					</div>
					<button
						onClick={downloadAgent}
						className={styles.buttonBlue}
					>
						Скачать Такском-Агент
					</button>
				</div>
			}
			modalCard={<DownloadHint />}
			modalActive={modalActive}
			onClickOutOfModalCard={() => setModalState(false)}
			modalStyle={styles.modal}
			showMobileView={true}
		/>
	);
}
