import { haveSameData } from '@helpers/comparison-helper';
import logger from '@logger';
import { Stage } from '@features/setup-wizard/models/stages';
import { BrowserInfo } from '@features/setup-wizard/models/api/browser';
import {
	StageInfo,
	StageInfoWithLinkContent,
	StageInfoWithTextContent,
} from '@features/setup-wizard/models/stages/infos';
import { getModelFromAgentHttps, getModelFromBackend, putModelToAgentHttps } from '@helpers/fetch-helper';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';

const BreakError = {};

export class BrowserStage extends Stage {
	private browserInfo?: BrowserInfo;

	public constructor() {
		super(StageTypes.Browser);
	}

	protected async checkUpLogic() {
		let browserInfoBackend = await getModelFromBackend(BrowserInfo, process.env.REACT_APP_EP_TRUSTED_SITES!);
		logger.info(`Получен список доверенных сайтов с бэкенда.`);

		let browserInfoTa = await getModelFromAgentHttps(BrowserInfo, process.env.REACT_APP_AGENT_EP_TRUSTED_SITES!);
		logger.info(`Получен список доверенных сайтов с агента.`);

		this.browserInfo = new BrowserInfo();
		this.browserInfo.trustedSites = browserInfoBackend.trustedSites.filter(siteBackend => {
			let check = true;
			try {
				browserInfoTa.trustedSites.forEach(siteTa => {
					if (siteBackend.domain === siteTa.domain && haveSameData(siteBackend, siteTa)) {
						check = false;
						throw BreakError;
					}
				});
			} catch (err) {
				if (err !== BreakError) throw err;
			}
			return check;
		});

		if (this.browserInfo.trustedSites.length === 0) {
			this.needInstall = false;
			logger.info(`Все необходимые доверенные сайты уже есть. Настройка не требуется.`);
		} else {
			logger.info(`Присутствуют не все необходимые доверенные сайты. Требуется настройка.`);
		}
	}

	protected async setupLogic() {
		if (this.browserInfo == null) {
			logger.warn(`Настройка не удалась, т.к. отсутствует информация о браузере и доверенных сайтах!`);
			return;
		}

		let response = await putModelToAgentHttps(
			process.env.REACT_APP_AGENT_EP_TRUSTED_SITES!,
			BrowserInfo,
			this.browserInfo,
		);
		if (response.ok) {
			logger.info(`Настройка браузера успешно завершена.`);
			this.needInstall = false;
			return;
		}
		logger.warn(JSON.stringify(await response.text()));
	}

	public getSetupSettingsStageInfo(): StageInfoWithTextContent {
		return new StageInfoWithTextContent(
			'Настроить браузер',
			'Добавить «*.taxcom.ru» и «https://*.taxcom.ru» в надежные адреса, чтобы не было проблем с доступом к ресурсам Такском',
		);
	}

	public getCheckUpStageInfo(): StageInfo {
		return new StageInfo('Настройки браузера');
	}

	public getInstallStageInfo(): StageInfo {
		return new StageInfo('Настраиваем браузер');
	}

	public getInstallErrorStageInfo(): StageInfoWithLinkContent {
		return new StageInfoWithLinkContent('Настройте браузер', {
			textBeforeLink: 'Добавьте «*.taxcom.ru» и «https://*.taxcom.ru» в надежные адреса',
		});
	}
}
