import { cloneElement, MouseEventHandler, ReactElement } from 'react';
import styles from './action-modal-card-template.module.scss';
import { ReactComponent as Cross } from '@assets/icons/cross-thin.svg';
import { v4 as getUuid } from 'uuid';

export interface BasicModalCardTemplateProps {
	title: string;
	body: ReactElement;
	footerButtons: ReactElement<HTMLButtonElement>[];
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
}

export function ActionModalCardTemplate(props: BasicModalCardTemplateProps) {
	return (
		<div className={styles.card}>
			<header className={styles.header}>
				<span className={styles.title}>{props.title}</span>
				<button
					className={[styles.crossButton, props.onClickCrossButton ? null : styles.hidden].join(' ')}
					onClick={props.onClickCrossButton}
				>
					<Cross className={styles.cross} />
				</button>
			</header>
			<div className={styles.body}>{props.body}</div>
			<footer className={styles.footer}>
				{props.footerButtons.map(element => cloneElement(element, { key: getUuid() }))}
			</footer>
		</div>
	);
}
