import { Main } from '@pages/setup-wizard/main';
import { Routes, Route } from 'react-router-dom';
import SettingsRouter from '@pages/settings/settings-router';
import { PageNotFound } from '@pages/page-not-found';
import { WrongInstall } from '@pages/wrong-install/wrong-install';

window.Buffer = window.Buffer || require('buffer').Buffer;

function App() {
	return (
		<Routes>
			<Route
				path="/settings/*"
				element={<SettingsRouter />}
			/>
			<Route
				path="/wrongInstall"
				element={<WrongInstall />}
			/>
			<Route
				path="/"
				element={<Main />}
			/>
			<Route
				path="*"
				element={<PageNotFound />}
			/>
		</Routes>
	);
}

export default App;
