import { getModelFromBackend } from '@helpers/fetch-helper';
import { SoftTypes } from '@features/setup-wizard/enums/soft-types';
import { commonInfo } from '@businessLogic/common-info';
import { DistrInfo } from '@models/api';

export async function GetDistrInfo(softType: SoftTypes): Promise<DistrInfo> {
	let systemInfo = await commonInfo.getSystemInfo();
	return await getModelFromBackend(
		DistrInfo,
		`${process.env.REACT_APP_EP_DISTR_LINKS!}?architecture=${systemInfo.architecture}&type=${
			systemInfo.type
		}&build=${systemInfo.build}&name=${systemInfo.name}&softType=${softType}`,
	);
}
