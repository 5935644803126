import { AuthResponse } from '@models/api/os-authorize/auth-response';
import { UnexpectedReturnError } from '@base/errors/api/unexpected-return-error';
import logger from '@logger';
import { deleteFromBackend, getFromBackend } from '@helpers/fetch-helper';
import { deserializeJson } from '@helpers/json-helper';
import { NotExpectedId } from '@errors/api/oauth/not-expected-id';
import { commonInfo } from '@businessLogic/common-info';

export function generateLinkMakeAuth(id: string): string {
	return `${commonInfo.backendUrl}${process.env.REACT_APP_EP_OS_AUTHORIZE_REDIRECT!}/${id}`;
}

export async function getAuthResult(id: string, abortSignal?: AbortSignal): Promise<AuthResponse | undefined> {
	try {
		let url = `${process.env.REACT_APP_EP_OS_AUTHORIZE!}/${id}`;
		let fetchResult = await getFromBackend(url, abortSignal);
		if (fetchResult.status === 204) {
			return undefined;
		}
		if (fetchResult.status === 200) {
			return deserializeJson(AuthResponse, await fetchResult.text());
		}
		if (fetchResult.status === 404) {
			throw new NotExpectedId(`Данные об авторизации по id=${id} не найдены!`, id);
		}
	} catch (e) {
		if (e === NotExpectedId) {
			throw e;
		}
		let msg = `Неожиданная ошибка при работе с api по получению данных о результатах авторизации\n${e}`;
		logger.error(msg);
		throw new UnexpectedReturnError(msg);
	}
}

export async function revokeToken(id: string): Promise<boolean> {
	try {
		let url = `${process.env.REACT_APP_EP_OS_AUTHORIZE!}/${id}`;
		let response = await deleteFromBackend(url);
		return response.status === 200;
	} catch (e) {
		let msg = `Неожиданная ошибка при работе с api по отзыву токенов авторизации!\n${e}`;
		logger.error(msg);
		return false;
	}
}
