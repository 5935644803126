import { Page, PageImage } from '@components/page-template';
import styles from './no-access-to-install-cp.module.scss';
import { InstructionLink } from '@components/instruction-link';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';

export function NoAccessToInstallCp() {
	return (
		<Page
			image={PageImage.Error}
			child={
				<div>
					<div id={styles.title}>Не хватает прав для запуска установщика КриптоПро CSP 5</div>
					<div id={styles.supportTextBlock}>
						<div id={styles.supportText}>
							Обратитесь к&nbsp;Администратору или&nbsp;запустите
							{cpStore.setupMode !== CpSetupMode.Install ? ' обновление ' : ' установку '}
							самостоятельно, воспользовавшись инструкцией по{' '}
							<InstructionLink
								link={process.env.REACT_APP_INSTR_INSTALL_CRYPTO!}
								text={'ссылке'}
							/>
						</div>
					</div>
					<button
						className={styles.buttonBlue}
						onClick={() => {
							window.location.reload();
						}}
					>
						Обновить страницу
					</button>
				</div>
			}
		/>
	);
}
