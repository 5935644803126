import styles from './not-supported-os.module.scss';
import { useState } from 'react';
import { Switcher } from '@components/switcher';
import { SystemTypes } from '@enums/system-types';
import { LinuxTitles, WindowsTitles } from './titles';
import { Page, PageImage } from '@components/page-template';
import { commonInfo } from '@businessLogic/common-info';

function SystemsList(props: { systems: string[] }) {
	let components = props.systems.map((system, index) => (
		<div
			className={styles.listElement}
			key={index}
		>
			{system}
		</div>
	));

	return <div>{components}</div>;
}

export function NotSupportedOs() {
	const showMobileView = true;

	const [systemType, setSystemType] = useState<SystemTypes>(commonInfo.getSystemType());

	return (
		<Page
			child={
				<div id={styles.content}>
					<div id={styles.title}>Устройство не подходит</div>
					<div id={styles.subtitle}>Запустите мастер на компьютере с системой из списка:</div>
					<Switcher
						showMobileView={showMobileView}
						callback={(systemType: SystemTypes): void => {
							setSystemType(systemType);
						}}
						systemType={systemType}
					/>
					<SystemsList systems={systemType === SystemTypes.Linux ? LinuxTitles : WindowsTitles} />
				</div>
			}
			image={PageImage.Error}
			showMobileView={showMobileView}
		/>
	);
}
