import styles from './download-hint.module.scss';
import arrowUp from './assets/arrow-up.png';
import arrowDown from './assets/arrow-down.png';
import { UAParser } from 'ua-parser-js';
import { compare } from 'compare-versions';
import logger from '@logger';

export function DownloadHint() {
	function getStyle() {
		try {
			const ua = new UAParser(navigator.userAgent);
			const browser = ua.getBrowser().name;

			switch (browser) {
				case 'Yandex':
					return styles.yandex;
				case 'Chromium':
				case 'Chrome':
					const version = ua.getEngine().version;
					return version && compare(version, '115.0.5790', '<') ? styles.bottom : styles.default;
				default:
					return styles.default;
			}
		} catch (error) {
			logger.error(error);
			return styles.default;
		}
	}

	const style = getStyle();

	return (
		<div className={[styles.hint, style].join(' ')}>
			<img
				className={[styles.bottomHintImage, style].join(' ')}
				src={arrowDown}
			/>
			<div className={styles.hintText}>
				Для запуска установки
				<br />
				кликните по файлу
			</div>
			<img
				className={[styles.defaultHintImage, style].join(' ')}
				src={arrowUp}
			/>
		</div>
	);
}
