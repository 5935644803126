import { jsonMember, jsonObject } from 'typedjson';
import { ExtendData } from './extend-data';

@jsonObject
export class License {
	@jsonMember(Boolean)
	public isActive!: boolean;

	@jsonMember(Boolean)
	public successExtendExtract!: boolean;

	@jsonMember(ExtendData)
	public extendData?: ExtendData;
}
