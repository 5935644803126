import { jsonMember, jsonObject } from 'typedjson';
import { OrgData } from '@models/api/org-data';

@jsonObject
export class AuthResponse {
	@jsonMember(String)
	public accessToken!: string;

	@jsonMember(String)
	public refreshToken!: string;

	@jsonMember(Date)
	public accessTokenExpTime!: Date;

	@jsonMember(Date)
	public refreshTokenExpTime!: Date;

	@jsonMember(OrgData)
	public orgData!: OrgData;
}
