import styles from './password-input.module.scss';
import { ChangeEventHandler, FocusEventHandler, useRef, useState } from 'react';
import { ReactComponent as Eye } from '@assets/icons/eye.svg';
import { ReactComponent as EyeCrossedOut } from '@assets/icons/eye-crossed-out.svg';

export interface PasswordInputProps {
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	value?: string | number | readonly string[];
	wrong?: boolean;
}

export function PasswordInput(props: PasswordInputProps) {
	let [showPassword, setShowPasswordState] = useState(false);

	return (
		<div className={styles.inputWithElement}>
			<input
				type={showPassword ? 'text' : 'password'}
				placeholder="Password"
				onChange={props.onChange}
				onBlur={props.onBlur}
				value={props.value}
				className={props.wrong ? styles.wrongInputCustom1 : styles.inputCustom1}
			/>
			<button
				className={styles.passwordCheckbox}
				tabIndex={-1}
				onMouseDown={() => setShowPasswordState(!showPassword)}
			>
				{showPassword ? <EyeCrossedOut className={styles.eye} /> : <Eye className={styles.eye} />}
			</button>
		</div>
	);
}
