import { ProductInfo } from '@models/api/product-info';
import logger from '@logger';
import { getModelFromBackend } from '@helpers/fetch-helper';

export async function getUtmAppInfo(appId: string): Promise<ProductInfo | undefined> {
	return await getAppInfo(`?app=${appId}`);
}

export async function getInstalledIdAppInfo(installerId: string): Promise<ProductInfo | undefined> {
	return await getAppInfo(`?installerId=${installerId}`);
}

async function getAppInfo(queryParams: string): Promise<ProductInfo | undefined> {
	try {
		return await getModelFromBackend(ProductInfo, process.env.REACT_APP_EP_PRODUCTINFO! + queryParams);
	} catch (e) {
		logger.error(`Ошибка при попытке получить информацию о продукте по ссылке ${queryParams}`, e);
		return undefined;
	}
}
