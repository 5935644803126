import { jsonArrayMember, jsonObject } from 'typedjson';
import { PdfAdobeProgram } from './pdf-adobe-program';

@jsonObject
export class AcrobatReaderInfo {
	@jsonArrayMember(() => PdfAdobeProgram)
	public pdfAdobePrograms?: PdfAdobeProgram[];

	// ТА версии 3.1.4 возвращает некорректное имя для объекта типа PdfAdobeProgram. Таким образом мы его поддерживаем
	@jsonArrayMember(() => PdfAdobeProgram)
	public pdfAbodePrograms?: PdfAdobeProgram[];

	public GetActual(): PdfAdobeProgram[] | undefined {
		if (this.pdfAbodePrograms !== undefined) return this.pdfAbodePrograms;
		return this.pdfAdobePrograms;
	}
}
