import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ModalStore } from '@base/stores/modal-store';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import styles from './serial-not-attached-to-cert-modal.module.scss';

export interface Props {
	modalStore: ModalStore;
}

function SerialNotAttachedToCertModal(props: Props) {
	return (
		<InfoModalCardTemplate
			title="К сертификату не привязан серийный номер"
			info="Пожалуйста, проверьте правильность выбора и повторите попытку"
			image={<Attention className={styles.exclamationIcon} />}
			centerCross
			onClickCrossButton={() => props.modalStore.hide()}
		/>
	);
}

export const showSerialNotAttachedToCertModal = (props: Props) => {
	props.modalStore.show({ children: <SerialNotAttachedToCertModal {...props} />, active: true });
};
