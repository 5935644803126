import { jsonMember, jsonObject } from 'typedjson';
import { OrgAuthData } from './org-auth-data';

@jsonObject
export class NewOrgData {
	@jsonMember(String)
	public directoryPath!: string;

	@jsonMember(() => OrgAuthData)
	public orgAuthData!: OrgAuthData;
}
