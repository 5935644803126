import { MouseEventHandler, ReactElement } from 'react';
import styles from './reset-settings-modal-card.module.scss';
import { observer } from 'mobx-react-lite';
import { ActionModalCardTemplate } from '@components/modal-cards/action-modal-card-template';

export interface ResetSettingsModalCardProps {
	onReset: MouseEventHandler<HTMLButtonElement>;
	onCancel: MouseEventHandler<HTMLButtonElement>;
}

function ResetSettingsModalCardElement(props: ResetSettingsModalCardProps) {
	return (
		<ActionModalCardTemplate
			title="Сбросить настройки прокси?"
			body={
				<div className={styles.main}>
					Поля будут очищены. Соединение будет напрямую, без использования прокси-сервера
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonRed}
					onClick={props.onReset}
				>
					Сбросить
				</button>,
				<button
					className={styles.buttonTransparentWithBorderBlue}
					onClick={props.onCancel}
				>
					Отменить
				</button>,
			]}
		/>
	);
}

export const ResetSettingsModalCard = observer(ResetSettingsModalCardElement);
