import { ReactComponent as ExclamationFace } from '@assets/icons/exclamation-face.svg';
import { ReactNode } from 'react';
import styles from './search-not-found.module.scss';

interface Props {
	text: ReactNode;
}

export function SearchNotFound(props: Props) {
	return (
		<div id={styles.main}>
			<ExclamationFace />
			<div id={styles.text}>{props.text}</div>
		</div>
	);
}
