export class PathCheckResult {
	//флаг того что путь создан
	public isPathCreated!: boolean;

	//флаг того что путь занят другой организацией
	public isPathBusyByAnother!: boolean;

	//флаг того что путь не корректный
	public isPathInvalid!: boolean;

	//флаг того что к пути нет доступа
	public isNoAccessToPath!: boolean;

	//проверенный путь
	public path!: string;
}
