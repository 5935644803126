import { Page, PageImage } from '@components/page-template';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { UpdateCpMinorVersionStage } from '@features/update-cp-minor-version/enums/update-cp-minor-version-stage';
import styles from './update-cp-minor-version-restart-failure-page.module.scss';

export function UpdateCpMinorVersionRestartFailurePage() {
	function renderTitleText(): string {
		switch (cpStore.currentUpdateCpMinorVersionStage?.kind) {
			case UpdateCpMinorVersionStage.Deletion:
				return 'КриптоПро CSP 5 удалено';
			case UpdateCpMinorVersionStage.Installation:
				return 'Установка КриптоПро CSP 5 завершена';
			case UpdateCpMinorVersionStage.Cleaning:
				return 'Очистка файлов завершена';
			default:
				return '';
		}
	}

	return (
		<Page
			rightPaneContentClassName={styles.rightPaneContent}
			image={PageImage.Attention}
			child={
				<div>
					<div id={styles.title}>{renderTitleText()}</div>
					<div id={styles.subtitle}>Для продолжения обновления требуется перезагрузка компьютера</div>
					<PromptBlock
						type={PromptBlockType.Warning}
						text={'Не хватает прав на перезагрузку. Обратитесь к Администратору'}
					/>
				</div>
			}
		/>
	);
}
