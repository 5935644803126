import { ActiveXValues } from '@features/setup-wizard/enums/activex-values';
import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class ProgramComponentsInfo {
	@jsonMember(() => String)
	public binaryCode?: ActiveXValues;

	@jsonMember(() => String)
	public runElements?: ActiveXValues;
}
