import { PingStatus } from '@features/settings/proxy/enums/ping-status';
import { InputState } from '@features/settings/proxy/enums/input-state';
import { makeAutoObservable } from 'mobx';
import { SaveStore } from './save-store';
import { ModalState } from '@features/settings/proxy/enums/modal-state';
import { checkPortValidity, checkState } from '../business-logic/validation';
import { addressRegex, loginRegex, passwordRegex } from '../data/regular-expressions';
import { PageStatus } from '@features/settings/common/enums/page-body-status';
import { prepareSettingsPage } from '@features/settings/common/helpers/prepare-settings-page';
import logger from '@logger';
import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import { storeSettings } from '@features/settings/common/stores/store-settings';
import { PageImage } from '@components/page-template';

export class ProxySettingsStore {
	public address!: string;
	public addressInputState!: InputState;

	public port?: number;
	public portInputState!: InputState;

	public login!: string;
	public loginInputState!: InputState;

	public password!: string;
	public passwordInputState!: InputState;
	public authenticationEnabled!: boolean;

	public protocolIndex!: number;

	public modalState!: ModalState;
	public pingStatus!: PingStatus;

	public pageImage!: PageImage;
	public pageStatus!: PageStatus;

	public saveStore = new SaveStore(this);

	public constructor() {
		this.reset();
		makeAutoObservable(this, {}, { autoBind: true });
	}

	reset(resetPageStatus: boolean = true) {
		this.address = '';
		this.addressInputState = InputState.Ok;

		this.port = undefined;
		this.portInputState = InputState.Ok;

		this.login = '';
		this.loginInputState = InputState.Ok;

		this.password = '';
		this.passwordInputState = InputState.Ok;
		this.authenticationEnabled = false;

		this.protocolIndex = 0;
		this.modalState = ModalState.None;
		this.pingStatus = PingStatus.Ok;
		this.pageImage = PageImage.Load;

		if (resetPageStatus) {
			this.pageStatus = PageStatus.Skeleton;
		}

		this.saveStore.reset();
	}

	setPingStatus(pingStatus: PingStatus) {
		this.pingStatus = pingStatus;
	}

	setPageImage(pageImage: PageImage) {
		this.pageImage = pageImage;
	}

	setModalState(modalState: ModalState) {
		this.modalState = modalState;
	}

	setAddress(address: string) {
		this.address = address;
	}

	setPageStatus(status: PageStatus) {
		this.pageStatus = status;
	}

	setAuthenticationState(authorizationEnabled: boolean) {
		try {
			this.authenticationEnabled = authorizationEnabled;
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	toggleAuthenticationState() {
		try {
			this.authenticationEnabled = !this.authenticationEnabled;
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	trySetPort(port: string): boolean {
		if (port === '') {
			this.port = undefined;
			return true;
		}

		let tempPort = parseInt(port);
		if (isNaN(tempPort)) {
			return false;
		}
		this.port = tempPort;

		return true;
	}

	setProtocolIndex(protocolIndex: number) {
		try {
			this.protocolIndex = protocolIndex;
			if (this.authenticationAllowed) {
				return;
			}
			this.authenticationEnabled = false;
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	setPort(port?: number) {
		this.port = port;
	}

	setLogin(login: string) {
		this.login = login;
	}

	setPassword(password: string) {
		this.password = password;
	}

	handleAddress() {
		let addressSplitted = this.address.split(':');
		if (
			addressSplitted.length === 2 &&
			addressRegex.test(addressSplitted[0]) &&
			checkPortValidity(parseInt(addressSplitted[1]))
		) {
			this.setAddress(addressSplitted[0]);
			this.setPort(parseInt(addressSplitted[1]));

			this.addressInputState = InputState.Ok;
			this.portInputState = InputState.Ok;

			this.saveStore.updateSaveAvailableState();

			return;
		}

		this.updateAddressInputState();
	}

	updateAddressInputState() {
		try {
			this.addressInputState = checkState(this.address, e => addressRegex.test(e));
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	updatePortInputState() {
		try {
			this.portInputState = checkState(this.port, e => checkPortValidity(e));
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	updateLoginInputState() {
		try {
			this.loginInputState = checkState(this.login, e => loginRegex.test(e));
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	updatePasswordInputState() {
		try {
			this.passwordInputState = checkState(this.password, e => passwordRegex.test(e));
		} finally {
			this.saveStore.updateSaveAvailableState();
		}
	}

	async preparePage() {
		try {
			logger.info('Подгототавливаем страницу');
			await prepareSettingsPage(
				async () => {
					await proxySettingsStore.saveStore.loadSettings();
					this.setPageImage(PageImage.Gear);
					this.setPageStatus(PageStatus.Ok);
				},
				() => {
					this.setPageImage(PageImage.Attention);
					this.setPageStatus(PageStatus.ConnectionLost);
				},
			);
			logger.info('Закончили подготовку страницы');
		} catch (error) {
			logger.error(`Во время подготовки страницы что-то пошло не так: [${error}]`);
			this.setModalState(ModalState.SomethingWentWrong);
		}
	}

	get authenticationAllowed(): boolean {
		return !(this.protocolIndex === 1 || this.protocolIndex === 2);
	}
}

const proxySettingsStore = new ProxySettingsStore();

export { proxySettingsStore };
