import logger from '@logger';
import { CheckConnectionWs } from '@models/check-connection-ws';

export function checkConnectionWs(url: string, defaultMessage: string = '') {
	return new Promise<CheckConnectionWs>((resolve, reject) => {
		try {
			let socket = new WebSocket(url);
			socket.onopen = function () {
				socket.send(defaultMessage);
			};

			socket.onmessage = function (event) {
				socket.close();
				let res = new CheckConnectionWs();
				res.isConnected = true;
				res.message = JSON.parse(event.data);
				resolve(res);
			};

			socket.onerror = function () {
				socket.close();
				logger.warn(`Ошибка при попытке обращения по адресу ${url}. Возможно отсутствует терминальный ТА!`);
				let res = new CheckConnectionWs();
				res.isConnected = false;
				res.message = {
					error: 'Ошибка при проверке соединения с ТА по веб-сокету!',
				};
				resolve(res);
			};
		} catch (error) {
			logger.warn(`Глобальная ошибка при попытке обращения по адресу ${url}`, error);
			let res = new CheckConnectionWs();
			res.isConnected = false;
			res.message = {
				error: `Ошибка при проверке соединения с ТА по веб-сокету: ${error}!`,
			};
			resolve(res);
		}
	});
}
