import { Page, PageImage } from '@components/page-template';
import styles from './cp-no-actual-license.module.scss';
import { store } from '@features/setup-wizard/stores/store';
import { useState } from 'react';
import { Modal } from '@components/modal';
import { DemoInstallModalCard } from '@components/modal-cards/demo-install-modal-card/demo-install-modal-card';
import { CpLicenseEnterType } from '@features/setup-wizard/enums/cp-license-enter-type';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { installCpStore } from '@features/setup-wizard/stores/install-cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { cryptographyLicense, installCp, updateCpMinorVersionProcess } from '@features/setup-wizard/models/pages';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';

export function CpNoActualLicense() {
	const [showModal, setModalVisibility] = useState(false);

	function getTitle() {
		if (cpStore.setupMode !== CpSetupMode.Install) {
			return 'Не смогли обновить КриптоПро';
		}
		return 'Не смогли установить КриптоПро';
	}

	return (
		<Page
			image={PageImage.Cryptography}
			child={
				<>
					<div>
						<div id={styles.title}>{getTitle()}</div>
						<div id={styles.supportText}>Указанный серийный номер лицензии не действителен</div>
						<div id={styles.buttons}>
							<button
								className={styles.buttonBlue}
								onClick={() => {
									store.setPage(cryptographyLicense());
								}}
							>
								Повторить
							</button>
							{cpStore.enterLicenseWindowType() !== CpLicenseEnterType.NoDemo ? (
								<button
									className={styles.buttonTransparentBlue}
									onClick={() => {
										setModalVisibility(true);
									}}
								>
									Продолжить с демо-лицензией
								</button>
							) : null}
						</div>
					</div>

					<Modal
						children={
							<DemoInstallModalCard
								onBuyNewKeyClick={() => {
									window.open(process.env.REACT_APP_INSTR_UPDATE_LICENSE!, '_blank');
									setModalVisibility(false);
								}}
								onContinueInstallationClick={() => {
									cpLicenseStore.resetLicense();
									if (cpStore.setupMode === CpSetupMode.UpdateMinorVersion) {
										store.setPage(updateCpMinorVersionProcess());
										return;
									}
									installCpStore.restoreToDefault();
									store.setPage(installCp());
								}}
								onCancel={() => setModalVisibility(false)}
							/>
						}
						active={showModal}
						onClickOutOfChild={() => setModalVisibility(false)}
					/>
				</>
			}
		/>
	);
}
