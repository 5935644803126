import logger from '@logger';

export function parseEnumValue<T>(enumType: T, value: string | undefined | null): T[keyof T] | null {
	if (!value) {
		return null;
	}
	// @ts-ignore
	const enumKeys = Object.keys(enumType) as Array<keyof T>;

	for (const key of enumKeys) {
		if (key.toString().toLowerCase() === value.toLowerCase()) {
			return enumType[key];
		}
	}
	logger.warn(`Не удалось спарсить enum. Переданное значение: ${value}!`);
	return null;
}
