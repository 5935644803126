import { observer } from 'mobx-react-lite';
import styles from './enter-os-modal-card.module.scss';
import { ReactComponent as Spinner } from '@assets/icons/spinners/spinner-thin.svg';
import { MouseEventHandler } from 'react';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';

interface EnterOsModalCardElement {
	onClickCrossButton: MouseEventHandler<HTMLButtonElement>;
}

function EnterOsModalCardElement(props: EnterOsModalCardElement) {
	return (
		<InfoModalCardTemplate
			image={<Spinner className={styles.spinner} />}
			title="Войдите в Онлайн-Спринтер"
			onClickCrossButton={props.onClickCrossButton}
			centerCross
			innerWidth={302}
		/>
	);
}

export const EnterOsModalCard = observer(EnterOsModalCardElement);
