import { commonInfo } from '@businessLogic/common-info';
import { VersionInfo } from '@businessLogic/version-info';
import { CheckTerminalStatuses } from '@enums/check-terminal-statuses';
import { CompareVersionResults } from '@enums/compare-version-results';
import { SystemTypes } from '@enums/system-types';
import { delay } from '@helpers/delay-helper';
import { getFromAgentHttp, getFromBackend } from '@helpers/fetch-helper';
import { checkConnectionWs } from '@helpers/ws-connection';
import logger from '@logger';
import { PagePreparationStatus } from '@enums/page-preparation-status';
import { checkTerminalTa } from '@businessLogic/terminal-agent-operation';
import { deserializeJson } from '@helpers/json-helper';
import { AgentVersion } from '@models/api';

export async function preparePage(needCheckOs = true): Promise<PagePreparationStatus> {
	if (needCheckOs) {
		let userAgent = window.navigator.userAgent.toLowerCase();
		logger.info('Проверяем что юзер агент нам подходит!');
		if (commonInfo.getSystemType() === SystemTypes.Others) {
			logger.warn(`User-Agent: [${userAgent}] нам не подходит. Покажем соответсвующее окно!`);
			return PagePreparationStatus.NotSupportedOs;
		}
	}

	logger.info('Проверяем соединение с ТА по защищенному соединению!');
	let terminalExistStatus = await checkTerminalTa();
	switch (terminalExistStatus) {
		case CheckTerminalStatuses.NeedUpdate:
			commonInfo.terminalNeedUpdate = true;
			logger.info('Откроем страницу не актуальной версии с учетом терминального ТА!');
			return PagePreparationStatus.NonActualVersion;
		case CheckTerminalStatuses.ExistsWithoutStandardTA:
			logger.info('Терминальный ТА ответил что агент не зарегистрирован. Предложим юзеру скачать.');
			return PagePreparationStatus.NotFound;
		case CheckTerminalStatuses.CertMissing:
			logger.info('Отсутствует сертификат, необходимый для безопасного соединения с терминальным ТА.');
			return PagePreparationStatus.NoConnectionTerminal;
	}

	let result = await checkConnectionWs(
		`${commonInfo.agentWss}/commandWS`,
		JSON.stringify({ methodName: 'GetVersion' }),
	);
	if (!result.isConnected || !result.message.hasOwnProperty('version')) {
		let agentVersionByHttp = await getAgentVersionByHttp();
		if (agentVersionByHttp === undefined) {
			return PagePreparationStatus.NotFound;
		}
		if (!checkAgentVersionForMinimal(agentVersionByHttp)) {
			return PagePreparationStatus.NonActualVersion;
		}
		return PagePreparationStatus.NoConnection;
	}

	if (!checkVersionIsActual(result.message)) {
		logger.warn('Версия ТА не актуальна!');
		await delay(500);
		return PagePreparationStatus.NonActualVersion;
	}

	if (needCheckOs) {
		if (!(await checkOs())) {
			return PagePreparationStatus.NotSupportedOs;
		}
	} else {
		//Получаем инфу о том какая система чтобы в будущем при необходимости рестарта понимать нужен ли нам Терм ТА
		await commonInfo.getSystemInfo();
	}
	return PagePreparationStatus.Ok;
}

async function getAgentVersionByHttp(): Promise<string | undefined> {
	try {
		let response = await getFromAgentHttp(process.env.REACT_APP_AGENT_EP_VERSION!);
		if (!response.ok) {
			return undefined;
		}
		let agentVersion = deserializeJson(AgentVersion, await response.text());
		if (agentVersion === undefined) {
			// MGA: По идеи такого быть не должно, но будем считать в данном случае,
			// что ТА работает не корректно и юзеру надо его переустановить
			return undefined;
		}
		return agentVersion.version;
	} catch (error) {
		logger.error(`Ошибка во время попытки соединиться по HTTP! Ошибка: ${error}!`);
		return undefined;
	}
}

function checkVersionIsActual(versionJson: any): boolean {
	let version = versionJson.version;
	return checkAgentVersionForMinimal(version);
}

function checkAgentVersionForMinimal(version: string): boolean {
	let inputVersion = VersionInfo.GetVersionDesc(version);
	let confVersion = VersionInfo.GetVersionDesc(process.env.REACT_APP_AGENT_MINIMAL_VERISON!);
	let resOfCheckVersion = VersionInfo.CompareVersion(confVersion, inputVersion);
	logger.info(
		`Результат сравнения версии [${version}] и конфига [${process.env.REACT_APP_AGENT_MINIMAL_VERISON}] = ${resOfCheckVersion}`,
	);
	return resOfCheckVersion !== CompareVersionResults.ConfBigger;
}

async function checkOs() {
	try {
		let systemInfo = await commonInfo.getSystemInfo();
		let response = await getFromBackend(
			`${process.env.REACT_APP_EP_CHECK_SUPPORT!}?architecture=${systemInfo.architecture}&type=${
				systemInfo.type
			}&build=${systemInfo.build}&name=${systemInfo.name}`,
		);
		return (await response.json()).isSupported as boolean;
	} catch (error) {
		logger.error(`Ошибка во время попытки проверить совместимость текущей системы с Такском-Агентом: ${error}!`);
		return false;
	}
}
