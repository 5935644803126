import { Serializable, TypedJSON } from 'typedjson';

const settings = {
	errorHandler: (e: Error) => {
		throw e;
	},
};

export function deserializeJson<T>(rootConstr: Serializable<T>, json: string): T | undefined {
	let serializer = new TypedJSON(rootConstr, settings);
	return serializer.parse(json);
}

export function deserializeJsonAsArray<T>(rootConstr: Serializable<T>, json: string): Array<T> | undefined {
	let serializer = new TypedJSON(rootConstr, settings);
	return serializer.parseAsArray(json);
}

export function serializeToJson<T>(rootConstructor: Serializable<T>, data: T): string {
	let serializer = new TypedJSON(rootConstructor, settings);
	return serializer.stringify(data);
}

export function serializeToJsonArray<T>(rootConstructor: Serializable<T>, data: T[]): string {
	let serializer = new TypedJSON(rootConstructor, settings);
	return serializer.stringifyAsArray(data);
}
