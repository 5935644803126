import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class StagesRelevance {
	@jsonMember(() => Boolean)
	public browser!: boolean;

	@jsonMember(() => Boolean)
	public cryptography!: boolean;

	@jsonMember(() => Boolean)
	public pdf!: boolean;

	@jsonMember(() => Boolean)
	public certs!: boolean;

	@jsonMember(() => Boolean)
	public programComponents!: boolean;
}
