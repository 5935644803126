import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { PlacesType, Tooltip as TooltipInner, TooltipRefProps } from 'react-tooltip';
import styles from './tooltip.module.scss';

export interface TooltipProps {
	id?: string;
	anchorSelect?: string;
	place?: PlacesType;
	clickable?: boolean;
	children: ReactNode;
}

export const Tooltip = forwardRef((props: TooltipProps, ref: ForwardedRef<TooltipRefProps>) => {
	return (
		<TooltipInner
			{...props}
			ref={ref}
			className={styles.hint}
			opacity={1}
		>
			{props.children}
		</TooltipInner>
	);
});
