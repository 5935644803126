import { MouseEventHandler, ReactElement } from 'react';
import styles from './info-modal-card-template.module.scss';
import { ReactComponent as Cross } from '@assets/icons/cross-thin.svg';

interface BasicModalCardTemplateProps {
	title: string;
	info?: ReactElement | string;
	image: ReactElement;
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
	centerCross?: boolean;
	innerWidth?: number;
}

export function InfoModalCardTemplate(props: BasicModalCardTemplateProps) {
	return (
		<div className={styles.card}>
			<div className={styles.imageBlock}>{props.image}</div>
			<div
				className={styles.textBlock}
				style={{ width: props.innerWidth ?? 342 }}
			>
				<header className={styles.title}>{props.title}</header>
				<span className={styles.info}>{props.info}</span>
			</div>
			{props.onClickCrossButton !== undefined ? (
				<button
					className={[styles.crossButton, props.centerCross ? styles.center : null].join(' ')}
					onClick={props.onClickCrossButton}
				>
					<Cross className={styles.cross} />
				</button>
			) : (
				<></>
			)}
		</div>
	);
}
