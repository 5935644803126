import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

@jsonObject
export class TrustedSites {
	@jsonMember(String)
	public domain!: string;

	@jsonMember(Boolean)
	public includeAllSubDomains!: boolean;

	@jsonMember(Boolean)
	public allowHttps!: boolean;

	@jsonArrayMember(String)
	public subDomain!: string[];
}
