import { PagesNavigationGrid } from '@components/pages-navigation-grid';
import { Page, PageImage } from '@components/page-template';
import styles from './settings-root.module.scss';

export function SettingsRoot() {
	return (
		<Page
			image={PageImage.Settings}
			child={
				<>
					<div id={styles.title}>Настройки</div>
					<PagesNavigationGrid />
				</>
			}
			showMobileView={false}
		/>
	);
}
