import styles from './input-with-hint.module.scss';
import { ChangeEventHandler, FocusEventHandler, ReactElement } from 'react';
import { ReactComponent as Info } from '@assets/icons/info.svg';
import { Tooltip, TooltipProps } from '@components/tooltip';

export interface InputWithHintProps {
	tooltip: TooltipProps;
	placeholder?: string;
	className?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	numberInput?: boolean;
	value?: string | number | readonly string[];
	wrong?: boolean;
}

export function InputWithHint(props: InputWithHintProps) {
	return (
		<div className={styles.inputBlock + ' ' + props.className}>
			<input
				className={props.wrong ? styles.wrongInputCustom1 : styles.inputCustom1}
				type={props.numberInput ? 'number' : 'text'}
				placeholder={props.placeholder}
				onChange={props.onChange}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				value={props.value}
			/>
			{props.wrong ? null : (
				<Info
					className={styles.icon}
					data-tooltip-id={props.tooltip.id}
					tabIndex={-1}
				/>
			)}
			<Tooltip {...props.tooltip} />
		</div>
	);
}
