import { jsonMember, jsonObject } from 'typedjson';
import { ShortcutExtended } from './shortcut-extended';

@jsonObject
export class ShortcutRecord extends ShortcutExtended {
	@jsonMember(() => String)
	productID!: string;

	@jsonMember(() => String)
	displayName!: string;

	@jsonMember(() => String)
	utm!: string;
}
