import { RefObject, useLayoutEffect } from 'react';

export const useResizeObserver = (ref: RefObject<HTMLElement>, onResize: (element: Element) => void) => {
	useLayoutEffect(() => {
		let resizeObserver: ResizeObserver | undefined;

		function callback(entries: ResizeObserverEntry[]) {
			entries.forEach(entry => {
				onResize(entry.target);
			});
		}

		if (ref.current && 'ResizeObserver' in window) {
			resizeObserver = new ResizeObserver(callback);
			resizeObserver.observe(ref.current);
		}

		return () => {
			resizeObserver?.disconnect();
		};
	}, [onResize, ref]);
};
