import { Page } from '@components/page-template';
import styles from './auto-import.module.scss';
import { ReactComponent as Sprinter } from '@assets/products-logos/sprinter.svg';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { Modal } from '@features/settings/auto-import/components/modal/modal';
import { ContentBlock } from '@features/settings/auto-import/components/content-block';

function AutoImportElement() {
	useEffect(() => {
		autoImportStore.preparePage();
	}, []);

	return (
		<>
			<Page
				image={autoImportStore.pageImage}
				child={
					<div id={styles.allBlock}>
						<div id={styles.title}>Автозагрузка файлов</div>
						<div id={styles.subtitle}>
							в&nbsp;
							<Sprinter id={styles.sprinterIcon} />
							&nbsp;Онлайн-Спринтер
						</div>
						<ContentBlock />
					</div>
				}
				showMobileView={false}
				fixedHeight
			/>
			<Modal />
		</>
	);
}

export const AutoImport = observer(AutoImportElement);
