import { GetDistrInfo } from '@features/setup-wizard/business-logic/api/get-distr-info';
import { SoftTypes } from '@features/setup-wizard/enums/soft-types';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { postModelToAgentHttps } from '@helpers/fetch-helper';
import { UpdateCpMinorVersionErrorType } from '../enums/update-cp-minor-version-error-type';
import { UpdateCpMinorVersionStage } from '../enums/update-cp-minor-version-stage';
import { CleanFromCpRequestDto } from '../models/dtos/requests/clean-from-cp-request.dto';
import { deleteKeyFromCache } from './ta-cache-logic';

// TODO SMA вероятно вынести в другое место
export async function executeCleanerApi(): Promise<Response> {
	const cleanerDistrInfo = await GetDistrInfo(SoftTypes.CspClean);

	const cleanFromCpRequestDto = new CleanFromCpRequestDto();
	cleanFromCpRequestDto.setupURL = cleanerDistrInfo.linkToDistr;
	cleanFromCpRequestDto.silent = true;

	const response = await postModelToAgentHttps(
		process.env.REACT_APP_AGENT_EP_CLEAN_CRYPTO!,
		CleanFromCpRequestDto,
		cleanFromCpRequestDto,
	);

	return response;
}

export async function executeCleaner(): Promise<void> {
	const response = await executeCleanerApi();
	if (response.status === 403) {
		if (cpStore.currentUpdateCpMinorVersionStage?.kind === UpdateCpMinorVersionStage.Deletion) {
			await deleteKeyFromCache(process.env.REACT_APP_CACHE_KEY_KP_SERIAL!);
		}
		store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.NotEnoughRights);
		return;
	}
	if (!response.ok) {
		store.setUpdateCpMinorVersionErrorPage(UpdateCpMinorVersionErrorType.CommonFailure);
		return;
	}
}
