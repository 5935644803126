import styles from './drop-downs.module.scss';
import React, { ReactElement } from 'react';
import { DropDown } from '@components/drop-down';

export type DropDownData = [title: string, child: ReactElement, id: string];

export interface DropDownsProps {
	elements: DropDownData[];
	openFirst?: boolean;
}

interface DropDownsState {
	chosen: [show: boolean, id: string];
}

export class DropDowns extends React.Component<DropDownsProps, DropDownsState> {
	public constructor(props: DropDownsProps) {
		super(props);
		this.state = {
			chosen: [props.openFirst!, props.elements[0] != null ? props.elements[0][2] : ''],
		};
	}

	public changeDropdownState(key: string, showContent: boolean): void {
		this.setState({ chosen: [showContent, key] });
	}

	public render() {
		return <>{this.getListComponents(this!.props.elements)}</>;
	}

	private getListComponents(elements: DropDownData[]): ReactElement[] {
		return elements.map(element => {
			let key = element[2];
			return (
				<div
					className={styles.listComponent}
					key={key}
				>
					<DropDown
						title={element[0]}
						child={element[1]}
						callback={(showContent: boolean) => this.changeDropdownState(key, showContent)}
						showContent={key === this.state.chosen[1] ? this.state.chosen[0] : false}
					/>
				</div>
			);
		});
	}
}
