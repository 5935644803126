import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class AuthData {
	@jsonMember(() => String)
	login?: string;

	@jsonMember(() => String)
	password?: string;
}
