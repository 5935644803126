export function indexOfCaseInsensitive(array: readonly string[], value: string) {
	return array.findIndex(item => equalCaseInsensitive(value, item));
}

export function equal(value1?: string, value2?: string) {
	if (!value1 && !value2) {
		return true;
	}
	return value1 === value2;
}

export function equalCaseInsensitive(value1?: string, value2?: string) {
	if (!value1 && !value2) {
		return true;
	}
	return value1?.toLowerCase() === value2?.toLowerCase();
}
