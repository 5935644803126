import ContentLoader from 'react-content-loader';

export function ButtonSkeleton(props: { width: number }) {
	return (
		<ContentLoader
			speed={4}
			height={56}
			viewBox={`0 0 ${props.width} 56`}
			backgroundColor="#F2F5FA"
			foregroundColor="#f7f9fc"
			title={'Загрузка...'}
		>
			<rect
				x="0"
				y="0"
				rx="8"
				ry="8"
				width={props.width}
				height="56"
			/>
		</ContentLoader>
	);
}
