export enum CryptoCheckResult {
	NotFound,
	LicenseExpired,
	UpdateNotRequired,

	UpdateAnnualLicense,
	UpdatePerpetualLicense,

	UpdateMinorVersion,
	UpdateMinorVersionWithNewLicense,

	VipnetUpdateAnnualLicense,
	VipnetUpdatePerpetualLicense,
}
