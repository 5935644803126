import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ProductInfo {
	@jsonMember(() => String)
	public productId!: string;

	@jsonMember(() => String)
	public buttonText!: string;
}
