import { MouseEventHandler } from 'react';
import styles from './demo-install-modal-card.module.scss';
import { ActionModalCardTemplate } from '../action-modal-card-template';

export interface DemoInstallModalCardElementModalCardProps {
	onContinueInstallationClick: MouseEventHandler<HTMLButtonElement>;
	onBuyNewKeyClick: MouseEventHandler<HTMLButtonElement>;
	onCancel: MouseEventHandler<HTMLButtonElement>;
}

export function DemoInstallModalCard(props: DemoInstallModalCardElementModalCardProps) {
	return (
		<ActionModalCardTemplate
			title="Выбрана установка без указания лицензионного ключа"
			onClickCrossButton={event => props.onCancel(event)}
			body={
				<div className={styles.content}>
					<div>
						Если вы уже использовали КриптоПро с демо-лицензией на этом компьютере,&nbsp;то купите новый
						ключ для продолжения работы
					</div>
				</div>
			}
			footerButtons={[
				<button
					className={styles.buttonBlue}
					onClick={props.onContinueInstallationClick}
				>
					Продолжить установку
				</button>,
				<button
					className={styles.buttonTransparentBlue}
					onClick={props.onBuyNewKeyClick}
				>
					Купить новый ключ
				</button>,
			]}
		/>
	);
}
