import { RestartPCResult } from '@enums/restart-pc-result';
import { postToAgentHttps } from '@helpers/fetch-helper';
import { RestartPCType } from '@enums/restart-pc-type';

export async function restartPC(
	typeOfRestart: RestartPCType,
	additionalParameters?: Map<string, string>,
): Promise<RestartPCResult> {
	try {
		let response: Response;
		const url = `${process.env.REACT_APP_AGENT_EP_RESTART_SYSTEM!}?event=${RestartPCType[typeOfRestart]}`;
		response = await postToAgentHttps(
			url,
			additionalParameters ? JSON.stringify(Object.fromEntries(additionalParameters)) : undefined,
		);

		if (response.status === 200) {
			return RestartPCResult.Success;
		} else if (response.status === 403) {
			return RestartPCResult.NoAccess;
		} else {
			return RestartPCResult.AgentError;
		}
	} catch (e) {
		//MGA не считаем ошибкой ибо запрос может отработать, но браузер не корректно его обработает
		return RestartPCResult.Success;
	}
}
