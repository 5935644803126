import styles from './footer.module.scss';
import { ReactComponent as HelpIcon } from '@assets/help.svg';

export interface FooterProps {
	showMobileView: boolean;
}

export function Footer(props: FooterProps) {
	let main = props.showMobileView ? [styles.footer, styles.mobile].join(' ') : styles.footer;

	let currentYear = new Date().getFullYear();

	return (
		<div className={main}>
			<div className={styles.label}>© ООО «Такском» {currentYear}</div>
			<a
				className={styles.helpButton}
				href={process.env.REACT_APP_INSTR_GLOBAL!}
				target={'_blank'}
				rel="noreferrer"
			>
				<HelpIcon className={styles.helpIcon} />
				<div className={styles.helpText}>Помощь</div>
			</a>
		</div>
	);
}
