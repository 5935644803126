import { CryptoProviderType } from '@features/update-cp-minor-version/enums/crypto-provider-type';
import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DeleteCpDistrRequestDto {
	@jsonMember(() => String)
	public type!: CryptoProviderType;

	@jsonMember(Boolean)
	public silent!: boolean;
}
