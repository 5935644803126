import { CheckTerminalStatuses } from '@enums/check-terminal-statuses';
import logger from '@logger';
import { ProtocolTypes } from '@enums/protocol-types';
import { CheckConnectionWs } from '@models/check-connection-ws';
import { checkConnectionWs } from '@helpers/ws-connection';
import { TerminalAgentErrorTypes } from '@enums/terminal-agent-error-types';
import { delay } from '@helpers/delay-helper';
import { commonInfo } from './common-info';

export async function checkTerminalTa(): Promise<CheckTerminalStatuses> {
	let response = await checkWsConnection(commonInfo.masterWss);
	if (!response.isConnected) {
		if ((await checkWsConnection(commonInfo.masterWs)).isConnected) {
			logger.info('Подключились к терминальному ТА по WS');
			return CheckTerminalStatuses.CertMissing;
		}

		logger.info('Не удалось получить информацию о терминальном ТА. Будем использовать дефолтные порты!');
		return CheckTerminalStatuses.NotExist;
	}
	logger.info('Подключились к терминальному ТА по WSS');

	if (
		response.message.Error === TerminalAgentErrorTypes.ClientSessionError ||
		response.message.Error === TerminalAgentErrorTypes.UnregisteredAgentError
	) {
		logger.warn(
			'При попытке работы с терминальным ТА произошла ошибка. Считаем что терминальный ТА есть, а обычного ТА нет.',
		);
		return CheckTerminalStatuses.ExistsWithoutStandardTA;
	}

	if (!isProtocolActual(response.message.ProtocolVersion) || !setPorts(response.message.Endpoint)) {
		return CheckTerminalStatuses.NeedUpdate;
	}

	return CheckTerminalStatuses.Exist;
}

function setPorts(ports: any): boolean {
	if (ports == null) {
		logger.error('Нарушение протокола общения! Не передано описание точек входа!');
		return false;
	}

	if (
		commonInfo.setPort(ProtocolTypes.Http, ports.HttpPort) &&
		commonInfo.setPort(ProtocolTypes.Https, ports.HttpsPort) &&
		commonInfo.setPort(ProtocolTypes.Wss, ports.WebSocketPort)
	) {
		logger.info('Используем порты ТА от терминального', ports);
		return true;
	}

	logger.error(`Не удалось достать все необходимые порты из модели: ${JSON.stringify(ports)}`);
	return false;
}

function isProtocolActual(protocol: any): boolean {
	if (protocol == null) {
		logger.warn(`Протокол в полученной модели равен: ${protocol}`);
		return false;
	}

	let protocolNumber: number = protocol;
	let protocolSettingNumber: number = +process.env.REACT_APP_TERMINAL_AGENT_PROTOCOL_MINIMUM_VERSION!;

	if (protocolNumber < protocolSettingNumber) {
		logger.warn(
			`Версия протокола от терминального ТА (${protocolNumber}) ниже, чем в настройках: ${protocolSettingNumber}`,
		);
		return false;
	}

	return true;
}

async function checkWsConnection(url: string): Promise<CheckConnectionWs> {
	let checksCount = parseInt(process.env.REACT_APP_CHECK_TERMINAL_AGENT_COUNT!);
	let checkDelay = parseInt(process.env.REACT_APP_CHECK_TERMINAL_AGENT_DELAY!);

	let res: CheckConnectionWs = new CheckConnectionWs();
	for (let i = 0; i < checksCount; i++) {
		res = await checkConnectionWs(url);
		if (!res.isConnected) {
			return res;
		}

		if (res.message.Error !== TerminalAgentErrorTypes.AgentNotInitialized) {
			return res;
		}

		if (i !== checksCount - 1) {
			await delay(checkDelay);
		}
	}
	return res;
}
