import styles from './no-connection.module.scss';
import { v4 as getUuid } from 'uuid';
import { DropDownData } from '@components/drop-downs';
import { InstructionLink } from '@components/instruction-link';

export const WindowsElements: DropDownData[] = [
	[
		'Не тот протокол Такском‑Агента',
		<div className={styles.dropDownContent}>
			1. Откройте окно с программами в правом нижнем углу экрана.
			<br />
			2. Нажмите правой кнопкой на иконку Агента.
			<br />
			3. В меню выберите «Настроить...» и нажмите «Сменить протокол».
			<br />
			<img
				className={styles.imgForSupport}
				src={require('@assets/instructions/change-protocol.png')}
				alt={'ChangeProtocol'}
			/>
		</div>,
		getUuid(),
	],
	[
		'Не установлен корневой сертификат',
		<div className={styles.dropDownContent}>
			1. Откройте окно с программами в правом нижнем углу экрана.
			<br />
			2. Нажмите правой кнопкой на иконку Агента.
			<br />
			3. В меню выберите «Настроить...» и нажмите «Установить корневой сертификат».
			<br />
			<img
				className={styles.imgForSupport}
				src={require('@assets/instructions/install-root-cert.png')}
				alt={'InstallRootCert'}
			/>
		</div>,
		getUuid(),
	],
	[
		'Сброшено соединение с Такском‑Агентом',
		<div className={styles.dropDownContent}>
			Чаще всего это происходит из-за антивируса. Откройте его настройки и отключите защиту от веб-угроз. Но если
			у вас Windows 11, проблема может быть из-за версии Крипто-Про. Установите последнюю версию программы.
		</div>,
		getUuid(),
	],
];

export const LinuxElements: DropDownData[] = [
	[
		'Не установлен корневой сертификат',
		<div className={styles.dropDownContent}>
			Установите сертификат по{' '}
			<InstructionLink
				link={process.env.REACT_APP_INSTR_INSTALL_CERT_LINUX!}
				text={'инструкции'}
			/>
		</div>,
		getUuid(),
	],
];
