import styles from './non-actual-version.module.scss';
import { DownloadHint } from '@components/modal-cards/download-hint';
import { useState } from 'react';
import { prepareInstallApiLink } from '@businessLogic/install-link-preparator';
import { Page, PageImage } from '@components/page-template';
import { commonInfo } from '@businessLogic/common-info';

export function NonActualVersion() {
	const [modalActive, setModalState] = useState(false);

	let textOfUpdate = commonInfo.terminalNeedUpdate
		? 'Пожалуйста, обновите обе версии программы: обычную и терминальную, скачав и установив их последние релизы'
		: 'Пожалуйста, обновите версию программы, скачав и установив ее последний релиз';

	async function downloadAgent() {
		window.open(await prepareInstallApiLink(), '_blank');
		setModalState(true);
	}

	return (
		<Page
			image={PageImage.Error}
			child={
				<>
					<div id={styles.title}>Обновите Такском-Агент</div>
					<div
						id={styles.subtitle}
						style={!commonInfo.terminalNeedUpdate ? { maxWidth: '449px' } : undefined}
					>
						{textOfUpdate}
					</div>
					<div className={styles.btnArea}>
						<button
							onClick={downloadAgent}
							className={styles.buttonBlue}
						>
							Скачать Такском-Агент
						</button>
						{commonInfo.terminalNeedUpdate && (
							<a
								href={process.env.REACT_APP_DOWNLOAD_TEMINAL_AGENT!}
								target="_blank"
								rel="noreferrer"
								id={styles.aTerminal}
							>
								Терминальная&nbsp;версия
							</a>
						)}
					</div>
				</>
			}
			modalCard={<DownloadHint />}
			modalActive={modalActive}
			onClickOutOfModalCard={() => setModalState(false)}
			modalStyle={styles.modal}
			showMobileView={false}
		/>
	);
}
