import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CurrentDateTime {
	@jsonMember(() => Date)
	public datetime!: Date;

	@jsonMember(() => Number)
	public timestamp!: number;
}
