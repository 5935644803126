import { Page, PageImage } from '@components/page-template';
import styles from './cp-install-error-when-vipnet.module.scss';
import { InstructionLink } from '@components/instruction-link';
import { store } from '@features/setup-wizard/stores/store';
import { setupSucceeded } from '@features/setup-wizard/models/pages';

export function CpInstallErrorWhenVipnet() {
	return (
		<Page
			image={PageImage.Warning}
			child={
				<div>
					<div id={styles.title}>Не смогли обновить КриптоПро</div>
					<div id={styles.supportText}>
						Установите КриптоПро CSP 5 самостоятельно,
						<br /> воспользовавшись{' '}
						<InstructionLink
							link={process.env.REACT_APP_INSTR_UPDATE_UPDATE_VIPNET!}
							text={'инструкцией'}
						/>
					</div>
					<button
						className={styles.buttonBlue}
						onClick={() => {
							store.setPage(setupSucceeded());
						}}
					>
						Не обновлять КриптоПро
					</button>
				</div>
			}
		/>
	);
}
