import styles from './drop-down.module.scss';
import { ReactComponent as Chevron } from '@assets/icons/chevron.svg';
import { ReactElement } from 'react';

export interface DropDownProps {
	title: string;
	child: ReactElement;
	callback: (showContent: boolean) => void;
	showContent: boolean;
}

export function DropDown(props: DropDownProps) {
	return (
		<div id={styles.dropDown}>
			<div
				id={styles.header}
				onClick={() => props.callback(!props.showContent)}
			>
				<span>{props.title}</span>
				<Chevron
					className={
						props.showContent ? [styles.chevron, styles.chevronShowContent].join(' ') : styles.chevron
					}
				/>
			</div>
			{props.showContent ? <div id={styles.child}>{props.child}</div> : <></>}
		</div>
	);
}
