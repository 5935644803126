import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class CryptographyLogInfo {
	@jsonMember(String)
	public machineName!: string;

	@jsonMember(String)
	public userName!: string;

	@jsonMember(String)
	public cryptoProVersion!: string;
}
