import { getModelFromBackend, getModelsArrayFromAgentHttps } from '@helpers/fetch-helper';
import { isNullOrEmpty } from '@helpers/string-helper';
import { CurrentDateTime } from '@models/api';
import { Cert } from '@models/api/cert';

const certsWithoutQesSupportingLimit = new Date(2024, 8, 1);

export async function needToShowOnlyNewQesCerts() {
	const currentDateTime = await getModelFromBackend(CurrentDateTime, process.env.REACT_APP_EP_CURRENT_DATETIME!);
	return currentDateTime.datetime >= certsWithoutQesSupportingLimit;
}

export async function getCerts(searchString?: string): Promise<Cert[]> {
	let endpoint = process.env.REACT_APP_AGENT_EP_SYSTEM_CERT!;
	if (!isNullOrEmpty(searchString)) {
		endpoint = endpoint + `?searchString=${searchString}`;
	}
	return await getModelsArrayFromAgentHttps(Cert, endpoint);
}
