import { CompareVersionResults } from '@enums/compare-version-results';

// TODO SMA вроде как была добавлена библиотека для этого дела. Решить нужно ли вообще или нужна ли библиотека
export class VersionInfo {
	public major: number;
	public minor: number;
	public micro: number;

	constructor(majorNumber: number, minorNumber: number, microNumber: number) {
		this.major = majorNumber;
		this.minor = minorNumber;
		this.micro = microNumber;
	}

	static GetVersionDesc(versionNumber: string): VersionInfo {
		let splitInfo = versionNumber.split('.');
		let firstNumber = parseInt(splitInfo[0]);
		let secondNumber = parseInt(splitInfo[1]);
		let thirdNumber = parseInt(splitInfo[2]);
		if (isNaN(thirdNumber)) thirdNumber = 0;
		if (isNaN(secondNumber)) secondNumber = 0;
		if (isNaN(firstNumber)) firstNumber = 0;
		return new VersionInfo(firstNumber, secondNumber, thirdNumber);
	}

	static CompareVersion(confNumber: VersionInfo, inputNumber: VersionInfo): CompareVersionResults {
		if (
			confNumber.minor === inputNumber.minor &&
			confNumber.major === inputNumber.major &&
			confNumber.micro === inputNumber.micro
		)
			return CompareVersionResults.Equal;
		if (inputNumber.major === confNumber.major && inputNumber.minor > confNumber.minor) {
			return CompareVersionResults.InputBigger;
		}
		if (confNumber.major > inputNumber.major) {
			return CompareVersionResults.ConfBigger;
		}
		if (confNumber.minor > inputNumber.minor) {
			return CompareVersionResults.ConfBigger;
		}
		if (confNumber.micro > inputNumber.micro) {
			return CompareVersionResults.ConfBigger;
		}
		return CompareVersionResults.InputBigger;
	}
}
