import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SaveToCacheRequestDto {
	@jsonMember(String)
	public key!: string;

	@jsonMember(String)
	public value!: string;

	@jsonMember(Boolean)
	public oneTimeAccess!: boolean;
}
