import logger from '@logger';
import { useEffect } from 'react';

export function useOrgQueryParams(callback: (type: string, orgId: string) => void) {
	const url = new URL(window.location.href);
	const type = url.searchParams.get('type');
	const orgId = url.searchParams.get('orgid');

	function handleSearchParams() {
		try {
			if (!type || !orgId) {
				return;
			}
			callback(type, orgId);
		} catch (error) {
			logger.error(`При обработке query параметров [${url.search}] возникла ошибка: [${error}]`);
		} finally {
			url.search = '';
			window.history.replaceState(null, '', url);
		}
	}

	useEffect(() => {
		handleSearchParams();
	}, []);
}
