import { CreateShortcutState } from '../enums/create-shortcut-state';
import { ShortcutRecord } from './api/shortcut-record';

export class ProductInfo extends ShortcutRecord {
	createShortcutState: CreateShortcutState;

	constructor(shortcutRecord: ShortcutRecord) {
		super();
		this.productID = shortcutRecord.productID;
		this.name = shortcutRecord.name;
		this.iconPath = shortcutRecord.iconPath;
		this.displayName = shortcutRecord.displayName;
		this.targetUrl = shortcutRecord.targetUrl;
		this.utm = shortcutRecord.utm;

		this.createShortcutState = CreateShortcutState.NotAvailable;
	}
}
