import { Modal as BasicModal } from '@components/modal';
import { DeleteOrganizationModalCard } from '@features/settings/auto-import/components/modal-cards/delete-organization-modal-card';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { ModalState } from '../../enums/modal-state';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { OrganizationAlreadySetUpModalCard } from '@features/settings/auto-import/components/modal-cards/organization-already-set-up-modal-card';
import { DirectoryInUseModalCard } from '@features/settings/auto-import/components/modal-cards/directory-in-use-modal-card/directory-in-use-modal-card';
import { NoAccessToDirectoryModalCard } from '@features/settings/auto-import/components/modal-cards/no-access-to-directory-modal-card/no-access-to-directory-modal-card';
import { AuthErrorModalCard } from '@features/settings/auto-import/components/modal-cards/auth-error-modal-card/auth-error-modal-card';
import { NotSameOrgModalCard } from '@features/settings/auto-import/components/modal-cards/not-same-org-modal-card/not-same-org-modal-card';
import { InnerServerErrorModalCard } from '@components/modal-cards/inner-server-error-modal-card';
import { EnterOsModalCard } from '@features/settings/auto-import/components/modal-cards/enter-os-modal-card';

export function ModalElement() {
	function refreshPage() {
		window.location.reload();
	}

	async function selectOtherDirectory() {
		autoImportStore.setModal(ModalState.None);
		await autoImportStore.selectDirectory();
	}

	function openSupport() {
		window.open(process.env.REACT_APP_TAX_SUPPORT!, '_blank', 'noreferrer');
		autoImportStore.setModal(ModalState.None);
		autoImportStore.setReAuthOrgId('');
	}

	async function makeAuth() {
		await autoImportStore.makeOauth();
	}

	function onClickOffAuthErrorModal() {
		autoImportStore.setReAuthOrgId('');
		onClickOutOfChild();
	}

	function getModalCard(): ReactElement {
		switch (autoImportStore.modalState) {
			case ModalState.OrgDouble:
				return (
					<OrganizationAlreadySetUpModalCard
						organizationName={autoImportStore.orgNameToModal!}
						onClickCrossButton={onClickOutOfChild}
						onClickOkButton={onClickOutOfChild}
					/>
				);
			case ModalState.DeleteOrg:
				return (
					<DeleteOrganizationModalCard
						info={autoImportStore.orgNameToModal!}
						onClickCrossButton={onClickOutOfChild}
						onClickCancelButton={onClickOutOfChild}
					/>
				);
			case ModalState.WrongDirectory:
				return (
					<NoAccessToDirectoryModalCard
						commonButtonEvent={onClickOutOfChild}
						pathToDirectory={autoImportStore.pathToDirectoryModal}
						newSelectEvent={selectOtherDirectory}
					/>
				);
			case ModalState.DirectoryAlreadyInUse:
				return (
					<DirectoryInUseModalCard
						closeModalEvent={onClickOutOfChild}
						pathToDirectory={autoImportStore.pathToDirectoryModal}
						newSelectEvent={selectOtherDirectory}
					/>
				);
			case ModalState.EnterOs:
				return <EnterOsModalCard onClickCrossButton={() => autoImportStore.stopOauth()} />;
			case ModalState.AuthError:
				return (
					<AuthErrorModalCard
						closeButtonEvent={onClickOffAuthErrorModal}
						openSupportEvent={openSupport}
						retryAuthEvent={makeAuth}
					/>
				);
			case ModalState.NotSameOrg:
				return (
					<NotSameOrgModalCard
						closeButtonEvent={onClickOffAuthErrorModal}
						retryAuthEvent={makeAuth}
					/>
				);
			case ModalState.SomethingWentWrong:
				return <InnerServerErrorModalCard onClickCrossButton={onClickOutOfChild} />;
			default:
				return <></>;
		}
	}

	function onClickOutOfChild(): void {
		switch (autoImportStore.modalState) {
			case ModalState.SomethingWentWrong:
				refreshPage();
				break;
			case ModalState.EnterOs:
				break;
			default:
				autoImportStore.setModal(ModalState.None);
		}
	}

	return (
		<BasicModal
			active={autoImportStore.modalState !== ModalState.None}
			children={getModalCard()}
			onClickOutOfChild={onClickOutOfChild}
		/>
	);
}

export const Modal = observer(ModalElement);
