import styles from './inner-server-error-modal-card.module.scss';
import { ReactComponent as Cross } from '@assets/icons/cross-thin.svg';
import { ReactComponent as Exclamation } from '@assets/icons/exclamation-circle.svg';
import { MouseEventHandler } from 'react';

export interface InnerServerErrorModalCardProps {
	onClickCrossButton?: MouseEventHandler<HTMLButtonElement>;
}

export function InnerServerErrorModalCard(props: InnerServerErrorModalCardProps) {
	return (
		<div className={styles.card}>
			<button
				className={styles.crossButton}
				onClick={props.onClickCrossButton}
			>
				<Cross className={styles.cross} />
			</button>
			<Exclamation className={styles.exclamation} />
			<span className={styles.title}>Внутренняя ошибка сервера</span>
		</div>
	);
}
