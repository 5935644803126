import { StageInfo } from './stage-info';

export class StageInfoWithTextContent extends StageInfo {
	public content: string;

	public constructor(title: string, content: string) {
		super(title);
		this.content = content;
	}
}
