import styles from './org-card-template.module.scss';
import { ToggleSwitch2 } from '@components/toggle-switch2';
import { ChangeEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import { ReactComponent as Trash } from '@assets/icons/trash.svg';
import { ReactComponent as Pen } from '@assets/icons/pen.svg';

interface Props {
	content: ReactElement;

	onEdit?: () => void;
	onDelete?: () => void;
	onToggle?: ChangeEventHandler<HTMLInputElement>;

	checked: boolean;
	error?: boolean;
	highlight?: boolean;
}

export function OrgCardTemplate(props: Props) {
	const selectedOrgCardRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (props.highlight) {
			selectedOrgCardRef.current?.scrollIntoView();
		}
	}, [props.highlight]);

	function getStyle(): string | undefined {
		if (!props.checked) {
			return styles.disabled;
		}
		if (props.error) {
			return styles.error;
		}
		return undefined;
	}

	function getHighlightStyle(): string | undefined {
		return props.highlight ? styles.highlighted : undefined;
	}

	return (
		<div
			className={[styles.commonBlock, getStyle(), getHighlightStyle()].join(' ')}
			ref={selectedOrgCardRef}
		>
			<div id={styles.switchBlock}>
				<ToggleSwitch2
					checked={props.checked}
					onToggle={props.onToggle}
					disabled={props.error}
				/>
			</div>
			<div className={styles.content}>
				{props.content}
				<div className={[styles.actionBlock, getStyle()].join(' ')}>
					<button
						id={styles.penButton}
						onClick={props.onEdit}
					>
						<Pen />
					</button>
					<button
						id={styles.trashButton}
						onClick={props.onDelete}
					>
						<Trash />
					</button>
				</div>
			</div>
		</div>
	);
}
