import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class DeleteCpDistr500ResponseDto {
	@jsonMember(Boolean)
	public systemError!: boolean;

	@jsonMember(Boolean)
	public userCancel!: boolean;

	@jsonMember(Boolean)
	public globalError!: boolean;

	@jsonMember(Boolean)
	public otherError!: boolean;

	@jsonMember(Number)
	public exitCode!: number;
}
