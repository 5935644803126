import styles from './no-connection.module.scss';
import { CheckConnectionButton } from '@components/check-connection-button';
import { ErrorMain } from '@components/error-main';
import { LinuxElements, WindowsElements } from '@pages/no-connection/basic/elements';
import { Page, PageImage } from '@components/page-template';

export function NoConnection() {
	return (
		<Page
			image={PageImage.Error}
			child={
				<>
					<ErrorMain
						windowsElements={WindowsElements}
						linuxElements={LinuxElements}
						title={'Нет связи с Такском‑Aгентом'}
						switchOsText={'Возможные причины для'}
					/>
					<div className={styles.btnArea}>
						<CheckConnectionButton />
					</div>
				</>
			}
			showMobileView={false}
		/>
	);
}
