import { ModalStore } from '../../../../../stores/modal-store';
import { CheckCertModalCardTemplate } from '../../../../settings/auto-sign/common/modals/check-cert-modal-card-template';

export interface Props {
	modalStore: ModalStore;
}

export const showCertNotAttachedToOrgModal = (props: Props) => {
	props.modalStore.show({
		children: (
			<CheckCertModalCardTemplate
				title="Cертификат не привязан к&nbsp;организации"
				onClickCrossButton={props.modalStore.hide}
			/>
		),
		onClickOutOfChild: props.modalStore.hide,
		active: true,
	});
};
