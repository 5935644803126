import styles from './page.module.scss';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { MouseEventHandler, ReactElement } from 'react';
import { Footer } from '@components/footer';
import { PageImage } from './page-image';
import { PageImage as PageImageEnum } from './page-image-enum';
import { Modal } from '@components/modal';

export interface PageProps {
	image: PageImageEnum;
	child: ReactElement;
	showMobileView?: boolean;
	splitScreenClassName?: string;
	leftPaneClassName?: string;
	rightPaneClassName?: string;
	rightPaneContentClassName?: string;
	fixedHeight?: boolean;

	modalCard?: ReactElement;
	modalActive?: boolean;
	onClickOutOfModalCard?: MouseEventHandler<HTMLDivElement>;
	modalStyle?: string;
}

export function Page(props: PageProps) {
	let main = [styles.main, props.showMobileView ? styles.mobile : undefined].join(' ');

	function openSettingsPage() {
		window.location.href = '/settings';
	}

	return (
		<div className={main}>
			<header className={styles.header}>
				<button
					className={styles.helpButton}
					onClick={openSettingsPage}
				>
					<Logo className={styles.logo} />
				</button>
				<button
					className={styles.productName}
					onClick={openSettingsPage}
				>
					Мастер настройки
				</button>
			</header>
			<div
				className={[
					styles.splitScreen,
					props.splitScreenClassName,
					props.fixedHeight ? styles.fixedHeight : undefined,
				].join(' ')}
			>
				<div className={[styles.leftPane, props.leftPaneClassName].join(' ')}>
					{<PageImage image={props.image} />}
				</div>
				<div className={[styles.rightPane, props.rightPaneClassName].join(' ')}>
					<div className={styles.additionalLateralArea} />
					<div className={[styles.content, props.rightPaneContentClassName].join(' ')}>{props.child}</div>
					<div className={styles.additionalLateralArea} />
				</div>
			</div>
			<Footer showMobileView={props.showMobileView ? true : false} />
			{props.modalCard && (
				<Modal
					children={props.modalCard}
					active={props.modalActive ?? false}
					style={props.modalStyle}
					onClickOutOfChild={props.onClickOutOfModalCard}
				/>
			)}
		</div>
	);
}
