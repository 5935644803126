import { preparePage } from '@businessLogic/page-preparations';
import { PagePreparationStatus } from '@enums/page-preparation-status';
import { PagesSettings } from '../enums/pages-settings';
import { storeSettings } from '../stores/store-settings';

// TODO SMA подумать над рефакторингом
export async function prepareSettingsPage(onSuccess: () => Promise<void>, onFail: () => void) {
	let preparationResult = await preparePage(false);
	switch (preparationResult) {
		case PagePreparationStatus.Ok:
			await onSuccess();
			break;
		case PagePreparationStatus.NonActualVersion:
			storeSettings.setPage(PagesSettings.NonActualVersion);
			break;
		default:
			onFail();
			break;
	}
}
