import { TaConnectionLostCard } from '@features/settings/common/components/ta-connection-lost-card';
import { PageStatus } from '@features/settings/common/enums/page-body-status';
import { observer } from 'mobx-react-lite';
import { autoImportStore } from '@features/settings/auto-import/stores/auto-import-store';
import { ContentNormal } from './content-normal';
import { CardBlockSkeleton } from '@features/settings/common/components/skeletons/cards-block-skeleton';
import styles from './content-block.module.scss';

function ContentBlockElement() {
	switch (autoImportStore.pageStatus) {
		case PageStatus.Ok:
			return <ContentNormal />;
		case PageStatus.Skeleton:
			return <CardBlockSkeleton />;
		case PageStatus.ConnectionLost:
			return (
				<div className={styles.connectionLostCard}>
					<TaConnectionLostCard />
				</div>
			);
	}
}

export const ContentBlock = observer(ContentBlockElement);
