import { InstallCpState } from '@features/setup-wizard/enums/install-cp-state';
import { makeAutoObservable } from 'mobx';
import logger from '@logger';
import { CryptographyStage } from '@stages/cryptography-stage';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { CryptoInstallResult } from '@features/setup-wizard/enums/crypto-install-result';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { updateCryptoProvider, installCryptoProvider } from '../business-logic/api/crypto/crypto';
import { store } from './store';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { CpSetupMode } from '../enums/cp-setup-mode';
import { cpStore } from './cp-store';
import { installCpError, downloadCpError, noAccessToInstallCp, cpNoActualLicense } from '../models/pages';

class InstallCpStore {
	currentUpdateState: InstallCpState = InstallCpState.Install;

	constructor() {
		makeAutoObservable(this);
		this.restoreToDefault();
	}

	restoreToDefault() {
		this.currentUpdateState = InstallCpState.Install;
	}

	//Обработка данных

	setNextState(nextState: InstallCpState) {
		this.currentUpdateState = nextState;
	}

	async installCp() {
		let cryptoStage = stagesWorker.getIfType(StageTypes.Cryptography) as CryptographyStage;
		if (this.currentUpdateState !== InstallCpState.Install) {
			logger.warn('Вызвали метод обновления не в том состоянии чтобы выполнялось обновление!');
			return;
		}
		try {
			let cryptOperateResult: CryptoInstallResult;

			if (cpStore.setupMode !== CpSetupMode.Install) {
				logger.info('Обновляем крипто провайдер');
				cryptOperateResult = await updateCryptoProvider();
			} else {
				logger.info('Устанавливаем крипто провайдер');
				cryptOperateResult = await installCryptoProvider();
			}

			if (cryptOperateResult !== CryptoInstallResult.Success) {
				cpLicenseStore.setNotificationStatus(false);
			}

			switch (cryptOperateResult) {
				case CryptoInstallResult.Success:
				case CryptoInstallResult.CryptoAlreadyInstall:
					cryptoStage.needInstall = false;
					this.setNextState(InstallCpState.RestartRequired);
					return;
				case CryptoInstallResult.NotSuccess:
					store.setPage(installCpError());
					break;
				case CryptoInstallResult.NotNormalLicenseInstallDemo:
				case CryptoInstallResult.NotNormalLicense:
					cpLicenseStore.resetLicense();
					store.setPage(cpNoActualLicense());
					break;
				case CryptoInstallResult.ErrorWhenReceiveInstaller:
					store.setPage(downloadCpError());
					return;
				case CryptoInstallResult.AccessDenied:
					store.setPage(noAccessToInstallCp());
					break;
				case CryptoInstallResult.InstallerAlreadyRun:
					store.setPage(installCpError());
					break;
			}
		} catch (ex) {
			logger.error(`Неожиданная ошибка при попытке работать с крипто провайдером ${ex}`);
			store.setPage(installCpError());
			return;
		}
	}
	//Обработка данных
}

export const installCpStore = new InstallCpStore();
