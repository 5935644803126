import styles from './create-shortcuts-failure-modal-card.module.scss';
import { observer } from 'mobx-react-lite';
import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { MouseEventHandler } from 'react';

export interface CreateShortcutsFailureModalCardProps {
	onCancel: MouseEventHandler<HTMLButtonElement>;
}

function CreateShortcutsFailureModalCardElement(props: CreateShortcutsFailureModalCardProps) {
	return (
		<InfoModalCardTemplate
			title={'Не удалось создать ярлыки на рабочем столе'}
			image={
				<img
					className={styles.image}
					src={require('@assets/computer/icons/crossed-out-circle.png')}
					alt={'attention'}
				/>
			}
			onClickCrossButton={e => props.onCancel(e)}
			innerWidth={362}
		/>
	);
}

export const CreateShortcutsFailureModalCard = observer(CreateShortcutsFailureModalCardElement);
