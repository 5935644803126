import styles from './setup.module.scss';
import { delay } from '@helpers/delay-helper';
import { Page, PageImage } from '@components/page-template';
import { store } from '@features/setup-wizard/stores/store';
import { useState, useMemo, useEffect, ReactElement } from 'react';
import { StageTypes } from '@features/setup-wizard/enums/stage-types';
import { Stage } from '@features/setup-wizard/models/stages';
import { CertInstallationHint } from '@features/setup-wizard/components/cert-installation-hint';
import { stagesWorker } from '@features/setup-wizard/business-logic/stages-worker';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { commonInfo } from '@businessLogic/common-info';
import { SystemTypes } from '@enums/system-types';
import { CryptographyStage } from '@features/setup-wizard/business-logic/stages/cryptography-stage';
import { ProgressList } from '@components/progress-list';
import {
	installCp,
	installedCp5Demo,
	setupFailed,
	setupSucceeded,
	updateCpMinorVersionStart,
} from '@features/setup-wizard/models/pages';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { PageType } from '@features/setup-wizard/enums/page-type';

function getNextPage(): PageType {
	const cryptographyStage = stagesWorker.getIfType(StageTypes.Cryptography) as CryptographyStage;
	if (commonInfo.getSystemType() !== SystemTypes.Linux && cryptographyStage && cryptographyStage.needInstall) {
		if (cpStore.setupMode === CpSetupMode.UpdateMinorVersion) {
			return updateCpMinorVersionStart();
		}
		return installCp();
	}

	if (
		commonInfo.getSystemType() === SystemTypes.Linux &&
		!cryptographyStage.needInstall &&
		cpLicenseStore.scheduledEnterCpKey
	) {
		return installedCp5Demo();
	}

	if (stagesWorker.getNeededToInstall().length > 0) {
		return setupFailed();
	}
	return setupSucceeded();
}

export function Setup() {
	const [errorMessage, setErrorMessage] = useState('');
	const [selectedStage, selectStage] = useState(0);
	const [showCertsHint, setCertsHintState] = useState(false);

	const stages = useMemo(() => {
		try {
			let stages = stagesWorker.getNeededToInstall();
			if (stages.length === 0) {
				store.setPage(setupSucceeded());
			}
			return stages;
		} catch (error) {
			setErrorMessage(String(error));
			return [];
		}
	}, []);

	useEffect(() => {
		async function setupStages() {
			try {
				if (stages.length === 0) return;
				for (let i = 0; i < stages.length; i++) {
					selectStage(i);
					if (stages[i].type === StageTypes.Certs) {
						await setupCerts(stages[i]);
					} else {
						await stages[i].setup();
					}
					// Считаем что настройка раб места проходит слишком быстро, поэтому делаем ее чуть меделеннее, ибо так ее юзер просто не заметит.
					await delay(1000);
				}
				store.setPage(getNextPage());
			} catch (error) {
				// TODO SMA тут можно сделать ошибочную модалку
				setErrorMessage(String(error));
			}
		}

		setupStages();
	}, [stages]);

	async function setupCerts(stage: Stage) {
		let timeout = setTimeout(() => setCertsHintState(true), 2000);
		await stage.setup();
		clearTimeout(timeout);
		setCertsHintState(false);
	}

	function getSetupInfo(): ReactElement {
		return showCertsHint ? (
			<CertInstallationHint />
		) : (
			<div className={styles.progressList}>
				<ProgressList
					stages={stages.map(stage => stage.getInstallStageInfo().title)}
					selected={selectedStage}
				/>
			</div>
		);
	}

	return errorMessage.length === 0 ? (
		<Page
			image={showCertsHint ? PageImage.Attention : PageImage.Load}
			child={
				<>
					<div id={styles.title}>Настраиваем компьютер</div>
					{getSetupInfo()}
				</>
			}
			showMobileView={false}
		/>
	) : (
		<div>{String(errorMessage)}</div>
	);
}
