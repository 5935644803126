import styles from './toggle-switch.module.scss';
import React from 'react';
import { v4 as guid } from 'uuid';

export interface ToggleSwitchProps {
	name?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	onToggle?: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	ref?: React.LegacyRef<HTMLInputElement>;
}

export function ToggleSwitch2(props: ToggleSwitchProps) {
	const id = guid();
	return (
		<div className={styles.toggleSwitch}>
			<input
				type="checkbox"
				className={styles.toggleSwitchCheckbox}
				name={props.name}
				id={id}
				defaultChecked={props.defaultChecked}
				checked={props.checked}
				disabled={props.disabled}
				onChange={props.onToggle}
				ref={props.ref}
			/>
			<label
				className={styles.toggleSwitchLabel}
				htmlFor={id}
			>
				<span className={styles.toggleSwitchInner} />
				<span className={styles.toggleSwitchSwitch} />
			</label>
		</div>
	);
}
