import { makeAutoObservable } from 'mobx';
import { Modal, ModalProps } from '@components/modal';
import { ReactElement } from 'react';
import { createContext } from '@helpers/context-helper';

export class ModalStore {
	public modal: ReactElement | null = null;

	public constructor() {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	show(props: ModalProps) {
		this.modal = <Modal {...props} />;
	}

	hide() {
		this.modal = null;
	}
}

export const ModalStoreContext = createContext<ModalStore>();
