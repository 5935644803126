import { InfoModalCardTemplate } from '@components/modal-cards/info-modal-card-template';
import { ReactComponent as Attention } from '@assets/icons/exclamation-triangle.svg';
import { ModalStore } from '../../../../../stores/modal-store';
import { InstructionLink } from '@components/instruction-link';
import styles from './failed-cp5-activation-modal.module.scss';

export interface Props {
	modalStore: ModalStore;
	serial: string;
	onClose?: () => void;
}

function FailedCp5ActivationModal(props: Props) {
	return (
		<InfoModalCardTemplate
			title="Не удалось активировать КриптоПро CSP 5 "
			info={
				<>
					Воспользуйтесь
					<InstructionLink
						link={process.env.REACT_APP_INSTR_UPDATE_ENTER_CP_LICENSE!}
						text=" инструкцией "
					/>
					для активации КриптоПро CSP 5 по найденному серийному номеру{' '}
					<span className={styles.serial}>{props.serial}</span>
				</>
			}
			image={<Attention />}
			onClickCrossButton={() => {
				props.modalStore.hide();
				props.onClose?.();
			}}
			centerCross
		/>
	);
}

export const showFailedCp5ActivationModal = (props: Props) => {
	props.modalStore.show({
		children: <FailedCp5ActivationModal {...props} />,
		onClickOutOfChild: () => {
			props.modalStore.hide();
			props.onClose?.();
		},
		active: true,
	});
};
