import styles from './cert-list-content.module.scss';
import { useHeightController } from '@hooks/height-controller';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { CertCard } from '../cert-card/cert-card';
import { SearchNotFound } from '@components/search-not-found';
import { SearchInput } from '@pages/settings/auto-import';
import { RefreshButton } from '@components/refresh-button';
import { ShowCerts } from '@features/cert-list/models/states';
import { Cert } from '@models/api/cert';
import { SelectProps } from '../interfaces/select-props';
import { OldCertsHint } from '../old-certs-hint';

interface Props extends SelectProps {
	info: ShowCerts;
	load: (searchValue?: string) => Promise<void>;
	selected: Cert | undefined;
	select: Dispatch<SetStateAction<Cert | undefined>>;
}

export const CertListContent = (props: Props) => {
	const { selected, select } = props;
	const { certs } = props.info;

	useEffect(() => {
		if (selected && certs.filter(cert => cert.certThumbprint === selected.certThumbprint).length === 0) {
			select(undefined);
		}
	}, [certs]);

	const ref = useRef(null);
	const height = useHeightController(ref, props.info.searchResult);

	async function onRefresh() {
		await props.load();
		select(undefined);
	}

	return (
		<div id={styles.content}>
			<div id={styles.additional}>
				{(certs.length > 1 || props.info.searchResult) && (
					<SearchInput
						onChange={searchString => {
							props.load(searchString);
						}}
						placeholder="ФИО владельца, название или ИНН организации..."
					/>
				)}
				<RefreshButton onClick={() => onRefresh()} />
			</div>
			<div
				className={styles.list}
				ref={ref}
				style={{ height: height, maxHeight: height }}
			>
				{certs.length === 0 ? (
					<SearchNotFound text="Сертификатов по такому запросу не найдено" />
				) : (
					certs.map(cert => (
						<CertCard
							key={cert.certThumbprint}
							certInfo={cert}
							onClick={() => select(cert)}
							checked={selected?.certThumbprint === cert.certThumbprint}
							showObsoleteSoonWarning={!cert.isCertNewQes}
						/>
					))
				)}
			</div>
			{props.info.showObsoleteCertsHint && <OldCertsHint text="Почему видны не все сертификаты?" />}
		</div>
	);
};
