import { Page, PageImage } from '@components/page-template';
import styles from './install-cp-error.module.scss';
import { InstructionLink } from '@components/instruction-link';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';

export function InstallCpError() {
	return (
		<Page
			image={PageImage.Error}
			child={
				<div>
					<div id={styles.title}>Не удалось установить КриптоПро CSP 5</div>
					<div id={styles.supportTextBlock}>
						<div id={styles.supportText}>
							{cpStore.setupMode === CpSetupMode.UpdateMajorVersion
								? 'Повторите установку обновления или обновите '
								: 'Повторите установку или установите '}
							КриптоПро CSP&nbsp;5&nbsp;самостоятельно
							<InstructionLink
								link={process.env.REACT_APP_INSTR_INSTALL_CRYPTO!}
								text={' по инструкции'}
							/>
						</div>
					</div>
					<button
						className={styles.buttonBlue}
						onClick={() => {
							window.location.reload();
						}}
					>
						Повторить попытку
					</button>
				</div>
			}
		/>
	);
}
