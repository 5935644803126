import styles from './enable-org-switch.module.scss';
import { ReactComponent as CircleCrossedOut } from '@assets/icons/circle-crossed-out.svg';
import { ReactComponent as SwitchCircle } from '@assets/icons/switch-circle.svg';

export interface EnableDisableSwitchProps {
	enableAll: boolean;
	onClick: (currentState: boolean) => void;
}

export function EnableDisableSwitch(props: EnableDisableSwitchProps) {
	return (
		<button
			id={styles.main}
			onClick={() => props.onClick(props.enableAll)}
		>
			{props.enableAll ? <SwitchCircle /> : <CircleCrossedOut id={styles.switchIcon} />}
			<span>{props.enableAll ? <>Включить&nbsp;все</> : <>Выключить&nbsp;все</>}</span>
		</button>
	);
}
