import { useEffect, useState } from 'react';
import logger from '@logger';
import { InstallCpState } from '@features/setup-wizard/enums/install-cp-state';
import styles from './install-cp.module.scss';
import { restartPC } from '@businessLogic/api/restart-pc';
import { RestartPCType } from '@enums/restart-pc-type';
import { RestartPCResult } from '@enums/restart-pc-result';
import { Page, PageImage } from '@components/page-template';
import { PromptBlock, PromptBlockType } from '@components/prompt-block/prompt-block';
import { ButtonWithSpinner } from '@components/button-with-spinner/button-with-spinner';
import { observer } from 'mobx-react-lite';
import { installCpStore } from '@features/setup-wizard/stores/install-cp-store';
import { store } from '@features/setup-wizard/stores/store';
import { commonInfo } from '@businessLogic/common-info';
import { cpLicenseStore } from '@features/cp-license/cp-license-store';
import { cpStore } from '@features/setup-wizard/stores/cp-store';
import { CpSetupMode } from '@features/setup-wizard/enums/cp-setup-mode';
import { noAccessToRestartAfterInstallCp } from '@features/setup-wizard/models/pages';

// TODO SMA тут можно переделать список на использование ProgressList
// но так как данную страницу делал не я, пока боюсь :|
export function InstallCpPage() {
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { setupMode } = cpStore;

	useEffect(() => {
		installCpStore.installCp().catch(logger.error);
	}, []);

	function getStyleForText(expectedState: InstallCpState) {
		if (installCpStore.currentUpdateState === expectedState) {
			return styles.currentStateText;
		} else {
			if (installCpStore.currentUpdateState < expectedState) {
				return styles.nextStateText;
			} else {
				return styles.pastStateText;
			}
		}
	}

	function getTextForPart() {
		if (setupMode === CpSetupMode.UpdateMajorVersion) {
			if (installCpStore.currentUpdateState === InstallCpState.Install) {
				return 'Обновляем КриптоПро CSP';
			}
			return 'Обновили КриптоПро CSP';
		}
		if (installCpStore.currentUpdateState === InstallCpState.Install) {
			return 'Устанавливаем КриптоПро CSP';
		}
		return 'Установили КриптоПро CSP';
	}

	async function restart() {
		logger.info('Отправляем запрос на перезагрузку ПК');
		if (setupMode === CpSetupMode.UpdateMinorVersion) return;

		const params = new Map<string, string>([
			[process.env.REACT_APP_PARAMETER_NAME_CP_SETUP_MODE!, setupMode],
			[process.env.REACT_APP_PARAMETER_NAME_SCHEDULED_ENTER_CP_KEY!, String(cpLicenseStore.scheduledEnterCpKey)],
		]);
		if (cpLicenseStore.licenseKey)
			params.set(process.env.REACT_APP_PARAMETER_NAME_CP_KEY!, cpLicenseStore.licenseKey);
		if (commonInfo.sourceInfo.installerId)
			params.set(process.env.REACT_APP_PARAMETER_NAME_INSTALLER_ID!, commonInfo.sourceInfo.installerId);
		if (commonInfo.sourceInfo.utmAppId)
			params.set(process.env.REACT_APP_PARAMETER_NAME_UTM_APP_ID!, commonInfo.sourceInfo.utmAppId);

		const res = await restartPC(
			setupMode === CpSetupMode.UpdateMajorVersion ? RestartPCType.CspUpdate : RestartPCType.CspInstall,
			params,
		);
		if (res !== RestartPCResult.Success) {
			store.setPage(noAccessToRestartAfterInstallCp());
		}
	}

	return (
		<Page
			image={PageImage.Load}
			child={
				<div>
					<div id={styles.title}>
						{setupMode === CpSetupMode.UpdateMajorVersion
							? 'Обновление КриптоПро'
							: 'Устанавливаем КриптоПро CSP 5'}
					</div>
					<div id={styles.content}>
						<div className={getStyleForText(InstallCpState.Install)}>{getTextForPart()}</div>

						<div className={getStyleForText(InstallCpState.RestartRequired)}>
							Ждём перезагрузки
							{installCpStore.currentUpdateState === InstallCpState.RestartRequired ? (
								<div>
									<div id={styles.warnBlock}>
										<PromptBlock
											type={PromptBlockType.Warning}
											text={'Перезагрузка может затронуть работу других пользователей'}
										/>
									</div>
									<div id={styles.buttonBlock}>
										<ButtonWithSpinner
											onClick={async () => {
												setButtonLoading(true);
												await restart();
											}}
											loading={isButtonLoading}
											defaultText={'Перезагрузить компьютер'}
										/>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
}

export const InstallCp = observer(InstallCpPage);
