import { Route, Routes, useLocation } from 'react-router-dom';
import { storeSettings } from '@features/settings/common/stores/store-settings';
import { PagesSettings } from '@features/settings/common/enums/pages-settings';
import { Settings } from '@pages/settings/settings';
import { useEffect } from 'react';
import { SettingsRoot } from './settings-root';
import { PageNotFound } from '@pages/page-not-found';
import { AutoSign } from './auto-sign';

function SettingsRouter() {
	const location = useLocation();

	//TODO MGA Костыль ли это? - Да. Знаешь как сделать лучше делая отдельные состояния для каждой страницы - велком
	useEffect(() => {
		if (location.pathname === '/settings/proxy') {
			storeSettings.setPage(PagesSettings.ProxySettings);
		} else if (location.pathname === '/settings/autoimport') {
			storeSettings.setPage(PagesSettings.AutoImportSettings);
		}
	}, [location]);

	return (
		<Routes>
			<Route
				path="/proxy"
				element={<Settings />}
			/>
			<Route
				path="/autoimport"
				element={<Settings />}
			/>
			{/*TODO SMA Раскомментить перед релизом автоподписи*/}
			{/* <Route
				path="/autosign"
				element={<AutoSign />}
			/> */}
			<Route
				path="/"
				element={<SettingsRoot />}
			></Route>
			<Route
				path="*"
				element={<PageNotFound />}
			/>
		</Routes>
	);
}

export default SettingsRouter;
