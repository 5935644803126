import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class PdfAdobeProgram {
	@jsonMember(() => String)
	public name!: string;

	@jsonMember(() => String)
	public installDate!: string;

	@jsonMember(() => String)
	public pdfFileProgId!: string;
}
