import { Checkbox } from '@components/checkbox';
import { CreateShortcutState } from '@features/shortcuts-creator/enums/create-shortcut-state';
import { ProductInfo } from '@features/shortcuts-creator/models/product-info';
import { shortcutCreatorStore } from '@features/shortcuts-creator/stores/shortcut-creator-store';
import { observer } from 'mobx-react-lite';
import styles from './product.module.scss';

export interface ProductProps {
	info: ProductInfo;
}

function ProductElement(props: ProductProps) {
	function onButtonClick() {
		if (!shortcutCreatorStore.createShortCutMode) {
			window.open(props.info.targetUrl, '_self', 'noopener,noreferrer');
			return;
		}
		shortcutCreatorStore.toggleProductCreateShortcutState(props.info.targetUrl);
	}

	return (
		<button
			onClick={onButtonClick}
			className={[
				styles.link,
				styles.main,
				shortcutCreatorStore.createShortCutMode && props.info.createShortcutState === CreateShortcutState.Needed
					? styles.checked
					: null,
				props.info.createShortcutState === CreateShortcutState.NotAvailable &&
				shortcutCreatorStore.createShortCutMode
					? styles.displayNone
					: null,
			].join(' ')}
		>
			{shortcutCreatorStore.createShortCutMode ? (
				<Checkbox
					checked={
						shortcutCreatorStore.createShortCutMode &&
						props.info.createShortcutState === CreateShortcutState.Needed
					}
					onChange={(_checked, e) => {
						shortcutCreatorStore.toggleProductCreateShortcutState(props.info.targetUrl);
						e.stopPropagation();
					}}
				/>
			) : null}
			<img
				src={props.info.iconPath}
				id={styles.icon}
			/>
			<span>{props.info.displayName}</span>
		</button>
	);
}

export const Product = observer(ProductElement);
